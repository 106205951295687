import { useMedias } from '@happywait/hw-lib-front.core.api'
import { ObserverWrapper } from 'components/Observer/ObserverWrapper'
import useNavigateParams from 'hooks/useNavigateParams'
import { useSetCurrentUnit } from 'hooks/useSetCurrentUnit'
import { PropsWithChildren } from 'react'
import { useMatch } from 'react-router-dom'
import { useStore } from 'store'
import { selectInitialData } from 'store/selectors'
import { getMediaThumbPath } from 'tools/thumb'
import { useDevice } from '../../hooks/useDevice'
import { useQueryString } from '../../hooks/useQueryString'
import { routesWithoutMenu } from '../../modules'
import { Menu } from '../Menu/Menu'
import { LayoutStyles as Styles } from './Layout.styles'

export const Layout = (props: PropsWithChildren) => {
  const routesWithoutMenuMatch = useMatch({ path: routesWithoutMenu })
  const isMobile = useDevice('mobileL')
  const navigate = useNavigateParams()
  const formType = useQueryString('formType')

  const hideMenu = (!!formType && isMobile) || (!!routesWithoutMenuMatch && isMobile)

  useSetCurrentUnit()
  const initialData = useStore(selectInitialData)
  const { data: promoterLogo } = useMedias({
    params: { ids: [initialData?.logo || 0] },
    queryParams: { enabled: !!initialData?.logo },
  })
  const thumb = getMediaThumbPath(promoterLogo?.data[0], 'logo')

  return (
    <>
      <ObserverWrapper>
        <Styles.Header>
          {!!thumb.path && <Styles.Logo onClick={() => navigate('/home')} src={thumb.path} />}
        </Styles.Header>
        <Styles.SubContainer>
          {!hideMenu && <Menu />}
          <Styles.Content hideMenu={hideMenu}>{props.children}</Styles.Content>
        </Styles.SubContainer>
      </ObserverWrapper>
    </>
  )
}
