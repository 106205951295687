import styled from 'styled-components'

type SeparatorStyleProps = {
  width?: number
  height?: number
}

export const Separator = styled.div<SeparatorStyleProps>`
  margin-left: ${({ width }) => (width ? `${width / 2}px` : undefined)};
  margin-right: ${({ width }) => (width ? `${width / 2}px` : undefined)};
  ${({ width }) => width && `width: ${width}px`};
  ${({ height }) => height && `height: ${height}px`};
  ${({ height }) => height && `min-height: ${height}px`};
`
