import { useConversationById, useMe } from '@happywait/hw-lib-front.core.api'
import { usePromoterConfiguration } from '@happywait/hw-lib-front.core.api/v1'
import { useCurrentAcquisition } from 'hooks/useCurrentAcquisition'
import { useEffect, useMemo, useRef } from 'react'
import { useStore } from 'store'
import { selectConversationRankId, selectModalIdsHistory, selectSetConversationRankId } from 'store/selectors'
import { ShouldShowModal } from 'store/slices/showModal'
import { setNewValues } from '../WelcomeModalWrapper.services'

export const useInitWelcomeModal = () => {
  const conversationRankId = useStore(selectConversationRankId)
  const setConversationRankId = useStore(selectSetConversationRankId)
  const isConversationRankId = !!conversationRankId && !Number.isNaN(+conversationRankId)

  const {
    data: currentConversation,
    isLoading: isLoadingCurrentConversation,
    isSuccess,
  } = useConversationById({
    params: { id: window.conversationRankId },
    queryParams: {
      enabled: !!window.conversationRankId,
    },
  })

  useEffect(() => {
    if (!!window.conversationRankId && isSuccess) {
      setConversationRankId(window.conversationRankId)
      window.conversationRankId = undefined
    }
  }, [conversationRankId, isSuccess, setConversationRankId])

  const { data: promoterConfiguration, isLoadingTanstack: isLoadingPromoterConf } = usePromoterConfiguration()

  const { sale } = useCurrentAcquisition()

  const { data: me } = useMe()
  const modalIdHistory = useStore(selectModalIdsHistory)

  const newValues: ShouldShowModal = useMemo(
    () =>
      setNewValues({
        promoterConfiguration,
        currentSale: sale,
        me,
        isConversationRankId,
        currentConversation,
        modalIdHistory,
      }),
    [promoterConfiguration, sale, me, isConversationRankId, currentConversation, modalIdHistory]
  )

  const newValuesRef = useRef(newValues)

  return {
    newValues,
    newValuesRef,
    isLoading: isLoadingPromoterConf || isLoadingCurrentConversation,
  }
}
