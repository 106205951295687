import styled, { DefaultTheme, ThemedStyledProps } from 'styled-components'
import { semibold14 } from '../Font/Font'

type ContainerStyleProps = {
  size: 'large' | 'small'
  variant: 'primary' | 'secondary' | 'borderless'
  position: 'left' | 'center' | 'right'
  hasIcon: boolean
  hasTitle: boolean
  fullWidth?: boolean
  noWrap?: boolean
  isLoading?: boolean
  color?: string
  newColorOnHover: boolean
}

const getHeight = ({ size }: ContainerStyleProps) => {
  if (size === 'large') {
    return '42px'
  }
  if (size === 'small') {
    return '36px'
  }
}

const getPosition = ({ position }: ContainerStyleProps) => {
  if (position === 'center') {
    return '0 auto'
  }
  if (position === 'right') {
    return '0 0 0 auto'
  }
}

const getBackgroundColor = ({ variant, theme, color }: ThemedStyledProps<ContainerStyleProps, DefaultTheme>) => {
  if (color) {
    return color
  }
  if (variant === 'primary') {
    return theme.colors.primary
  }
  if (variant === 'secondary') {
    return theme.colors.grey20
  }
}

const getIconFill = ({ variant, theme }: ThemedStyledProps<ContainerStyleProps, DefaultTheme>) => {
  if (variant === 'primary') {
    return theme.colors.white
  }
  return theme.colors.black
}

const getTitleColor = ({ variant, theme }: ThemedStyledProps<ContainerStyleProps, DefaultTheme>) => {
  if (variant === 'primary') {
    return theme.colors.white
  }
  return theme.colors.black
}

const getTitleColorHover = ({
  variant,
  theme,
  newColorOnHover,
}: ThemedStyledProps<ContainerStyleProps, DefaultTheme>) => {
  if (variant === 'borderless' && newColorOnHover) {
    return theme.colors.primary
  }
}

const getBackgroundColorHover = ({
  variant,
  theme,
  newColorOnHover,
}: ThemedStyledProps<ContainerStyleProps, DefaultTheme>) => {
  if (variant === 'secondary' && newColorOnHover) {
    return theme.colors.grey60
  }
}

const getBackgroundColorHoverDisabled = ({
  variant,
  theme,
  newColorOnHover,
}: ThemedStyledProps<ContainerStyleProps, DefaultTheme>) => {
  if (variant === 'secondary' && newColorOnHover) {
    return theme.colors.grey20
  }
}

const getBackgroundColorActive = ({ variant, theme }: ThemedStyledProps<ContainerStyleProps, DefaultTheme>) => {
  if (variant === 'secondary') {
    return theme.colors.grey
  }
}

const getBackgroundColorActiveDisabled = ({
  variant,
  theme,
  newColorOnHover,
}: ThemedStyledProps<ContainerStyleProps, DefaultTheme>) => {
  if (variant === 'secondary' && newColorOnHover) {
    return theme.colors.grey20
  }
}

const getPadding = ({ hasIcon, hasTitle }: ThemedStyledProps<ContainerStyleProps, DefaultTheme>) => {
  if (hasIcon && hasTitle) {
    return '32.5px'
  }
  if (hasIcon) {
    return '9px'
  }
  if (hasTitle) {
    return '40px'
  }
}
const SubContainer = styled.div`
  display: flex;
  isolation: isolate;
  align-items: center;
`

const Title = styled.div`
  transition: opacity 0.25s ease-out;
  ${semibold14};
`

const Shadow = styled.div`
  transition: opacity 0.25s ease;
  position: absolute;
  background-color: #000000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  opacity: 0;
`

const Icon = styled.div`
  transition: opacity 0.25s ease-out;
  width: 24px;
  height: 24px;
`

const Spinner = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    display: flex;
    align-items: center;
  }
`

const Container = styled.button<ContainerStyleProps>`
  transition: background-color 0.25s ease;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${getBackgroundColor};
  border-radius: 4px;
  min-height: ${getHeight};
  min-width: ${getHeight};
  padding-left: ${getPadding};
  padding-right: ${getPadding};
  position: relative;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : undefined)};
  margin: ${getPosition};

  ${Title} {
    color: ${getTitleColor};
    ${({ noWrap }) => noWrap && { whiteSpace: 'nowrap' }};
    ${({ isLoading }) => isLoading && { opacity: 0 }}
  }

  ${Icon} {
    ${({ isLoading }) => isLoading && { opacity: 0 }}
    * {
      fill: ${getIconFill};
    }
  }

  ${SubContainer} {
    > * + * {
      ${({ hasTitle }) => hasTitle && 'margin-left: 6px;'}
    }
  }

  :hover {
    background-color: ${getBackgroundColorHover};
    ${Shadow} {
      opacity: 0.3;
    }
    ${Title} {
      color: ${getTitleColorHover};
    }
    ${Icon} {
      * {
        fill: ${({ variant, theme, newColorOnHover }) =>
          variant === 'borderless' && newColorOnHover && theme.colors.primary}};
      }
    }
  }

  :active {
    background-color: ${getBackgroundColorActive};
    ${Shadow} {
      opacity: 0.7;
    }
  }

  :disabled {
    ${({ theme, isLoading }) => !isLoading && `background-color: ${theme.colors.grey20}`};

    ${Title} {
      ${({ theme, isLoading }) => !isLoading && `color: ${theme.colors.black}`};
      ${({ isLoading }) => `opacity: ${isLoading ? 0 : 0.2}`};
    }

    ${Icon} {
      fill: ${({ theme, hasTitle }) => (hasTitle ? theme.colors.black : theme.colors.white)};
      opacity: ${({ hasTitle, isLoading }) => (isLoading ? 0 : hasTitle ? 0.2 : 1)};
    }

    :hover {
      background-color: ${getBackgroundColorHoverDisabled};
      ${Shadow} {
        opacity: 0;
      }
    }

    :active {
      background-color: ${getBackgroundColorActiveDisabled};
      ${Shadow} {
        opacity: 0;
      }
    }
  }
`

export const ButtonStyles = {
  Container,
  Title,
  Icon,
  SubContainer,
  Shadow,
  Spinner,
}
