import { MouseEvent, PropsWithChildren, ReactElement } from 'react'
import { Loader } from '../../components/Loader/Loader'
import { EntityId } from '../../types/entity'
import { when } from '../../utils/helpers'
import { Font } from '../Font/Font'
import { MenuListStyles as Styles } from './MenuList.styles'

export type MenuOption<T = EntityId> = {
  id: T
  label: string
  icon?: ReactElement
  disabled?: boolean
}

export type MenuListProps<T = EntityId> = {
  options: Array<MenuOption<T>>
  onSelect?: (id: T) => void
  isLoading?: boolean
  maxHeight?: number
}

export const MenuList = <T extends EntityId>({
  options,
  onSelect,
  isLoading,
  maxHeight,
}: PropsWithChildren<MenuListProps<T>>) => {
  if (options.length === 0) {
    return null
  }

  const onSelectOption = (id: T) => (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    onSelect?.(id)
  }

  const renderOption = ({ id, icon, label, disabled }: MenuOption<T>) => (
    <Styles.Option isDisabled={!!disabled} key={id} onClick={when(!disabled, onSelectOption(id))}>
      {icon && <Styles.Icon>{icon}</Styles.Icon>}
      <Font>{label}</Font>
    </Styles.Option>
  )

  return (
    <Styles.Container maxHeight={maxHeight}>
      {isLoading ? <Loader type="Oval" width={20} height={20} /> : options.map(renderOption)}
    </Styles.Container>
  )
}
