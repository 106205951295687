import { HStack, Text } from '@chakra-ui/react'
import { useTranslate } from 'services/i18n'
import { theme } from 'theme'
const WelcomeModalStandaloneToast = ({ variant }: { variant: 'success' | 'error' }) => {
  const t = useTranslate()

  return (
    <HStack
      borderLeft={`2px solid ${variant == 'success' ? theme.colors.primary : theme.colors.error}`}
      padding={'10px'}
      boxShadow={`5px 5px 10px  ${theme.colors.black40}`}
      background={theme.colors.white}
      margin={'20px 40px 10px'}
      bottom="20px"
      right={'70px'}
    >
      <Text>{t(`meantimeContact.toast.${variant}`)}</Text>
    </HStack>
  )
}

export default WelcomeModalStandaloneToast
