import { TextInput, TextInputProps } from 'design-system/TextInput/TextInput'
import { useFormErrorTranslate } from 'hooks/useFormErrorTranslate'
import { ChangeEventHandler } from 'react'
import { useController } from 'react-hook-form'

import { ControlledInputWrapper, ControlledInputWrapperProps } from '../ControlledInputWrapper/ControlledInputWrapper'
type FormTextInputControlledProps = { emptyValue?: '' | null | undefined } & ControlledInputWrapperProps &
  Omit<TextInputProps, 'value' | 'onChange' | 'error'>

export const FormTextInputControlled = ({
  title,
  name,
  emptyValue = '',
  wrapperStyle,
  ...props
}: FormTextInputControlledProps) => {
  const tError = useFormErrorTranslate()

  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({
    name,
  })

  const handleOnChange: ChangeEventHandler<HTMLInputElement> = ({ target: { value } }) =>
    onChange(!!value ? value : emptyValue)

  return (
    <ControlledInputWrapper name={name} title={title} wrapperStyle={wrapperStyle} displayError={false}>
      <TextInput value={value || ''} onChange={handleOnChange} error={tError(error)} {...props} />
    </ControlledInputWrapper>
  )
}
