import { SaleMinimal } from '@happywait/hw-lib-front.core.api'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useStore } from 'store'
import { selectProjectId, selectSaleId, selectSetAcquisition } from 'store/selectors'
import { useAccessibleSales } from './useAccessibleSales'
import useQueryParams from './useQueryParams'

export const useSetCurrentUnit = () => {
  const saleId = useStore(selectSaleId)
  const projectId = useStore(selectProjectId)
  const [searchParams] = useSearchParams()
  const setAcquisition = useStore(selectSetAcquisition)
  const { updateParams } = useQueryParams()
  const [saleIdQueryParam, setSaleIdQueryParam] = useState<null | number>(null)
  const [projectIdQueryParam, setProjectIdQueryParam] = useState<null | number>(null)

  const { accessibleSales } = useAccessibleSales()

  const updateAcquisition = (saleMinimal: SaleMinimal) => {
    const { id, project, mainBatch } = saleMinimal
    setAcquisition({ saleId: id, projectId: project, unitId: mainBatch })
    updateParams({ p: `${project}`, s: `${id}` })
  }

  useEffect(() => {
    setSaleIdQueryParam(Number(searchParams.get('s')))
    setProjectIdQueryParam(Number(searchParams.get('p')))
  }, [searchParams])

  useEffect(() => {
    if (!accessibleSales?.length) {
      return
    }
    let sale: SaleMinimal | undefined
    if (!saleIdQueryParam && !projectIdQueryParam && !!saleId && !!projectId) {
      updateParams({ p: `${projectId}`, s: `${saleId}` })
      return
    }
    if (saleId === saleIdQueryParam && !!projectId) {
      updateParams({ p: `${projectId}` })
      return
    }
    if (!!saleIdQueryParam) {
      sale = accessibleSales?.find((sale) => {
        return sale.id == saleIdQueryParam
      })
    }
    if (!sale && !!projectIdQueryParam) {
      sale = accessibleSales?.find((sale) => {
        return sale.project == projectIdQueryParam
      })
    }
    if (!sale) {
      sale = accessibleSales?.filter((e) => !e.archived)[0] || accessibleSales?.[0]
    }
    if (sale) {
      updateAcquisition(sale)
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saleIdQueryParam, projectIdQueryParam, accessibleSales])
}
