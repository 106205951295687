import { Box, Button, Fade, Flex, HStack, Link, Spinner, Text } from '@chakra-ui/react'
import { EntityId } from '@happywait/hw-lib-front.core.api'
import { useEffect, useRef } from 'react'
import { useTranslate } from 'services/i18n'
import { SelectOptions } from '../Select/Select'

export type ListOptionsProps = {
  boxHeight: number
  refHeight?: number
  isLoading?: boolean
  isScrollLoading?: boolean
  isOpen: boolean
  isReversed: boolean
  noOptionLabel?: string
  options: SelectOptions[]
  value: EntityId
  openOn?: EntityId
  onSelectOption: (id: string) => void
  onScroll?: (e: React.UIEvent<HTMLElement>) => void
  filterSearch?: string
  onCreateNotFound?: (value: string) => void
}

export const ListOptions = ({
  boxHeight,
  refHeight,
  isLoading,
  isScrollLoading,
  isOpen,
  isReversed,
  noOptionLabel,
  options,
  value,
  openOn,
  onSelectOption,
  onScroll,
  filterSearch,
  onCreateNotFound,
}: ListOptionsProps) => {
  const t = useTranslate()
  const activeRef = useRef() as React.MutableRefObject<HTMLButtonElement>
  const scrollToId = value || openOn

  useEffect(() => {
    isOpen && activeRef.current?.scrollIntoView({ block: 'nearest', inline: 'center' })
  }, [isOpen])

  return (
    <Box
      position="absolute"
      top={isReversed ? 'auto' : refHeight}
      bottom={isReversed ? refHeight : 0}
      left="0"
      right="0"
      zIndex={5}
    >
      <Fade in={isOpen} unmountOnExit>
        <Flex
          direction="column"
          alignItems="flex-start"
          backgroundColor="white"
          maxH={`${boxHeight}px`}
          overflowY="auto"
          boxShadow="xs"
          py={1}
          onScroll={onScroll}
        >
          {isLoading ? (
            <Flex my={4} w="full" alignItems="center" justifyContent="center">
              <Spinner h="20px" w="20px" />
            </Flex>
          ) : options.length > 0 ? (
            <>
              {options.map(({ id, label, component }) => (
                <Button
                  key={id}
                  data-testid={`list-option-${id}`}
                  ref={scrollToId?.toString() === id.toString() ? activeRef : undefined}
                  variant="ghost"
                  fontWeight="normal"
                  fontSize="1.4rem"
                  w="full"
                  h="40px"
                  px={4}
                  flex=" 0 0 40px"
                  justifyContent="flex-start"
                  textAlign="left"
                  whiteSpace="normal"
                  onClick={() => onSelectOption(id.toString())}
                  onMouseDown={(e) => e.preventDefault()}
                  backgroundColor={value?.toString() === id?.toString() ? 'primary.100' : undefined}
                  my={1}
                >
                  {component ? component : label}
                </Button>
              ))}
              {isScrollLoading && (
                <Flex my={1} w="full" alignItems="center" justifyContent="center">
                  <Spinner h="20px" w="20px" />
                </Flex>
              )}
            </>
          ) : (
            <HStack px={4} w="full">
              <Text h="40px" display="flex" alignItems="center">
                {noOptionLabel}
              </Text>
              {onCreateNotFound && filterSearch && (
                <Link
                  onMouseDown={(e) => e.preventDefault()}
                  onClick={() => onCreateNotFound(filterSearch)}
                  fontSize="14px"
                >
                  {t('createValue', {
                    value: filterSearch,
                    interpolation: { escapeValue: false },
                  }) || `Create ${filterSearch}`}
                </Link>
              )}
            </HStack>
          )}
        </Flex>
      </Fade>
    </Box>
  )
}
