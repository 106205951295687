import { Box, Heading } from '@chakra-ui/react'
import { useModules } from '@happywait/hw-lib-front.core.api'
import { Loader } from 'components/Loader/Loader'
import { LottiePicture } from 'components/LottiePicture/LottiePicture'
import { menuServices } from 'components/Menu/Menu.services'
import { useCurrentAcquisition } from 'hooks/useCurrentAcquisition'
import { PropsWithChildren } from 'react'
import { useTranslate } from 'services/i18n'
import { useStore } from 'store'
import { selectModules } from 'store/selectors'
import DocumentBlank from '../../assets/illustrations/DocumentBlank.json'

export const ModuleRightWrapper = ({
  children,
  moduleCodes,
}: PropsWithChildren & { moduleCodes: string | string[] }) => {
  const modules = useStore(selectModules)
  const t = useTranslate()
  const { showNavigationItem } = menuServices.useComponent()

  const { unit, isLoading } = useCurrentAcquisition()
  const { isLoading: isLoadingModule } = useModules({
    params: { batch_id: unit?.id },
    queryParams: { enabled: !!unit },
  })

  if (isLoadingModule || isLoading || modules.length === 0) {
    return <Loader />
  }

  if (showNavigationItem(...moduleCodes) && !!children) {
    return <>{children}</>
  } else {
    return (
      <Box
        p={4}
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'center'}
        alignItems={'center'}
        w={'100%'}
        h={'100vh'}
      >
        <LottiePicture animation={DocumentBlank} />
        <Heading padding={8}>{t('notFound')}</Heading>
      </Box>
    )
  }
}
