import { uniq } from 'ramda'
import { SetState } from 'zustand'
import { Store } from '../types'

export const documentSlice = (set: SetState<Store>) => ({
  readDocumentIds: [],
  markedDocumentIds: [],
  addReadDocumentIds: (ids: number[]) => set((prev) => ({ readDocumentIds: uniq([...prev.readDocumentIds, ...ids]) })),
  addMarkedDocumentId: (id: number) => set((prev) => ({ markedDocumentIds: uniq([...prev.markedDocumentIds, id]) })),
  removeMarkedDocumentId: (id: number) =>
    set((prev) => ({ markedDocumentIds: prev.markedDocumentIds.filter((docId) => docId !== id) })),
})
