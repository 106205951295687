import { animated } from 'react-spring'
import styled from 'styled-components'
import { createShadow } from '../../theme/style.services'
import { regular12, regular14 } from '../Font/Font'

type ContainerStyleProps = {
  isFocused: boolean
  error: boolean
  displayLabel: boolean
}

const LabelContainer = styled(animated.div)`
  position: absolute;
  padding-top: 1px;
  padding-bottom: 1px;
  padding-right: 6px;
  padding-left: 6px;
`

const Label = styled(animated.div)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: 'Avenir-Medium',
  opacity: 0.6;
  color: ${({ theme }) => theme.colors.black};
`

const Input = styled.input`
  text-overflow: ellipsis;
  width: 100%;
  ${regular14};
  padding: 0 0 0 6px;
  color: ${({ theme }) => theme.colors.black};

  ::placeholder {
    ${regular14};
    opacity: 0.6;
    color: ${({ theme }) => theme.colors.black};
  }
`

const IconLeft = styled.div`
  height: 24px;
  width: 24px;
  margin-right: 6px;
  * {
    transition: fill 0.25 ease;
  }
`

const Container = styled.div<ContainerStyleProps>`
  transition: background-color 0.25s ease, border-color 0.25s ease;
  min-height: 42px;
  border-radius: 4px;
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  background-clip: padding-box;
  -webkit-background-clip: padding-box;
  background-color: ${({ theme, isFocused }) => (isFocused ? theme.colors.white : theme.colors.grey20)};
  border: 1px solid transparent;
  ${({ isFocused, theme, error }) =>
    error
      ? createShadow({ x: 0, y: 0, blur: 0, spread: 3, color: theme.colors.error20 })
      : isFocused
      ? createShadow({ x: 0, y: 0, blur: 0, spread: 3, color: theme.colors.primary20 })
      : undefined};

  border-color: ${({ isFocused, theme, error }) =>
    error ? theme.colors.error : isFocused ? theme.colors.primary : theme.colors.grey20};

  :hover {
    background-color: ${({ theme, isFocused }) => (isFocused ? theme.colors.white : theme.colors.grey60)};
    border-color: ${({ theme, isFocused }) => (isFocused ? theme.colors.primary : theme.colors.grey60)};
  }

  ${LabelContainer} {
    background-color: ${({ isFocused }) => (isFocused ? 'white' : 'transparent')};
    display: ${({ displayLabel }) => (displayLabel ? 'flex' : 'none')};
  }

  ${IconLeft} {
    * {
      fill: ${({ theme, isFocused }) => (isFocused ? theme.colors.black : theme.colors.black60)};
    }
  }
`

const IconRightContainer = styled.button`
  border-radius: 50%;
  padding: 4px;
  margin-left: 8px;
  :hover {
    background-color: ${({ theme }) => theme.colors.grey20};
  }
  :active {
    background-color: ${({ theme }) => theme.colors.grey};
  }
`

const IconRight = styled.div`
  height: 24px;
  width: 24px;
  * {
    fill: ${({ theme }) => theme.colors.black};
  }
`

const InputContainer = styled.div`
  position: relative;
  display: flex;
  flex: 1;
`

const Title = styled.div`
  ${regular14};
  opacity: 0.6;
  margin-left: 3px;
  text-align: start;
`

const TextInput = styled.div`
  width: 100%;
  position: relative;
`

const Error = styled.div`
  margin-top: 2px;
  ${regular12};
  color: ${({ theme }) => theme.colors.error};
`

export const TextInputStyles = {
  Container,
  TextInput,
  Error,
  Title,
  Label,
  LabelContainer,
  InputContainer,
  Input,
  IconLeft,
  IconRight,
  IconRightContainer,
}
