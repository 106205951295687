import { baseApi, eventApi } from '@happywait/hw-lib-front.core.api'
import 'flat-map-polyfill'
import { createRoot } from 'react-dom/client'
import { _errorReporting } from 'services/error_reporting'
import { App } from './App'
import { config } from './config'
import { _api } from './services/api'
import './styles.css'

if (process.env.NODE_ENV !== 'development' || process.env.REACT_APP_SENTRY_IN_DEV === 'active') {
  _errorReporting.init(config.apiKeys.sentry)
}

const initApiToken = () => {
  _api.init()
  _api.startUnauthorizedAccessInterceptor()
  _api.addTokenRequestInterceptor()
}

initApiToken()

_errorReporting.initNetworkTracking({
  apiConnectors: [baseApi, eventApi],
})

const forceHash = () => {
  const _hash = window.location.hash
  const _pathname = window.location.pathname
  const strippedPathName = _pathname.substring(1)

  try {
    if (!_hash[0] && !window.forgotPassword) {
      window.history.pushState('index', 'HW', window.location.origin + '/#/' + strippedPathName)
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error while trying to remove token from url', e)
  }
}
forceHash()

window.addEventListener('hashchange', forceHash)

const container = document.getElementById('root')
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!)
root.render(<App />)
