import styled from 'styled-components'
import { getHexWithAlpha } from '../../theme/hexaTransparency'
import { createShadow } from '../../theme/style.services'

type ContainerStyleProps = {
  maxHeight?: number
}

type OptionStyleProps = {
  isDisabled: boolean
}

const Container = styled.div<ContainerStyleProps>`
  ${({ maxHeight }) => maxHeight && { maxHeight: `${maxHeight}px` }};
  overflow: auto;
  border-radius: 4px;
  padding-top: 11px;
  padding-bottom: 11px;
  padding-left: 6px;
  padding-right: 6px;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.white};
  ${createShadow({ x: 0, y: 4, blur: 8, spread: 0, color: getHexWithAlpha('#091A30', 16) })};
`

const Option = styled.div<OptionStyleProps>`
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 9px;
  padding-bottom: 9px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.white};
  cursor: ${({ isDisabled }) => (isDisabled ? 'default' : 'pointer')};

  > * + * {
    margin-left: 10px;
  }
  :hover {
    background-color: ${({ theme, isDisabled }) => !isDisabled && getHexWithAlpha(theme.colors.grey, 20)};
  }
  :active {
    background-color: ${({ theme, isDisabled }) => !isDisabled && getHexWithAlpha(theme.colors.grey, 60)};
  }
`

const Icon = styled.div`
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const MenuListStyles = {
  Container,
  Icon,
  Option,
}
