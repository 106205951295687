import { useStore } from 'store'
import { selectSetConversationRankId, selectSetModalIdsHistory } from 'store/selectors'

export const useCleanConversationEvaluation = () => {
  const setConversationRankId = useStore(selectSetConversationRankId)
  const setModalIdHistory = useStore(selectSetModalIdsHistory)

  const cleanConversationEvaluation = (): void => {
    setModalIdHistory(['conversationEvaluation'])
    setConversationRankId(undefined)
    window.conversationRankId = undefined
  }

  const isConversationEvaluationModal = (
    currentTruthyModalId: 'unitTypologySurvey' | 'meantimeContactSurvey' | 'conversationEvaluation' | undefined
  ): boolean => currentTruthyModalId === 'conversationEvaluation'

  return {
    cleanConversationEvaluation,
    isConversationEvaluationModal,
  }
}
