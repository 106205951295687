import { useEffect } from 'react'
import { useStore } from 'store'
import { selectSetToken } from 'store/selectors'

export const useToken = () => {
  const setToken = useStore(selectSetToken)

  useEffect(() => {
    const token = window.token
    if (!!token) {
      setToken(token)
      delete window.token
    }
  }, [setToken])
}
