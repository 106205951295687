import { Box, Text, Wrap, WrapItem } from '@chakra-ui/react'
import { ProgressBar } from 'design-system/ProgressBar/ProgressBar'
import { StepIndex } from 'design-system/StepIndex/StepIndex'
import { useTranslate } from 'services/i18n'
import { invitationFormService as service } from '../InvitationForm.service'
import { CustomLink } from './Link/CustomLink'

const { paddingWithBottomStyles } = service

type InvitationHeaderProps = {
  state: number
  maxStep: number
  status?: string
  previous: () => void
}

export const InvitationHeader = ({ state, maxStep, status, previous }: InvitationHeaderProps) => {
  const t = useTranslate()
  const isFinished = state !== maxStep || status !== 'OPENED'
  const percent = isFinished ? 100 : (state / maxStep) * 100

  const renderStepIndex = () => {
    return (
      <Box>
        <StepIndex index={state} total={maxStep} />
      </Box>
    )
  }

  return (
    <>
      <Wrap justify="space-between" align="center" padding={paddingWithBottomStyles}>
        <WrapItem order={1}>
          {status === 'OPENED' && state !== 1 ? <CustomLink titleTranslationKey="previous" onClick={previous} /> : null}
        </WrapItem>
        <WrapItem order={2}>
          <Text fontStyle={'bold'} fontSize={22}>
            {t('invForm.lotReservation')}
          </Text>
        </WrapItem>
        <WrapItem order={3}> {status === 'OPENED' ? renderStepIndex() : null} </WrapItem>
      </Wrap>
      <ProgressBar percent={percent} />
    </>
  )
}
