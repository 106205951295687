import { PromoterPlaceholder } from '../../assets/svg/PromoterPlaceholder'
import { getWordsFirstChars } from '../../utils/string'
import { PictureSize, UserPictureStyles as Styles } from './UserPicture.styles'

export type UserPictureProps = {
  size?: PictureSize
  url?: string
  name: string
  illustrationFallback?: boolean
  border?: boolean
}

export const UserPicture = ({ size = 'medium', url, name, illustrationFallback, border = false }: UserPictureProps) => {
  if (!url) {
    if (illustrationFallback) {
      return (
        <Styles.Placeholder size={size} border={border}>
          <PromoterPlaceholder />
        </Styles.Placeholder>
      )
    }

    return (
      <Styles.Placeholder size={size} border={border}>
        {getWordsFirstChars(name)}
      </Styles.Placeholder>
    )
  }

  return <Styles.Container size={size} src={url} border={border} />
}
