import { NavigateOptions, To, useNavigate } from 'react-router-dom'
import { pick } from 'remeda'
import useQueryParams from './useQueryParams'

const useNavigateParams = () => {
  const navigate = useNavigate()
  const { queryObjectParams } = useQueryParams()

  const buildUrl = (to: To) => {
    let url
    if (typeof to === 'string') {
      url = new URL(to, window.location.origin)
    } else {
      url = new URL(to.pathname || '', window.location.origin)
    }
    const conserveParams = pick(queryObjectParams as any, ['p', 's'])

    Object.entries(conserveParams).forEach(([key, value]) => {
      if (value) {
        url.searchParams.set(key, value)
      }
    })

    if (typeof to !== 'string' && to.search) {
      const toSearchParams = new URLSearchParams(to.search)
      toSearchParams.forEach((value, key) => {
        url.searchParams.set(key, value)
      })
    }
    return url
  }

  return (to: To | number, options?: NavigateOptions | undefined) => {
    if (typeof to === 'number') {
      navigate(to)
    } else {
      const url = buildUrl(to)
      navigate(`${url.pathname}${url.search}`, options)
    }
  }
}

export default useNavigateParams
