export const ChecklistMenuIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g
      fill="none"
      fillRule="evenodd"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      transform="translate(2 2)"
    >
      <path d="M10,0 C15.523,0 20,4.477 20,10 C20,15.523 15.523,20 10,20 C4.477,20 0,15.523 0,10 C0,4.477 4.477,0 10,0 Z" />
      <path d="M14.4567,7.0917 L9.6147,13.5487 C9.1997,14.1007 8.4147,14.2117 7.8627,13.7967 C7.8157,13.7617 7.7717,13.7227 7.7297,13.6817 L5.2297,11.1817" />
    </g>
  </svg>
)
