import { FieldError } from 'react-hook-form'
import { useCompleteTranslate } from '../services/i18n'

export const useErrorTranslate = (context?: string) => {
  const { t, exists } = useCompleteTranslate()

  return (error?: FieldError): string => {
    if (!error) {
      return ''
    }

    if (!!context && exists(`${context}.errors.${error.message}`)) {
      return t(`${context}.errors.${error.message}`)
    }

    if (!!context && exists(`${context}.errors.${error.type}`)) {
      return t(`${context}.errors.${error.type}`)
    }
    if (exists(`errors.form.${error.type}`)) {
      return t(`errors.form.${error.type}`)
    }

    if (exists(`${error.message}`)) {
      return t(`${error.message}`)
    }

    return error.message ?? ''
  }
}
