import { useMe } from '@happywait/hw-lib-front.core.api'
import { useUnits } from '@happywait/hw-lib-front.core.api/legacy'
import { useStore } from '../store'
import { selectUnitId } from '../store/selectors'

export const useCustomer = (unitIdParam?: number) => {
  const currentUnitId = useStore(selectUnitId)
  const unitId = unitIdParam || currentUnitId

  const { data: units } = useUnits({ params: { limit: -1 } })

  const { data: me } = useMe()

  const currentUnit = units?.find((unit) => unit.id === unitId)
  const mainCustomer = currentUnit?.consumer.id === me?.id

  return {
    mainCustomer,
  }
}
