import { animated } from 'react-spring'
import styled from 'styled-components'
import { device } from '../../theme'
import { getHexWithAlpha } from '../../theme/hexaTransparency'
import { bold10, regular12, regular16 } from '../Font/Font'

type ContainerStyleProps = {
  active: boolean
}

const Title = styled.div`
  ${regular16}
  margin-left: 20px;
  @media ${device.tabletL} {
    margin-left: 0px;
    margin-top: 4px;
    text-align: center;
  }
  @media ${device.mobileL} {
    ${bold10};
    margin-top: 7px;
    text-transform: uppercase;
  }
`

const Icon = styled.div``

const Container = styled.div<ContainerStyleProps>`
  transition: background-color 0.25s ease;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 28px;
  padding-right: 18px;
  display: flex;
  height: fit-content;
  align-items: center;
  position: relative;
  cursor: pointer;

  ${Title} {
    transition: opacity 0.25s ease;
    opacity: ${({ active }) => (active ? 1 : 0.6)};
    font-weight: ${({ active }) => (active ? '700' : '500')};
    @media ${device.mobileL} {
      font-weight: 700;
    }
  }

  ${Icon} {
    transition: opacity 0.25s ease;
    opacity: ${({ active }) => (active ? 1 : 0.6)};
  }

  :hover {
    background-color: ${({ theme }) => getHexWithAlpha(theme.colors.primary, 10)};
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    ${Title} {
      opacity: 1;
    }
    ${Icon} {
      opacity: 1;
    }
  }

  :active {
    background-color: ${({ theme }) => getHexWithAlpha(theme.colors.primary, 60)};
  }

  @media ${device.tabletL} {
    justify-content: center;
    padding-left: 7px;
    padding-right: 7px;
  }

  @media ${device.mobileL} {
    flex: 1;
  }
`

const IconTitleContainer = styled.div`
  display: flex;
  align-items: center;
  @media ${device.tabletL} {
    flex-direction: column;
    margin-right: 0px;
  }
`

const Bar = styled(animated.div)`
  width: 6px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: ${({ theme }) => theme.colors.primary};
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  @media ${device.mobileL} {
    height: 4px;
    width: 40px;
    left: 50%;
    transform: translateX(-50%);
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
  }
`

const NotificationsContainer = styled.div`
  margin-left: auto;
  background-color: ${({ theme }) => theme.colors.primary};
  ${regular12};
  color: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
  padding-top: 1px;
  padding-bottom: 1px;
  padding-left: 8px;
  padding-right: 8px;
  @media ${device.tabletL} {
    position: absolute;
    top: 0;
    transform: translateX(15px);
  }
  @media ${device.mobileL} {
    top: 8px;
  }
`

export const NavigationItemStyles = {
  Container,
  Bar,
  Icon,
  IconTitleContainer,
  Title,
  NotificationsContainer,
}
