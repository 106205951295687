import { Tooltip } from '@chakra-ui/react'
import { forwardRef, ReactElement } from 'react'
import { useTheme } from 'styled-components'
import { Spinner } from '../../components/Spinner/Spinner'
import { ButtonStyles as Styles } from './Button.styles'

export type ButtonProps = {
  title?: string
  variant?: 'primary' | 'secondary' | 'borderless'
  size?: 'large' | 'small'
  position?: 'left' | 'center' | 'right'
  disabled?: boolean
  icon?: ReactElement
  iconRight?: ReactElement
  fullWidth?: boolean
  noWrap?: boolean
  isLoading?: boolean
  loaderColor?: string
  tooltipText?: string
  newColorOnHover?: boolean
  dataHeap?: string
  dataTest?: string
} & { titleId?: string } & React.ButtonHTMLAttributes<HTMLButtonElement>

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      onClick,
      title = '',
      variant = 'primary',
      size = 'small',
      position = 'left',
      disabled = false,
      icon,
      iconRight,
      fullWidth = false,
      isLoading = false,
      loaderColor,
      tooltipText,
      newColorOnHover = true,
      dataHeap,
      dataTest,
      ...props
    },
    ref
  ) => {
    const theme = useTheme()

    return (
      <Tooltip isDisabled={!tooltipText} label={tooltipText} fontSize={12} shouldWrapChildren>
        <Styles.Container
          hasIcon={!!icon}
          fullWidth={fullWidth}
          hasTitle={!!title}
          size={size}
          position={position}
          variant={variant}
          onClick={onClick}
          disabled={disabled || isLoading}
          isLoading={isLoading}
          ref={ref}
          newColorOnHover={newColorOnHover}
          {...props}
        >
          {variant === 'primary' && <Styles.Shadow />}
          <Styles.SubContainer>
            <>
              {icon && <Styles.Icon>{icon}</Styles.Icon>}
              {title && (
                <Styles.Title id={props.titleId} data-test={dataTest}>
                  {title}
                </Styles.Title>
              )}
              {iconRight && <Styles.Icon>{iconRight}</Styles.Icon>}
              {isLoading && (
                <Styles.Spinner>
                  <Spinner
                    type="TailSpin"
                    height={24}
                    width={24}
                    color={variant === 'borderless' ? theme.colors.dark : loaderColor || theme.colors.white}
                  />
                </Styles.Spinner>
              )}
            </>
          </Styles.SubContainer>
        </Styles.Container>
      </Tooltip>
    )
  }
)
