import { useMe, usePatchSalesInvestmentType } from '@happywait/hw-lib-front.core.api'
import { useCleanWelcomeModal } from 'components/WelcomeModalWrapper/hooks/useCleanWelcomeModal'
import { useShowStandaloneToast } from 'components/WelcomeModalWrapper/hooks/useShowStandaloneWMToast'
import { useCurrentAcquisition } from 'hooks/useCurrentAcquisition'
import { ReactElement, createElement } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Button } from '../../design-system/Button/Button'
import { Font } from '../../design-system/Font/Font'
import { useDevice } from '../../hooks/useDevice'
import { useTranslate } from '../../services/i18n'
import { _validator } from '../../services/validator'
import { Separator } from '../Separator/Separator'
import { UnitTypologySurveyFormValues, unitTypologySurveyServices } from './UnitTypologySurvey.services'
import { UnitTypologySurveyStyles as Styles } from './UnitTypologySurvey.styles'

type ChoiceProps = {
  title: string
  icon: ReactElement
  selected: boolean
  onSelect: () => void
  disabled: boolean
}

const Choice = ({ title, icon, selected, onSelect, disabled }: ChoiceProps) => (
  <Styles.Choice selected={selected} onClick={disabled ? () => null : onSelect}>
    <Styles.Icon>{icon}</Styles.Icon>
    <Font family={selected ? 'semibold' : 'regular'}>{title}</Font>
  </Styles.Choice>
)

const { schema, ANSWERS_MAP } = unitTypologySurveyServices

export const UnitTypologySurvey = () => {
  const { cleanWelcomeModal } = useCleanWelcomeModal()
  const { data: me } = useMe()
  const t = useTranslate()
  const { sale } = useCurrentAcquisition()

  const isTablet = useDevice('tabletL')
  const { control, handleSubmit, watch } = useForm<UnitTypologySurveyFormValues>({
    defaultValues: {},
    resolver: _validator.resolver(schema),
  })
  const { showStandaloneToast } = useShowStandaloneToast()

  const investmentType = watch('investmentType')
  const { mutate, isLoading: isUpdating } = usePatchSalesInvestmentType({
    onSuccess: () => {
      cleanWelcomeModal({ closeModalId: 'unitTypologySurvey', invalidateQueries: ['sale-id'] })
    },
    onError: () => {
      showStandaloneToast('error')
    },
  })

  const onSubmit = ({ investmentType }: UnitTypologySurveyFormValues) => {
    sale && mutate({ saleId: sale?.id, investmentType: investmentType })
  }

  return (
    <Styles.Container>
      <Font family="semibold" size={16}>
        {isTablet
          ? t('unitTypologySurvey.label')
          : `${t('unitTypologySurvey.label')} : ${t('unitTypologySurvey.title')}`}
      </Font>

      {isTablet && (
        <Styles.TabletTitle>
          <Font family="semibold" size={14}>
            {t('unitTypologySurvey.title')}
          </Font>
        </Styles.TabletTitle>
      )}
      <Separator height={40} />
      <Styles.ChoicesContainer>
        <Controller
          control={control}
          name="investmentType"
          render={({ field: { onChange, value } }) => (
            <>
              {ANSWERS_MAP.map(({ name, icon }, index) => (
                <Choice
                  title={t(`unitTypologySurvey.answers.${index}`)}
                  selected={value === name}
                  icon={createElement(icon)}
                  onSelect={() => onChange(name)}
                  disabled={!!me?.isObserver}
                />
              ))}
            </>
          )}
        />
      </Styles.ChoicesContainer>
      <Separator height={30} />
      <Styles.Actions>
        <Button
          title={t('finish')}
          onClick={handleSubmit(onSubmit)}
          isLoading={isUpdating}
          disabled={!investmentType || !!me?.isObserver}
        />
      </Styles.Actions>
    </Styles.Container>
  )
}
