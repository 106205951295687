import { Icon, IconProps } from '@chakra-ui/react'

export const Mail = (props: IconProps) => (
  <Icon w="24px" h="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g id="icon/24/mail-copy" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M20.125,4.69871795 C21.2295695,4.69871795 22.125,5.59414845 22.125,6.69871795 L22.125,6.69871795 L22.125,17.6602564 C22.125,18.7648259 21.2295695,19.6602564 20.125,19.6602564 L20.125,19.6602564 L3.875,19.6602564 C2.7704305,19.6602564 1.875,18.7648259 1.875,17.6602564 L1.875,17.6602564 L1.875,6.69871795 C1.875,5.59414845 2.7704305,4.69871795 3.875,4.69871795 L3.875,4.69871795 Z M20.625,7.17071795 L16.218,10.4437179 L18.7791547,12.9941381 C19.0726956,13.2863822 19.073747,13.7612548 18.7815029,14.0547957 C18.5158265,14.3216511 18.0992174,14.3467796 17.8051243,14.1295757 L17.7208453,14.0571439 L15,11.3487179 L13.5866729,12.3990417 C12.6882087,13.0670823 11.504847,13.1041956 10.5750243,12.5106024 L10.4136794,12.3993036 L8.999,11.3487179 L6.27915467,14.0571439 C5.98561373,14.349388 5.51074116,14.3483366 5.2184971,14.0547957 C4.95282069,13.7879403 4.92953716,13.371224 5.14804112,13.0780956 L5.22084533,12.9941381 L7.781,10.4437179 L3.375,7.17171795 L3.375,17.6602564 C3.375,17.9057163 3.55187516,18.1098648 3.78512437,18.1522007 L3.875,18.1602564 L20.125,18.1602564 C20.4011424,18.1602564 20.625,17.9363988 20.625,17.6602564 L20.625,17.6602564 L20.625,7.17071795 Z M19.419,6.19871795 L4.58,6.19871795 L11.3083396,11.1953173 C11.6901796,11.4792291 12.1738781,11.5050393 12.5745026,11.272551 L12.6920128,11.1950554 L19.419,6.19871795 Z"
        id="Combined-Shape"
        fill="#2E3844"
        fillRule="nonzero"
      ></path>
    </g>
  </Icon>
)
