import { getColor } from '@chakra-ui/theme-tools'
import { Dict } from '@chakra-ui/utils'

export const getPhoneNumberInputStyles = (theme: Dict<any>) => ({
  '.PhoneInput': {
    display: 'flex',
    transition: 'all 0.15s linear',
    backgroundColor: 'gray.100',
    borderRadius: '4px',
    width: '100%',
    position: 'relative',
    mozAppearance: 'none',
    msAppearance: 'none',
    appearance: 'none',
    padding: '10px',
    height: '42px',
    textOverflow: 'ellipsis !important',
    overflow: 'hidden !important',
    whiteSpace: 'nowrap !important',
  },
  '.PhoneInput--focus': {
    boxShadow: `0px 0px 0px 3px ${getColor(theme, 'primary.200')}`,
    border: `1px solid ${getColor(theme, 'primary.500')}`,
    backgroundColor: 'white',
  },
  '.PhoneInputCountryIcon': {
    paddingRight: '5px',
  },
  '.PhoneInputCountryIconImg': {
    borderRadius: '50%',
    objectFit: 'cover',
    width: '20px',
    height: '20px',
  },
  '.PhoneInputInput': {
    flex: 1,
    fontSize: '14px',
    fontFamily: 'regular',
    paddingLeft: '8px',
  },
  '.PhoneInputCountry': {
    transition: 'border-color 0.15s linear',
    marginRight: '0 !important',
    position: 'relative',
    alignSelf: 'stretch',
    display: 'flex',
    alignItems: 'center',
  },
  '.PhoneInputCountrySelect': {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    zIndex: 1,
    border: 0,
    opacity: 0,
    cursor: 'pointer',
  },
  '.PhoneInputCountrySelect[disabled]': {
    cursor: 'default',
  },
  '.PhoneInputCountrySelectArrow': {
    display: 'block',
    width: '0.5em',
    height: '0.5em',
    marginLeft: '0.35em',
    borderStyle: 'solid',
    borderColor: '#2E3844',
    borderTopWidth: 0,
    borderBottomWidth: '2px',
    borderLeftWidth: 0,
    borderRightWidth: '2px',
    transform: 'rotate(45deg)',
  },
})
