import { useStore } from '../../store'
import { selectModules } from '../../store/selectors'

const useComponent = () => {
  const modules = useStore(selectModules)

  const showNavigationItem = (...moduleCode: string[]) => {
    // If one of the moduleCode is in the modules array, return true
    return moduleCode.some((code) => modules.includes(code))
  }

  return {
    showNavigationItem,
  }
}

export const menuServices = {
  useComponent,
}
