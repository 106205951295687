import { Flex, Image, Link } from '@chakra-ui/react'
import { EntityId, Locale } from '@happywait/hw-lib-front.core.api'
import { useQuery } from '@tanstack/react-query'
import { Select } from 'design-system/Select/Select'
import useNavigateParams from 'hooks/useNavigateParams'
import { PropsWithChildren, useEffect, useState } from 'react'
import { useTranslate } from 'services/i18n'
import { _locale } from 'services/locale'
import { getPictureThumbPaths } from 'tools/thumb'
import { fetchImageLoginPicture, fetchLoginPicture } from 'utils/loginPicture'
import { tests } from 'utils/tests'
import LoginBackground from '../../../../assets/pictures/LoginBackground.png'
import { useStore } from '../../../../store'
import { selectLocale, selectSetLocale } from '../../../../store/selectors'
import { LoginModuleFormStyles as Styles } from './LoginModuleForm.styles'

export const LoginModuleForm = ({ children }: PropsWithChildren) => {
  const locale = useStore(selectLocale)
  const promoterLogo = window.promoter?.logo
  const setLocale = useStore(selectSetLocale)
  const { data: imageUrl, isLoading } = useQuery([fetchLoginPicture], fetchImageLoginPicture)

  const [loginPictureUrl, setLoginPictureUrl] = useState('')

  useEffect(() => {
    if (imageUrl) {
      setLoginPictureUrl(imageUrl)
    }
  }, [imageUrl])

  const t = useTranslate()
  const navigate = useNavigateParams()

  const handleChangeLocal = (_locale: Locale) => {
    setLocale(_locale)
  }

  return (
    <Styles.Container>
      <Styles.Content>
        {promoterLogo && <Styles.PromoterPicture src={getPictureThumbPaths(promoterLogo.path, 'logo').path} />}
        <Styles.Form>{children}</Styles.Form>
        <Flex mt="auto" justify="space-around" alignItems="center">
          {_locale.isShowableLocalSelector() && (
            <Select
              options={_locale.getLocalSelectorOptions()}
              value={locale as EntityId}
              onSelect={(locale) => handleChangeLocal(locale as Locale)}
              inverted
              light
            />
          )}
          <Link
            fontSize={'lg'}
            {...tests.button.open('privacypolicy')}
            onClick={() => navigate('/login/privacy-policy')}
          >
            {t('privacyPolicy')}
          </Link>
        </Flex>
      </Styles.Content>
      <Styles.ImageContainer>
        {!isLoading && (
          <Image
            style={{
              objectFit: 'cover',
              height: '100%',
              width: '100%',
            }}
            src={loginPictureUrl}
            onError={() => {
              setLoginPictureUrl(LoginBackground)
            }}
            loading="eager"
          />
        )}
      </Styles.ImageContainer>
    </Styles.Container>
  )
}
