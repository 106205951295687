import { MouseEvent } from 'react'
import { ChevronRight } from '../../assets/svg/ChevronRight'
import { Separator } from '../../components/Separator/Separator'
import { Font, FontProps } from '../Font/Font'
import { LinkStyles as Styles } from './Link.styles'

export type LinkProps = {
  title: string
  onClick?: (e: MouseEvent<HTMLDivElement>, id?: number) => void
  chevron?: boolean
  underline?: boolean
} & FontProps

export const Link = ({ onClick, title, chevron, underline, ...props }: LinkProps) => {
  return (
    <Styles.Container onClick={onClick} underline>
      <Font {...props}>{title}</Font>
      {chevron && (
        <>
          <Separator width={2} />
          {ChevronRight}
        </>
      )}
    </Styles.Container>
  )
}
