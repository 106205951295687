export const Lock = (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="icon/24/lock-copy" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M12.125,2.84375 C14.7614133,2.84375 16.9121399,4.92322218 17.0264696,7.5314564 L17.03125,7.75 L17.03075,9.96875 L17.46875,9.96875 C18.4878469,9.96875 19.3231765,10.7555512 19.4004208,11.7548355 L19.40625,11.90625 L19.40625,20.21875 C19.40625,21.2888017 18.5388017,22.15625 17.46875,22.15625 L17.46875,22.15625 L6.78125,22.15625 C5.7111983,22.15625 4.84375,21.2888017 4.84375,20.21875 L4.84375,20.21875 L4.84375,11.90625 C4.84375,10.8361983 5.7111983,9.96875 6.78125,9.96875 L6.78125,9.96875 L7.21875,9.96875 L7.21875,7.75 C7.21875,5.04035295 9.41535295,2.84375 12.125,2.84375 Z M17.46875,11.46875 L6.78125,11.46875 C6.53962542,11.46875 6.34375,11.6646254 6.34375,11.90625 L6.34375,11.90625 L6.34375,20.21875 C6.34375,20.4603746 6.53962542,20.65625 6.78125,20.65625 L6.78125,20.65625 L17.46875,20.65625 C17.7103746,20.65625 17.90625,20.4603746 17.90625,20.21875 L17.90625,20.21875 L17.90625,11.90625 C17.90625,11.6646254 17.7103746,11.46875 17.46875,11.46875 L17.46875,11.46875 Z M12.125,13.53125 C13.5229708,13.53125 14.65625,14.6645292 14.65625,16.0625 C14.65625,17.4604708 13.5229708,18.59375 12.125,18.59375 C10.7270292,18.59375 9.59375,17.4604708 9.59375,16.0625 C9.59375,14.6645292 10.7270292,13.53125 12.125,13.53125 Z M12.125,15.03125 C11.5554564,15.03125 11.09375,15.4929564 11.09375,16.0625 C11.09375,16.6320436 11.5554564,17.09375 12.125,17.09375 C12.6945436,17.09375 13.15625,16.6320436 13.15625,16.0625 C13.15625,15.4929564 12.6945436,15.03125 12.125,15.03125 Z M12.125,4.34375 C10.3064874,4.34375 8.82081444,5.76880588 8.72379014,7.5631086 L8.71875,7.75 L8.71875,9.96875 L15.53075,9.96875 L15.53125,7.75 C15.53125,5.86878007 14.0062199,4.34375 12.125,4.34375 Z"
        id="Combined-Shape"
        fill="#2E3844"
        fillRule="nonzero"
      ></path>
    </g>
  </svg>
)
