import styled from 'styled-components'
import { regular14 } from '../Font/Font'

const Select = styled.div<{ light?: boolean; bgColor?: string; width?: string }>`
  height: 42px;
  padding-left: 16px;
  padding-right: 12px;
  padding-top: 9px;
  padding-bottom: 9px;
  border-radius: 4px;
  background-color: ${({ theme, light, bgColor }) => (bgColor ? bgColor : !light ? theme.colors.grey20 : 'initial')};
  display: flex;
  width: ${({ width }) => (width ? width : 'auto')};
  align-items: center;
  justify-content: space-between;

  > .select_icon {
    flex: 1;
  }
  > div {
    white-space: nowrap;
  }
`

const Title = styled.div`
  ${regular14};
  opacity: 0.6;
  margin-left: 3px;
  text-align: start;
`

const Menu = styled.div<{ inverted: boolean }>`
  position: absolute;
  top: ${({ inverted }) => (inverted ? 'initial' : '42px')};
  bottom: ${({ inverted }) => (inverted ? '42px' : 'initial')};
  left: 0px;
  right: 0px;
  z-index: 2;
`

export const SelectStyles = {
  Select,
  Menu,
  Title,
}
