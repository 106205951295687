import { baseApi, eventApi, initialiseTransformV3Booleans } from '@happywait/hw-lib-front.core.api'
import { PromoterConfiguration } from '@happywait/hw-lib-front.core.api/v1'
import { AxiosInstance } from 'axios'
import { routeNames } from '../../modules'
import { useStore } from '../../store'
import { selectToken } from '../../store/selectors'

import { getBaseDev } from '../../utils/helpers'
import { unauthorizedAccessHandler } from './unauthorizedAccess'

type ApiOptions = {
  baseUri: string
  contentType: string
  baseUrl: string
}

const userInactivityIgnoredRoutes = ['/message_number', '/document_signatures_number']

const addTokenRequestInterceptor = () => {
  const configHandler = (config) => {
    const token = selectToken(useStore.getState())
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  }

  baseApi.interceptors.request.use((config) => configHandler(config))
  eventApi.interceptors.request.use((config) => configHandler(config))
}

const startUnauthorizedAccessInterceptor = () => {
  baseApi.interceptors.response.use((response) => response, unauthorizedAccessHandler(baseApi))
  eventApi.interceptors.response.use((response) => response, unauthorizedAccessHandler(eventApi))
}

const startInactivityInterceptor = (promoterConfiguration: PromoterConfiguration) => {
  let timeoutId: NodeJS.Timeout

  baseApi.interceptors.request.use((config) => {
    if (
      promoterConfiguration?.autoDisconnect &&
      promoterConfiguration.autoDisconnectDelay &&
      config.url &&
      !userInactivityIgnoredRoutes.includes(config.url)
    ) {
      clearTimeout(timeoutId)
      timeoutId = setTimeout(
        () => window.history.pushState({}, '', routeNames.logout),
        promoterConfiguration.autoDisconnectDelay * 60 * 1000
      )
    }

    return config
  })
}

const setApiOptions = (api: AxiosInstance, { baseUri, contentType, baseUrl }: ApiOptions) => {
  api.defaults.baseURL = baseUrl
  api.defaults.headers.common['base-uri'] = baseUri
  api.defaults.headers.common['Content-Type'] = contentType
}

const init = () => {
  const commonApiOptions = {
    baseUri: window.document.baseURI,
    contentType: 'application/json',
  }

  setApiOptions(baseApi, {
    ...commonApiOptions,
    baseUrl: `${getBaseDev()}api`,
  })

  setApiOptions(eventApi, {
    ...commonApiOptions,
    baseUrl: window.eventApiURL,
  })

  initialiseTransformV3Booleans(baseApi)
}

export const _api = {
  startInactivityInterceptor,
  startUnauthorizedAccessInterceptor,
  init,
  addTokenRequestInterceptor,
}
