import styled from 'styled-components'
import { device, theme } from '../../theme'

const Container = styled.div`
  background-color: ${theme.colors.white};
  overflow: auto;
  z-index: 2;

  @media ${device.mobileL} {
    display: flex;
    width: 100%;
    height: 80px;
    position: fixed;
    bottom: 0;
    z-index: 10;
  }
`

const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  position: relative;
  overflow: auto;
  min-height: 100%;

  @media ${device.mobileL} {
    flex-direction: row;
    border-top: 1px solid ${theme.colors.grey60};
  }
`

const HeaderMenu = styled.div`
  height: 130px;
  display: flex;
  align-items: center;
  padding-left: 28px;
  @media ${device.tabletL} {
    display: none;
  }
`

const NavigationUserContainer = styled.div`
  margin-top: auto;
  border-top: 1px solid ${theme.colors.grey60};
  @media ${device.mobileL} {
    display: none;
  }
`

const Logo = styled.img`
  max-width: 200px;
  max-height: 80px;
  cursor: pointer;
`

export const MenuStyles = {
  HeaderMenu,
  Logo,
  Container,
  NavigationUserContainer,
  SubContainer,
}
