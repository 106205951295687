import { CheckIcon, CloseIcon } from '@chakra-ui/icons'
import { Box, Circle, Flex, Text, ToastId, useToast } from '@chakra-ui/react'
import { WarningIcon } from 'assets/svg/WarningIcon'
import { PropsWithChildren } from 'react'
import { useTranslate } from 'services/i18n'
import { v4 as uuid } from 'uuid'

export type ToastProps = {
  title?: string
  message?: string
  translateKey?: string
  variant?: ToastVariant
  id?: ToastId
  render?: () => JSX.Element
}

export type ToastVariant = 'info' | 'error' | 'success' | 'warning'

const variants = {
  info: {
    color: '#5b5650',
    bgColor: '#dedddc',
    icon: <CheckIcon boxSize={6} color={'#5b5650'} />,
  },
  error: {
    color: '#f85234',
    bgColor: '#ffddd7',
    icon: <CloseIcon boxSize={6} color={'#f85234'} />,
  },
  success: {
    color: '#50e2ae',
    bgColor: '#dcfaee',
    icon: <CheckIcon boxSize={6} color={'#50e2ae'} />,
  },
  warning: {
    color: '#f39924',
    bgColor: '#fdead2',
    icon: <WarningIcon boxSize={8} color={'#f39924'} />,
  },
}

export const Toast = ({
  id,
  title,
  message,
  translateKey,
  variant = 'info',
  render,
}: PropsWithChildren<ToastProps>) => {
  const t = useTranslate()
  const toast = useToast()
  const toastId = id || uuid()

  const color = variants[variant].color
  const bgColor = variants[variant].bgColor
  const icon = variants[variant].icon

  const displayedMessage = translateKey ? t(translateKey) : message

  if (!toast.isActive(toastId)) {
    return null
  }

  if (render) {
    return render()
  }

  return (
    <Flex
      shadow="0px 6px 13px 0px rgba(51, 48, 79, 0.12)"
      bg="white"
      p="16px 24px"
      borderRadius="5px"
      overflow="hidden"
      position="relative"
    >
      <Box position="absolute" left="0" top="0" bottom="0" width="6px" bg={color} />
      {icon && (
        <Circle margin={'auto'} bg={bgColor} size={'48px'}>
          {icon}
        </Circle>
      )}

      <Flex fontSize={'14px'} direction={'column'} ml={'24px'} justify="center">
        <Text>{title}</Text>
        {displayedMessage && <Box opacity={'60%'}>{displayedMessage}</Box>}
      </Flex>
      <Box margin={'auto'} cursor="pointer" borderRadius={50} ml="50px" _hover={{ background: 'gray.100' }}>
        <CloseIcon width={'14px'} height={'14px'} margin={4} onClick={() => !!toastId && toast.close(toastId)} />
      </Box>
    </Flex>
  )
}
