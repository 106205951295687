import { Heading, Stack, Text } from '@chakra-ui/react'
import { GlassIcon } from 'design-system/Icons/GlassIcon'
import { useTranslate } from 'services/i18n'

export const ObserverContent = () => {
  const t = useTranslate()
  return (
    <Stack justify="center" direction="row" align="center" width={'100%'} px={4}>
      <Heading as="h6" color="gray.100" fontSize={16} mr={'5%'}>
        <GlassIcon color="white" boxSize={10} mr={4} />
        {t('observer.title')}
      </Heading>
      <Text color={'gray.200'}>{t('observer.info')}</Text>
    </Stack>
  )
}
