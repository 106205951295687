import { Box, Icon, Spinner, Text, Tooltip, useTheme } from '@chakra-ui/react'
import { getColor } from '@chakra-ui/theme-tools'
import { E164Number } from 'libphonenumber-js/core'

import { keyframes } from '@chakra-ui/react'
import { InfoCircle } from 'assets/svg/InfoCircle'
import { TooltipWrapper } from 'design-system/TooltipWrapper/TooltipWrapper'
import { useEffect, useRef } from 'react'
import PhoneInput from 'react-phone-number-input'
import { omit } from 'remeda'
import { useStore } from 'store'
import { selectLocale } from 'store/selectors'
import { when } from 'utils/helpers'
import { PhoneNumberInputServices } from './PhoneNumberInput.services'
import { getPhoneNumberInputStyles } from './PhoneNumberInput.styles'

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`

const fadeOut = keyframes`
  from { opacity: 1; }
  to { opacity: 0; }
`

export type PhoneNumberInputProps = {
  value?: E164Number
  onChange: (value: E164Number) => void
  onBlur?: () => void
  placeholder?: string
  disabled?: boolean
  isLoading?: boolean
  title?: string
  error?: string
  errTooltip?: string
  name?: string
  dataTest?: string
  tooltip?: string | null
}

export const PhoneNumberInput = ({
  value,
  onChange,
  onBlur,
  placeholder,
  disabled,
  title,
  error,
  errTooltip,
  dataTest,
  isLoading,
  tooltip,
  name,
}: PhoneNumberInputProps) => {
  const theme = useTheme()
  const phoneNumberInputStyles = getPhoneNumberInputStyles(theme)
  const locale = useStore(selectLocale)
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const focusInput = () => {
      if (containerRef.current) {
        const input = containerRef.current.querySelector('input') as HTMLInputElement
        if (input && document.activeElement !== input) {
          input.focus()
        }
      }
    }

    focusInput()
    const timeoutId = setTimeout(focusInput, 0)

    return () => clearTimeout(timeoutId)
  }, [isLoading])

  const handleChange = (newValue: E164Number) => {
    onChange(newValue)
  }

  return (
    <Box
      width="100%"
      ref={containerRef}
      sx={{
        '.PhoneInput': {
          ...phoneNumberInputStyles['.PhoneInput'],
          boxShadow: when(!!error, `0px 0px 0px 3px ${getColor(theme, 'orange.50')}`),
          border: when(!!error, `1px solid ${getColor(theme, 'orange.200')}`),
        },
        ...omit(phoneNumberInputStyles, ['.PhoneInput']),
      }}
    >
      {title && (
        <Text opacity="0.6" textAlign="start">
          {title}
        </Text>
      )}

      <TooltipWrapper label={tooltip} hidden={!tooltip}>
        <Box position={'relative'}>
          <PhoneInput
            flagUrl="https://purecatamphetamine.github.io/country-flag-icons/1x1/{XX}.svg"
            defaultCountry={PhoneNumberInputServices.getCountryCodeFromLocale(locale)}
            value={value}
            onChange={handleChange}
            onBlur={onBlur}
            placeholder={placeholder}
            disabled={disabled}
            data-test={dataTest}
            name={name}
          />
          <Box
            position="absolute"
            top="50%"
            right="10px"
            transform="translateY(-50%)"
            opacity={isLoading ? 1 : 0}
            transition="opacity 0.3s ease-in-out"
            animation={isLoading ? `${fadeIn} 0.3s` : `${fadeOut} 0.3s`}
          >
            <Spinner size="sm" color="blue.300" />
          </Box>
        </Box>
      </TooltipWrapper>
      {error && (
        <Text mt="2px" color="orange.200" fontSize="1.2rem" lineHeight="1.8rem">
          {error}
          {errTooltip && (
            <Tooltip label={errTooltip} variant="error">
              <Icon ml={1}>
                <InfoCircle fill="orange.200" />
              </Icon>
            </Tooltip>
          )}
        </Text>
      )}
    </Box>
  )
}
