import { useUnits } from '@happywait/hw-lib-front.core.api/legacy'
import { useMemo } from 'react'
import { useAccessibleSales } from './useAccessibleSales'

export const useAccessibleUnits = () => {
  const { accessibleSales } = useAccessibleSales()
  const mainBatchesUnits: number[] | undefined = accessibleSales?.map((sale) => sale?.mainBatch)
  const { data, ...info } = useUnits({
    params: { limit: -1, ids: mainBatchesUnits },
    queryParams: { enabled: mainBatchesUnits && mainBatchesUnits?.length > 0 },
  })

  const accessibleUnits = useMemo(
    () => data?.filter(({ isMainBatch, associatedTo }) => isMainBatch || !associatedTo),
    [data]
  )

  return {
    data: accessibleUnits,
    ...info,
  }
}
