import styled from 'styled-components'
import { semibold14 } from '../../../../design-system/Font/Font'

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.error20};
  padding: 12px;
  border-radius: 4px;
  ${semibold14}
  color: ${({ theme }) => theme.colors.error};
`

export const FormErrorStyles = {
  Container,
}
