import { Locale } from '@happywait/hw-lib-front.core.api'
import { defaultLocale, localeToCountryCode } from 'const'
import { Country as CountryCode } from 'react-phone-number-input'

const getCountryCodeFromLocale = (locale: Locale | null): CountryCode =>
  null === locale ? (localeToCountryCode[defaultLocale] as CountryCode) : (localeToCountryCode[locale] as CountryCode)

export const PhoneNumberInputServices = {
  getCountryCodeFromLocale,
}
