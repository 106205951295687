import { Container, HStack, LinkBox, Text, VStack } from '@chakra-ui/react'
import { useUnits } from '@happywait/hw-lib-front.core.api/legacy'
import { useAccessibleUnits } from 'hooks/useAccessibleUnits'
import { getAcquisitionTitle } from 'modules/acquisition/screens/Acquisition.services'
import { useEffect } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { theme } from 'theme'
import { Folder } from '../../assets/svg/Folder'
import { useDevice } from '../../hooks/useDevice'
import { routeNames } from '../../modules'
import { useStore } from '../../store'
import { selectResetModals, selectUnitId } from '../../store/selectors'

export const ProjectSelector = () => {
  const { pathname } = useLocation()
  const isMobile = useDevice('mobileL')
  const unitId = useStore(selectUnitId)

  const { data: accessibleUnits, isLoading } = useAccessibleUnits()

  const closeModals = useStore(selectResetModals)
  const { data: currentUnit } = useUnits({
    params: { ids: [unitId as number] },
    queryParams: { enabled: !!unitId },
  })

  // TODO this should not be the way
  useEffect(() => {
    if (isMobile && pathname?.includes('acquisition')) closeModals()
  }, [closeModals, isMobile, pathname])

  if (!accessibleUnits) {
    return null
  }

  if (!currentUnit || !currentUnit[0]) {
    return null
  }

  const { projectName, customValues, batchType, name } = currentUnit[0]
  const active = pathname === routeNames.acquisition
  const isClickable = accessibleUnits.length > 1

  return (
    <LinkBox bg={theme.colors.primary20} _hover={{ bg: theme.colors.primary40 }} w={'100%'}>
      <HStack
        _active={active ? { bg: 'primary:200' } : {}}
        as={NavLink}
        to={!!isClickable || !isLoading ? routeNames.acquisition : undefined}
        p={'6'}
        justifyContent={'space-between'}
      >
        <VStack alignItems={'start'}>
          <Text fontSize={'2xl'} fontWeight={'semibold'}>
            {projectName}
          </Text>
          <Text fontSize={'1xl'} opacity={0.6}>
            {getAcquisitionTitle({ customValues, batchType, name })}
          </Text>
        </VStack>
        <Container borderRadius={'full'} bg={theme.colors.white} w={'fit-content'} h={'fit-content'} p={4}>
          <Folder boxSize={24} />
        </Container>
      </HStack>
    </LinkBox>
  )
}
