export default function buildMatchFn<Result extends any, DefaultMatchWidth extends any, DefaultParseWidth extends any>(
  args: any
): any {
  return (string: any, options = {} as any) => {
    const width = options.width

    const matchPattern = (width && args.matchPatterns[width]) || args.matchPatterns[args.defaultMatchWidth]
    const matchResult = string.match(matchPattern)

    if (!matchResult) {
      return null
    }
    const matchedString = matchResult[0]

    const parsePatterns = (width && args.parsePatterns[width]) || args.parsePatterns[args.defaultParseWidth]

    const key = (
      Array.isArray(parsePatterns)
        ? findIndex(parsePatterns, (pattern: any) => pattern.test(matchedString))
        : findKey(parsePatterns, (pattern: any) => pattern.test(matchedString))
    ) as Result extends any ? string : number

    let value: Result

    value = (args.valueCallback ? args.valueCallback(key) : key) as Result
    value = options.valueCallback ? options.valueCallback(value) : value

    const rest = string.slice(matchedString.length)

    return { value, rest }
  }
}

function findKey<Value, Obj extends any>(object: any, predicate: (value: Value) => boolean): any {
  for (const key in object) {
    if (key in object && predicate(object[key])) {
      return key
    }
  }
  return undefined
}

function findIndex<Item>(array: any, predicate: (item: Item) => boolean): number | undefined {
  for (let key = 0; key < array.length; key++) {
    if (predicate(array[key])) {
      return key
    }
  }
  return undefined
}
