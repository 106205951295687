import { Icon, IconProps } from '@chakra-ui/react'
export const GlassIcon = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 24 24" fill={'currentcolor'} {...props}>
      <path
        d="M5.75,3 C6.09517797,3 6.375,3.27982203 6.375,3.625 C6.375,3.97017797 6.09517797,4.25 5.75,4.25 L5.125,4.25 C4.08946609,4.25 3.25,5.08946609 3.25,6.125 L3.25030033,13.0628293 C4.04435219,12.2526652 5.15097422,11.75 6.375,11.75 C8.57908257,11.75 10.4024882,13.3798718 10.70571,15.5001086 L13.29429,15.5001086 C13.5975118,13.3798718 15.4209174,11.75 17.625,11.75 C18.8495227,11.75 19.9565464,12.2530735 20.7506667,13.0638163 L20.75,6.125 C20.75,5.08946609 19.9105339,4.25 18.875,4.25 L18.25,4.25 C17.904822,4.25 17.625,3.97017797 17.625,3.625 C17.625,3.27982203 17.904822,3 18.25,3 L18.875,3 C20.6008898,3 22,4.39911016 22,6.125 L22,16.125 L21.9999588,16.1441883 C21.9896215,18.5516074 20.0348465,20.5 17.625,20.5 C15.4212624,20.5 13.5980826,18.8706384 13.2944325,16.750887 L10.7055675,16.750887 C10.4019174,18.8706384 8.57873758,20.5 6.375,20.5 C3.95875422,20.5 2,18.5412458 2,16.125 L2,6.125 C2,4.39911016 3.39911016,3 5.125,3 L5.75,3 Z M6.375,13 C4.7086236,13 3.34687892,14.3042834 3.25494691,15.9476693 L3.25,16.089 L3.25001592,16.1350833 C3.25545267,17.856336 4.6524725,19.25 6.375,19.25 C8.10088984,19.25 9.5,17.8508898 9.5,16.125 C9.5,14.3991102 8.10088984,13 6.375,13 Z M17.625,13 C15.8991102,13 14.5,14.3991102 14.5,16.125 C14.5,17.8508898 15.8991102,19.25 17.625,19.25 C19.3508898,19.25 20.75,17.8508898 20.75,16.125 C20.75,14.3991102 19.3508898,13 17.625,13 Z"
        id="Combined-Shape"
      ></path>
    </Icon>
  )
}
