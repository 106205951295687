import { CSSProperties } from 'react'

const containerStyle = {
  display: 'flex',
  flexDirection: 'column',
} as CSSProperties

export const styleProviderServices = {
  containerStyle,
}
