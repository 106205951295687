import { Flex, Text } from '@chakra-ui/react'
import { useMe, useMeExtended } from '@happywait/hw-lib-front.core.api'
import { PropsWithChildren, ReactElement } from 'react'
import { Navigate } from 'react-router-dom'
import { isNil } from 'remeda'
import { Separator } from '../../../../../components/Separator/Separator'
import { LoginModuleForm } from '../../../components/LoginModuleForm/LoginModuleForm'

export type OnboardingWrapperProps = {
  renderStepIndex?: () => ReactElement
  renderContent: () => ReactElement
  renderTitle: () => string
}

export const OnboardingWrapper = ({
  renderContent,
  renderStepIndex,
  renderTitle,
}: PropsWithChildren<OnboardingWrapperProps>) => {
  const { data: meExtended } = useMeExtended()
  const { data: me } = useMe()

  if (!isNil(meExtended) && !me?.temporary && meExtended.onboardingCompleted) {
    return <Navigate to={'/home'} />
  }

  return (
    <LoginModuleForm>
      <Flex>
        <Text fontStyle={'bold'} fontSize={22}>
          {renderTitle()}
        </Text>
        {renderStepIndex?.()}
      </Flex>
      <Separator height={20} />
      {renderContent()}
    </LoginModuleForm>
  )
}
