import { getHexWithAlpha } from './hexaTransparency'

export const primary = window.color || '#4B9190'
export const dark = '#000000'
export const black = '#2E3844'
export const error = '#F75234'
export const alert = '#F3961E'
export const success = '#50E2AE'

export const colors = {
  primary: primary,
  primary10: getHexWithAlpha(primary, 10),
  primary20: getHexWithAlpha(primary, 20),
  primary30: getHexWithAlpha(primary, 30),
  primary40: getHexWithAlpha(primary, 40),
  primary50: getHexWithAlpha(primary, 50),
  primary60: getHexWithAlpha(primary, 60),
  grey: '#D3D8DE',
  grey20: '#F3F5F7',
  grey60: '#E2E6EA',
  darkgrey: '#959595',
  black,
  black40: getHexWithAlpha(black, 40),
  black60: getHexWithAlpha(black, 60),
  dark,
  dark20: getHexWithAlpha(dark, 20),
  dark40: getHexWithAlpha(dark, 40),
  dark60: getHexWithAlpha(dark, 60),
  blue: '#006FFF',
  info: '#006FFF',
  white: '#FFFFFF',
  alert,
  alert20: getHexWithAlpha(alert, 20),
  error,
  error20: getHexWithAlpha(error, 20),
  success,
  success20: getHexWithAlpha(success, 20),
  shadow: getHexWithAlpha('#102E56', 2),
  servicePrimary: '#102E56',
  yellow: '#FAD327',
  yellowdark: '#ffb800',
  shadowYellow: '#FEFBEB',
}
