import { CustomerApi } from '@happywait/hw-lib-front.core.api'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useMatch } from 'react-router-dom'
import { buildContext } from '../../services/context/buildContext'
import { SituationInformationFormValues } from './components/ComplementaryInformationForm/SituationInformationForm.services'
import { InformationFormValues } from './components/InformationForm/InformationForm.services'

export type InvitationOutletContextProps = {
  projectName?: string
  currencyCode?: string
  customer?: CustomerApi
  next: Function
  button: string
  isLoading?: boolean
}

export type InvitationForm = {
  information: InformationFormValues
  situation: SituationInformationFormValues
}

export type InvitationContextProps = {
  formData: InvitationForm
  setFormData: Dispatch<SetStateAction<InvitationForm>>
  state: number
  setState: Dispatch<SetStateAction<number>>
  informationMode: string
  setInformationMode: Dispatch<SetStateAction<string>>
}

export const [InvitationContext, InvitationContextProvider, useInvitationContext] =
  buildContext<InvitationContextProps>()

export const useRouteId = () => {
  const [routeId, setRouteId] = useState('1')
  const match = useMatch('/invitation/:token/:page')

  useEffect(() => {
    setRouteId(match?.params?.page ?? '1')
  }, [match])
  return routeId
}

export const useMaxStep = (informationMode: string) => {
  const [maxStep, setMaxStep] = useState(2)

  useEffect(() => {
    setMaxStep(informationMode === 'person' ? 2 : 1)
  }, [informationMode])
  return maxStep
}

const paddingWithBottomStyles = {
  sm: '1rem 5%',
  md: '2rem 10%',
  xl: '2rem 10%',
}

const paddingStyles = {
  sm: '1rem 5% 0',
  md: '2rem 10% 0',
  xl: '2rem 10% 0',
}

const marginStyles = {
  sm: '17% 0 0',
  md: '6% 0 0',
  lg: '6% 0 0',
  xl: '5% 0 0',
}

export const invitationFormService = {
  paddingStyles,
  marginStyles,
  paddingWithBottomStyles,
}
