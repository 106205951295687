import { Center } from '@chakra-ui/react'
import { useMe, useMeExtended } from '@happywait/hw-lib-front.core.api'
import { usePromoterConfiguration } from '@happywait/hw-lib-front.core.api/v1'
import { Loader } from 'components/Loader/Loader'
import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useStore } from 'store'
import { selectToken } from 'store/selectors'
import { OnboardingFull } from './OnboardingFull/OnboardingFull'
import { OnboardingPartial } from './OnboardingPartial/OnboardingPartial'

export const Onboarding = () => {
  const { data: promoterConfiguration, isLoading } = usePromoterConfiguration()
  const navigate = useNavigate()
  const token = useStore(selectToken)
  const { data: me } = useMe()
  const { data: meExtended } = useMeExtended({ queryParams: { enabled: !!token } })
  const [searchParams] = useSearchParams()
  const redirection = searchParams.get('redirection')

  useEffect(() => {
    if ((!me?.temporary && meExtended?.onboardingCompleted) || me?.isObserver) {
      return navigate(redirection ?? '/home')
    }
  }, [me, meExtended?.onboardingCompleted, navigate, redirection])

  if (isLoading)
    return (
      <Center w={'100%'} h={'100%'}>
        <Loader />
      </Center>
    )

  if (!promoterConfiguration?.blockConsumerProfileEdition) return <OnboardingFull />

  return <OnboardingPartial />
}
