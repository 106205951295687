import { css } from 'styled-components'

type Shadow = {
  x: number
  y: number
  blur: number
  spread: number
  color: string
}

export const createShadow = ({ x, y, blur, spread, color }: Shadow) => css`
  box-shadow: ${x}px ${y}px ${blur}px ${spread}px ${color};
  -webkit-box-shadow: ${x}px ${y}px ${blur}px ${spread}px ${color};
  -moz-box-shadow: ${x}px ${y}px ${blur}px ${spread}px ${color};
`

export const hideScrollBar = css`
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`
