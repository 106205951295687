import { Toast } from '@chakra-ui/react'
import { useMe, useMeExtended } from '@happywait/hw-lib-front.core.api'
import { useCompleteOnboarding } from '@happywait/hw-lib-front.core.api/legacy'
import { useQueryClient } from '@tanstack/react-query'
import useNavigateParams from 'hooks/useNavigateParams'
import { toast } from 'react-toastify'
import { useStore } from 'store'
import { selectLocale, selectToken } from 'store/selectors'
import { useDevice } from '../../../../../hooks/useDevice'
import { useTranslate } from '../../../../../services/i18n'
import { OnboardingStep1 } from '../OnboardingSteps/OnboardingStep1'
import { OnboardingWrapper } from '../OnboardingWrapper/OnboardingWrapper'

export const OnboardingPartial = () => {
  const isMobile = useDevice('mobileL')
  const token = useStore(selectToken)
  const t = useTranslate()
  const navigate = useNavigateParams()
  const queryClient = useQueryClient()
  const { data: me } = useMe()
  const locale = useStore(selectLocale)
  const { data: meExtended } = useMeExtended({ queryParams: { enabled: !!token } })

  const { mutateAsync } = useCompleteOnboarding({
    onError: (e) => {
      toast(<Toast title={t('api.adminError')} variant="error" />)
    },
  })

  const renderTitle = () => {
    if (isMobile) {
      return `${t('welcome')} !`
    }
    return t('login:onboardingScreen.step1.title')
  }

  const onSuccessPartial = async () => {
    if (!meExtended?.onboardingCompleted) {
      await mutateAsync({
        cellphone: !!me?.phone ? me.phone : undefined,
        email: !!me?.email ? me.email : undefined,
        lang: locale ? locale : undefined,
      })
    }

    queryClient.invalidateQueries(['me-extended'])
    queryClient.invalidateQueries(['me'])
    navigate('/home')
  }

  const renderContent = () => <OnboardingStep1 onSuccess={() => onSuccessPartial()} />

  return <OnboardingWrapper renderTitle={renderTitle} renderContent={renderContent} />
}
