import { FieldError } from 'react-hook-form'
import { useTranslate } from '../services/i18n'

export const useFormErrorTranslate = (ns?: string) => {
  const t = useTranslate(ns || 'common')

  return (error?: FieldError): string => {
    if (!error) {
      return ''
    }

    return t(`errors.form.${error.type}`) || t(`errors.${error.message}`) || error.message || ''
  }
}
