import styled from 'styled-components'
import { regular14 } from '../../../../design-system/Font/Font'
import { device } from '../../../../theme'

const Container = styled.div`
  display: flex;
  flex: 1;
`

const ImageContainer = styled.div`
  flex: 1 1 auto;
  object-fit: cover;
  max-height: 100vh;
  @media ${device.tabletL} {
    flex: 1 1 50%;
  }
  @media ${device.tabletS} {
    display: none;
  }
`

const Image = styled.img`
  object-fit: cover;
  height: 100%;
  width: 100%;
`

const PromoterPicture = styled.img`
  max-width: 350px;
  max-height: 12vh;
  @media screen and (min-height: 700px) {
    position: absolute;
    top: 7vh;
    left: calc(0.04 * 100vw);
  }
  @media screen and (max-height: 699px) {
    align-self: flex-start;
    margin-bottom: 20px;
  }
`

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  padding: 7vh calc(0.04 * 100vw);
  padding-bottom: 3vh;
  flex: 0 0 600px;
  max-height: 100vh;
  overflow: auto;

  @media ${device.desktopS} {
    flex: 0 0 450px;
  }
  @media ${device.tabletL} {
    flex: 1 1 50%;
  }
`

const Form = styled.form`
  flex: 1;
  justify-content: center;
  display: flex;
  flex-direction: column;
`

const BackButton = styled.div`
  display: flex;
  align-items: center;
  opacity: 0.6;
  :hover {
    cursor: pointer;
  }
`

const BackText = styled.div`
  ${regular14};
  margin-left: 12px;
`

const NavContainer = styled.div`
  display: flex;
`

const StepIndexContainer = styled.div`
  margin-left: auto;
`

export const LoginModuleFormStyles = {
  Container,
  Image,
  ImageContainer,
  Form,
  PromoterPicture,
  BackButton,
  BackText,
  NavContainer,
  Content,
  StepIndexContainer,
}
