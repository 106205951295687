import { Tooltip, TooltipProps } from '@chakra-ui/react'
import { PropsWithChildren } from 'react'

export type TooltipWrapperProps = {
  hidden?: boolean
} & Omit<TooltipProps, 'hidden'>

export const TooltipWrapper = ({ hidden = false, children, ...props }: PropsWithChildren<TooltipWrapperProps>) => {
  if (hidden) return <>{children}</>

  return (
    <Tooltip whiteSpace={'pre-line'} {...props}>
      {children}
    </Tooltip>
  )
}
