import { ApiErrorV3 } from '@happywait/hw-lib-front.core.api'
import { isEmpty } from 'ramda'
import { useCallback } from 'react'
import { useTranslate } from 'services/i18n'

const types = ['info', 'warning', 'error'] as const
export type ErrorMessages = Record<string, string>

export type ApiErrorTranslate = {
  type: typeof types[number] | undefined
  messages: ErrorMessages | undefined
}

export const useApiErrorTranslate = (context?: string) => {
  const t = useTranslate(context)
  return useCallback(
    (error: ApiErrorV3): ApiErrorTranslate | undefined => {
      const exception = error.response?.data.exception
      if (!exception) return undefined

      const type = types.find((t) => t === exception.type)

      const messages = exception.errors.reduce<ErrorMessages>(
        (_, error) => ({
          [error.field ? error.field : 'global']: t(`errors.api.code_message.${error.code_message}`)
            ? t(`errors.api.code_message.${error.code_message}`, {
                ...error.value_params,
                ...Object.entries(error.code_params || {}).reduce(
                  (_, [key, value]) => ({
                    [key]: t(`errors.api.code_params.${value}`),
                  }),
                  {}
                ),
              })
            : error.code_message,
        }),
        {}
      )
      return { type, messages: !isEmpty(messages) ? messages : undefined }
    },
    [t]
  )
}
