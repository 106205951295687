import styled from 'styled-components'
import { bold20, regular14 } from '../Font/Font'

const Container = styled.div`
  display: flex;
  align-items: baseline;
  flex-wrap: nowrap;
`

const Index = styled.div`
  ${bold20};
  margin-right: 6px;
`

const Total = styled.div`
  ${regular14};
  opacity: 0.6;
  white-space: nowrap;
`

export const StepIndexStyles = {
  Container,
  Index,
  Total,
}
