import { useTheme } from 'styled-components'

export const ActionError = () => {
  const theme = useTheme()

  return (
    <svg width="664px" height="377px" viewBox="0 0 664 377" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>Happywait-404</title>
      <g id="Erreur" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Erreur-page" transform="translate(-389.000000, -223.000000)">
          <g id="Happywait-404" transform="translate(390.000000, 224.000000)">
            <path
              d="M32.21,355.5 C137.51,333.64 161.55,268.71 187.63,268.71 C224.72,268.71 215.27,281.59 241.23,281.59 C282.49,281.59 293.12,247.98 355.4,247.98 C405.4,247.98 428.48,311.8 458.61,311.8 C480.61,311.8 498.55,294.8 522.17,294.8 C555.11,294.8 554.74,345.2 643.06,355.11 L32.21,355.5 Z"
              id="Path"
              fill="#FFFFFF"
              fillRule="nonzero"
            ></path>
            <g
              id="Group"
              opacity="0.25"
              transform="translate(68.901242, 121.468836)"
              fill={theme.colors.primary}
              fillRule="nonzero"
            >
              <path
                d="M40.5287584,193.111164 C106.778758,206.191164 110.138758,155.591164 91.4587584,130.191164 C72.3287584,103.701164 106.648758,89.8211638 96.5087584,64.4811638 C84.5087584,32.2211638 101.628758,18.1511638 93.7587584,4.15116376 C88.3987584,-6.19883624 63.3187584,2.62116376 51.9787584,32.6811638 C40.8287584,63.0111638 53.9087584,87.1011638 26.9787584,103.101164 C-4.52124156,121.181164 -17.9812416,180.581164 40.5287584,193.111164 Z"
                id="Path"
              ></path>
            </g>
            <path
              d="M138.77,158.52 C126.616491,223.147567 118.812302,288.517673 115.41,354.19"
              id="Path"
              stroke={theme.colors.primary}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <path
              d="M119.58,295.97 C129.206667,288.783333 138.873333,281.74 148.58,274.84"
              id="Path"
              stroke={theme.colors.primary}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <path
              d="M123.51,258.44 C119.41,253.773333 115.333333,249.043333 111.28,244.25"
              id="Path"
              stroke={theme.colors.primary}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <path
              d="M147.18,204.69 C141.18,208.556667 135.18,212.483333 129.18,216.47"
              id="Path"
              stroke={theme.colors.primary}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <circle
              id="Oval"
              stroke={theme.colors.primary}
              strokeWidth="2"
              fill="#FFFFFF"
              fillRule="nonzero"
              strokeLinecap="round"
              cx="37.16"
              cy="201.6"
              r="4.95"
            ></circle>
            <circle
              id="Oval"
              stroke={theme.colors.primary}
              strokeWidth="2"
              fill="#FFFFFF"
              fillRule="nonzero"
              strokeLinecap="round"
              cx="423.03"
              cy="267.42"
              r="4.95"
            ></circle>
            <circle
              id="Oval"
              stroke={theme.colors.primary}
              strokeWidth="2"
              fill="#FFFFFF"
              fillRule="nonzero"
              strokeLinecap="round"
              cx="489.45"
              cy="41.05"
              r="7.45"
            ></circle>
            <circle
              id="Oval"
              stroke={theme.colors.primary}
              strokeWidth="2"
              fill="#FFFFFF"
              fillRule="nonzero"
              strokeLinecap="round"
              cx="592.88"
              cy="164.59"
              r="3.73"
            ></circle>
            <circle
              id="Oval"
              stroke={theme.colors.primary}
              strokeWidth="2"
              fill="#FFFFFF"
              fillRule="nonzero"
              strokeLinecap="round"
              cx="150.03"
              cy="80.62"
              r="3.73"
            ></circle>
            <path
              d="M466.97,373.63 C466.97,374.89 433.06,375.92 338.47,375.92 C243.88,375.92 222.8,374.92 222.8,373.63 C222.8,372.34 243.87,371.33 338.47,371.33 C433.07,371.33 466.97,372.36 466.97,373.63 Z"
              id="Path"
              fill={theme.colors.primary}
              fillRule="nonzero"
              opacity="0.25"
            ></path>
            <path
              d="M536,355.66 C574.54,341.21 603,283.35 582.06,275.23 C564.06,268.23 548.11,301.33 548.11,301.33 C548.11,301.33 583,245 560.84,232.54 C533.84,217.37 517.74,283.27 517.74,283.27 C517.74,283.27 522.74,236.02 498.17,246.74 C473.6,257.46 473.27,326.33 490,355.66 L536,355.66 Z"
              id="Path"
              fill={theme.colors.primary}
              fillRule="nonzero"
              opacity="0.25"
            ></path>
            <path
              d="M231.16,356.11 C217.79,349.35 207.27,323.98 214.32,320.76 C220.39,317.98 226.32,332.49 226.32,332.49 C226.32,332.49 213.62,307.69 221.03,302.61 C230.03,296.43 236.45,325.11 236.45,325.11 C236.45,325.11 234.08,304.64 242.64,309.6 C251.2,314.56 252.24,344.31 246.92,356.74 L231.16,356.11 Z"
              id="Path"
              fill={theme.colors.primary}
              fillRule="nonzero"
              opacity="0.25"
            ></path>
            <line
              x1="0"
              y1="355.66"
              x2="661.34"
              y2="355.66"
              id="Path"
              stroke={theme.colors.primary}
              strokeWidth="2"
              strokeLinecap="round"
            ></line>
            <path
              d="M178.48,226.95 C219.04,244.75 258.27,227.02 236.35,220.25 C217.89,214.56 221.8,241.92 259.6,239.08"
              id="Path"
              stroke={theme.colors.primary}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <g
              id="Group-8"
              transform="translate(271.710920, 160.324465) rotate(15.000000) translate(-271.710920, -160.324465) translate(238.464556, 123.324465)"
              stroke="#2E3844"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
            >
              <path
                d="M20.0771638,67.249245 C17.7014553,70.269258 14.4804213,72.5035773 10.8298887,73.6637581 C3.48675158,75.8641424 -0.0578744668,66.6893257 0.000714393381,62.8582995 C0.0593032536,59.0272733 10.7420054,62.416258 16.4837137,61.5911139 L39.2454859,26.8368299 L31.0650216,16.4134048 C43.6151861,6.77237882 42.2217919,7.32911932 57.4763863,0 C64.9819801,11.631141 71.4498294,26.5224892 61.1479548,34.0961334 C50.8460802,41.6697776 20.0771638,67.249245 20.0771638,67.249245 Z"
                id="Path"
                fill="#FFFFFF"
                fillRule="nonzero"
                transform="translate(33.246363, 37.000000) scale(-1, 1) translate(-33.246363, -37.000000) "
              ></path>
              <line
                x1="18.0617907"
                y1="31.5313075"
                x2="24.6727667"
                y2="21.7376327"
                id="Path"
                transform="translate(21.367279, 26.634470) scale(-1, 1) rotate(55.979663) translate(-21.367279, -26.634470) "
              ></line>
            </g>
            <path
              d="M282.56,179.435098 L291.56,329.840363 C293.42,337.488088 292.56,349.066024 291.56,354.19 C290.56,359.313976 299.48,364.68 299.48,364.68 L321.9,364.68 C321.9,364.68 322.9,339.417014 322.38,298.799095 C321.87,262.370431 321.6,251.459763 321.6,251.459763 C321.6,251.459763 339.99,312.879407 342.32,318.895618 C346.92,330.792079 346.5,337.427961 350.41,345.475067 C353.41,351.669725 353.561115,351.746847 362.361115,364.68 L389.97,364.68 C389.97,364.68 353.07,213.535456 350.41,169.68 C321.55,176.393003 282.56,179.435098 282.56,179.435098 Z"
              id="Path"
              stroke="#2E3844"
              strokeWidth="2"
              fill="#FFFFFF"
              fillRule="nonzero"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <polygon
              id="Path"
              stroke="#2E3844"
              strokeWidth="2"
              fill="#2E3844"
              fillRule="nonzero"
              strokeLinecap="round"
              strokeLinejoin="round"
              points="388.03 365.87 391.53 369.51 407.44 373.99 369.34 373.99 366.39 365.42"
            ></polygon>
            <polygon
              id="Path"
              stroke="#2E3844"
              strokeWidth="2"
              fill="#2E3844"
              fillRule="nonzero"
              strokeLinecap="round"
              strokeLinejoin="round"
              points="321.11 365.45 323.12 374.29 287.68 374.85 301.19 370.17 301.96 365.56"
            ></polygon>
            <line
              x1="313"
              y1="224"
              x2="322.6"
              y2="255.01"
              id="Path"
              stroke="#2E3844"
              strokeWidth="2"
              fill="#FFFFFF"
              fillRule="nonzero"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></line>
            <path
              d="M292.29,103.83 C284.34,120.63 282.02,165.93 276.87,184.68 C283.26,192.13 297.58,193.17 318.15,187.4 C336.53,182.25 340.15,176.85 355.62,180.01 C356.17,157.84 352.91,117.26 352.91,117.26"
              id="Path"
              stroke="#2E3844"
              strokeWidth="2"
              fill={theme.colors.primary}
              fillRule="nonzero"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <line
              x1="302.95"
              y1="0"
              x2="303.38"
              y2="12.37"
              id="Path"
              stroke="#2E3844"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></line>
            <line
              x1="287.94"
              y1="18.39"
              x2="278.67"
              y2="7.4"
              id="Path"
              stroke="#2E3844"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></line>
            <line
              x1="281.23"
              y1="32.33"
              x2="269.95"
              y2="28.47"
              id="Path"
              stroke="#2E3844"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></line>
            <path
              d="M360.67,194.72 C358.237071,197.794385 354.938457,200.068931 351.2,201.25 C343.68,203.49 340.05,194.15 340.11,190.25 C340.17,186.35 351.11,189.8 356.99,188.96 L380.3,153.58 L369.75,145.58 C380.94,133.96 388.23,128.28 398.97,126.26 C414.83,136.83 413.28,153.26 402.73,160.97 C392.18,168.68 360.67,194.72 360.67,194.72 Z"
              id="Path"
              stroke="#2E3844"
              strokeWidth="2"
              fill="#FFFFFF"
              fillRule="nonzero"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <line
              x1="380.3"
              y1="153.54"
              x2="387.03"
              y2="143.57"
              id="Path"
              stroke="#2E3844"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></line>
            <path
              d="M345.39,125.22 C345.39,125.22 359.03,135.77 369.75,145.59 C380.67,134.65 389.61,127.75 399.17,126.29 C399.17,126.29 375.7,107.09 357.56,96.82 C339.42,86.55 329.56,86.96 329.56,86.96 C319.97,86.58 310.56,85.77 310.56,85.77 C310.56,85.77 298.81,87.35 288.94,93.13 C279.07,98.91 265.71282,111.064018 257.88282,118.314018 C261.93282,133.074018 278.48,141.48 278.48,141.48 L284.09203,137.21"
              id="Path"
              stroke="#2E3844"
              strokeWidth="2"
              fill={theme.colors.primary}
              fillRule="nonzero"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <path
              d="M292.29,103.83 C284.34,120.63 282.02,165.93 276.87,184.68"
              id="Path"
              stroke="#2E3844"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <path
              d="M302.89,43.34 C298.78,40.54 295.15,31.92 300.13,30.34 C305.11,28.76 305.29,34.63 307.06,34.54 C308.83,34.45 305.94,29.1 314.06,28.91 C318.63,28.91 316.72,33.91 321.33,34.57 C325.94,35.23 327.78,29.04 331.33,33.57 C333.388119,35.9733091 333.226198,39.5618125 330.96,41.77 C330.96,41.77 337.96,40.66 338.39,44.97 C338.82,49.28 332.94,50.86 332.68,52.58 C332.42,54.3 339.5,59.14 334.12,62.44 C330.717553,64.5320563 328.37068,67.9777755 327.67,71.91 C324.9,70.14 319,61.68 319,61.68 L312.93,42.47 L302.89,43.34 Z"
              id="Path"
              stroke="#2E3844"
              strokeWidth="2"
              fill="#2E3844"
              fillRule="nonzero"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <path
              d="M311.41,75.58 C309.98472,79.1982986 309.330761,83.074366 309.49,86.96 L309.49,86.96 L317.49,103.03 L329.61,87.32 L329.61,87.32 C329.61,87.32 327.82,81.02 327.77,70.32 C326.94526,69.5589968 326.213502,68.7030415 325.59,67.77 C324.26,65.67 326.23,61.39 327.8,59.46 C329.94,56.82 330.61,51.94 327.29,50.64 C323.11,49 321.04,55.16 321.04,55.16 C321.04,55.16 320.42,54.02 320.76,50.55 C321.1,47.08 321.85,44 319.11,42.36 C316.37,40.72 313.8,45.16 303.97,41.07 C301.51,44.59 303.27,47.22 302.25,51.63 C301.23,56.04 294.91,61.09 296.59,62.63 C298.45,64.34 300.44,62.5 302.35,63.84 C304.26,65.18 302.22,70.4 305.06,73.11 C307.9,75.82 311.57,75.58 315.67,74.58"
              id="Path"
              stroke="#2E3844"
              strokeWidth="2"
              fill="#FFFFFF"
              fillRule="nonzero"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <path
              d="M305.48,116.33 C305.24,117.63 307.06,132.87 321.91,149.7"
              id="Path"
              stroke="#2E3844"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <path
              d="M301.07,131.24 C302.405868,135.568622 304.52424,139.615559 307.32,143.18"
              id="Path"
              stroke="#2E3844"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <path
              d="M421.21,136.38 C424.74,143.58 425.21,151.13 424.69,154.45"
              id="Path"
              stroke="#2E3844"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <path
              d="M431.2,143.58 C432.357099,145.908535 432.85836,148.508178 432.65,151.1"
              id="Path"
              stroke="#2E3844"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <path
              d="M509.55,318.58 C482.36,312.83 475.21,330.67 453.76,331.01 C439.7,331.24 430.76,325.01 410.88,328.55"
              id="Path"
              stroke={theme.colors.primary}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <path
              d="M465.97,126.66 C503.39,146.66 560.44,131.12 546.19,112.2 C537.31,100.41 524.57,110.15 531.6,119.01"
              id="Path"
              stroke={theme.colors.primary}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  )
}
