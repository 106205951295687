import { EntityId } from '@happywait/hw-lib-front.core.api'
import { when } from './helpers'

export type TestProps = {
  'data-test': string
  'data-testid'?: string
}

type TestMaker = (name: string, id?: EntityId) => TestProps

const makeTest: TestMaker = (name, id) => ({
  'data-test': name,
  'data-testid': when(!!id, `${name}_${id}`),
})

const makeBase =
  (...baseNames: string[]) =>
  (name: string, id?: EntityId) =>
    makeTest(`${baseNames.join('_')}_${name}`, id)

// Define helpers for button test attribute makers
const buttonActionTypes = [
  'open',
  'close',
  'submit',
  'add',
  'delete',
  'change',
  'gobackto',
  'goforwardto',
  'goto',
  'continue',
] as const
export type ButtonActionType = typeof buttonActionTypes[number]
const buttonActions = buttonActionTypes.reduce((acc, t) => ({ ...acc, [t]: makeBase('button', t) }), {}) as Record<
  ButtonActionType,
  TestMaker
>

const input = (type: string, name: string) => makeBase('input', type)(name)

const error = (type: string, name: string, error?: string) => makeBase('error', type)(name, error)

const toast = (type: string, name: string) => makeBase('toast', type)(name)

export const tests = {
  button: buttonActions,
  input,
  error,
  toast,
  makeBase,
  makeTest,
}
