import { InfiniteConversation } from '@happywait/hw-lib-front.core.api'
import { SetState } from 'zustand'
import { Store } from '../types'

export const conversationSlice = (set: SetState<Store>) => ({
  conversations: [],
  conversationRankId: undefined,
  setConversationRankId: (conversationRankId?: number) => set({ conversationRankId }),
  setConversations: (conversations: InfiniteConversation[]) => set({ conversations }),
  addConversation: (conversation: InfiniteConversation) =>
    set((prev) => ({ conversations: [conversation, ...prev.conversations] })),
})
