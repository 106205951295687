import styled from 'styled-components'
import { createShadow } from '../../theme/style.services'
import { regular14 } from '../Font/Font'

const TextArea = styled.textarea`
  transition: all 0.25s ease;
  background-color: ${({ theme }) => theme.colors.grey20};
  padding: 12px 16px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.grey20};
  background-clip: padding-box;
  -webkit-background-clip: padding-box;
  resize: none;
  width: 100%;
  ${regular14}
  :hover {
    background-color: ${({ theme }) => theme.colors.grey60};
    border: 1px solid ${({ theme }) => theme.colors.grey60};
  }
  :focus {
    border: 1px solid ${({ theme }) => theme.colors.primary};
    background-color: ${({ theme }) => theme.colors.white};
    ${({ theme }) => createShadow({ x: 0, y: 0, blur: 0, spread: 3, color: theme.colors.primary20 })}
  }
  ::placeholder {
    opacity: 0.6;
    ${regular14}
  }
`

export const TextAreaStyles = {
  TextArea,
}
