import {
  Button,
  Container,
  Divider,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Portal,
  Text,
  VStack,
} from '@chakra-ui/react'
import { useMePromoterConfiguration, useMedias } from '@happywait/hw-lib-front.core.api'
import { CoCustomerBadge } from 'components/CoCustomerBadge/CoCustomerBadge'
import { ModalWrapper } from 'components/ModalWrapper/ModalWrapper'
import { ProjectSelector } from 'components/ProjectSelector/ProjectSelector'
import { useBaseDev } from 'hooks/useBaseDev'
import { useCustomer } from 'hooks/useCustomer'
import { useDevice } from 'hooks/useDevice'
import useNavigateParams from 'hooks/useNavigateParams'
import { getModuleTitle } from 'modules/tools'
import React, { useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslate } from 'services/i18n'
import { useStore } from 'store'
import { theme } from 'theme'
import { getMediaThumbPath } from 'tools/thumb'
import { routeNames } from '../../modules'

import { selectInitialData, selectResetModals } from '../../store/selectors'
import { menuServices as services } from './Menu.services'

type RenderLinkProps = { title: string; url: string; target?: string }
type RenderOptionProps = { title: string; onClick: () => void }

const { useComponent } = services

const renderLink = ({ title, url, target = '_blank' }: RenderLinkProps): JSX.Element => (
  <>
    <Button
      as={NavLink}
      to={url}
      target={target}
      borderRadius={0}
      paddingX={4}
      justifyContent={'start'}
      size="lg"
      width="100%"
      bgColor={'transparent'}
    >
      <Text fontSize={'2xl'} color={theme.colors.black60}>
        {title}
      </Text>
    </Button>
  </>
)

const renderOption = ({ title, onClick }: RenderOptionProps): JSX.Element => (
  <>
    <Button
      borderRadius={0}
      padding={4}
      py={4}
      justifyContent={'start'}
      size="lg"
      width="100%"
      bgColor={'transparent'}
      onClick={onClick}
    >
      <Text fontSize={'2xl'} color={theme.colors.black60}>
        {title}
      </Text>
    </Button>
  </>
)

const LightDivider = React.memo((): JSX.Element => {
  return <Divider maxW={'90%'} height={5} borderColor={'gray.300'} />
})

const MenuMoreMobile = React.memo((): JSX.Element => {
  const { showNavigationItem } = useComponent()
  const t = useTranslate()

  const isMobileS = useDevice('mobileS')
  const isMobileL = useDevice('mobileL')

  const baseDev = useBaseDev()
  const { mainCustomer } = useCustomer()

  const { data: mePromoter } = useMePromoterConfiguration()
  const initialData = useStore(selectInitialData)
  const { data: promoterLogo } = useMedias({
    params: { ids: [initialData?.logo || 0] },
    queryParams: { enabled: !!initialData?.logo },
  })
  const navigate = useNavigateParams()

  useEffect(() => {
    if (!isMobileL) {
      navigate('/home')
    }
  })

  const closeModals = useStore(selectResetModals)
  const onSelectRoute = (routeName: string) => {
    closeModals()
    navigate(routeName)
  }
  const thumb = getMediaThumbPath(promoterLogo?.data[0], 'logo')

  return (
    <VStack bgColor={''} fontFamily={'Avenir-Medium'} backgroundColor={'white'} minH={'100%'}>
      <HStack width={'100%'}>
        {!!thumb.path && <Image justifyContent={'left'} marginY={'50px'} maxHeight={'50px'} pl={6} src={thumb.path} />}
      </HStack>
      <ProjectSelector />
      {!mainCustomer && (
        <>
          <LightDivider />
          <Container w={'100%'}>
            <CoCustomerBadge displayIcon subTitle />
          </Container>
        </>
      )}
      <VStack spacing={30} w={'100%'} mt={10} p={2}>
        {showNavigationItem('MEDIA') && (
          <>
            {isMobileS &&
              renderOption({
                title: getModuleTitle('documents') || t('documents'),
                onClick: () => onSelectRoute('documents'),
              })}
            {renderOption({ title: t('pictures'), onClick: () => onSelectRoute('pictures') })}
          </>
        )}
        {showNavigationItem('SERVICE') &&
          renderOption({
            title: getModuleTitle('services') || t('services'),
            onClick: () => onSelectRoute('services'),
          })}
        {showNavigationItem('TOOL') &&
          renderOption({
            title: getModuleTitle('choices-options') || t('choicesAndOptions'),
            onClick: () => onSelectRoute('choices-options'),
          })}
        {showNavigationItem('FUNDING_APPEAL') &&
          renderOption({
            title: getModuleTitle('funding-appeal') || t('fundingAppeal'),
            onClick: () => onSelectRoute('funding-appeal'),
          })}
        {showNavigationItem('SNAG') &&
          renderOption({
            title: getModuleTitle('snags') || t('snags'),
            onClick: () => onSelectRoute(routeNames.snags),
          })}
        {showNavigationItem('EXTERNAL_AFTER_SALE') &&
          renderLink({
            title: t('sav'),
            url: mePromoter?.afterSaleServiceUrl || '',
          })}
        {showNavigationItem('CHECKLIST') &&
          renderOption({
            title: getModuleTitle('checklist') || t('checklist'),
            onClick: () => onSelectRoute(routeNames.checklist),
          })}
        {showNavigationItem('SHOWYOU') &&
          renderOption({
            title: t('personalization'),
            onClick: () => onSelectRoute(routeNames.personalization),
          })}
        {showNavigationItem('HOUSING_LOGBOOK') &&
          renderOption({
            title: t('housing_logbook'),
            onClick: () => onSelectRoute(routeNames.housing_logbook),
          })}
        <LightDivider />
        {showNavigationItem('PROFIL') &&
          renderOption({
            title: getModuleTitle('account') || t('myProfile'),
            onClick: () => onSelectRoute(routeNames.account),
          })}
        {renderOption({ title: t('logoutMe'), onClick: () => onSelectRoute(routeNames.logout) })}
      </VStack>
    </VStack>
  )
})

export const MenuMoreMobileModal = () => {
  const closeModals = useStore(selectResetModals)
  const handleCloseModal = () => {
    closeModals()
  }
  return (
    <>
      <ModalWrapper modalId="menuMoreMobile">
        {() => (
          <Portal>
            <Modal isOpen onClose={handleCloseModal} size="full">
              <ModalOverlay />
              <ModalContent>
                <ModalCloseButton backgroundColor={'transparent'} size="lg" />
                <ModalBody padding={0}>
                  <MenuMoreMobile />
                </ModalBody>
              </ModalContent>
            </Modal>
          </Portal>
        )}
      </ModalWrapper>
    </>
  )
}
