import { AxiosInstance, AxiosRequestConfig } from 'axios'
import { toast } from 'react-toastify'
import { useStore } from 'store'
import { Toast } from '../../design-system/Toast/Toast'
import { getBaseDev } from '../../utils/helpers'
import { _i18n } from '../i18n'

let isRefreshing = false

interface RetryQueueItem {
  resolve: (value?: any) => void
  reject: (error?: any) => void
  config: AxiosRequestConfig
  api: AxiosInstance
}

const refreshAndRetryQueue: RetryQueueItem[] = []

const hide500ErrorsAt = ['/v3/addons', '/v2/cgu/validate']

const loginUrl = ['/login_check', '/logout_token']

export const unauthorizedAccessHandler = (api) => async (error) => {
  if (!navigator.onLine) {
    toast(<Toast title={_i18n.t('api.lostInternetConnection')} variant="error" />)
  }

  if (
    error.response &&
    error.response.status === 500 &&
    !hide500ErrorsAt.some((url) => error.config?.url?.startsWith(url))
  ) {
    toast(<Toast title={_i18n.t('api.networkError')} variant="error" />)
    return Promise.reject(error)
  }

  if (
    !error.response ||
    error.response.status !== 401 ||
    !error.response.config.url ||
    loginUrl.some((url) => error.response.config.url.includes(url))
  ) {
    return Promise.reject(error)
  }

  const store = localStorage.getItem('reduxPersist:login')
  const token = JSON.parse(store || '{}')?.token

  if (
    !token ||
    error.response.config.url.includes('refresh_access_token') ||
    (error.config.headers && error.config.headers._retry && Number(error.config.headers._retry) >= 3)
  ) {
    logout()
    return
  }

  if (isRefreshing) {
    return new Promise<void>((resolve, reject) => {
      refreshAndRetryQueue.push({ api, config: error.config, resolve, reject })
    })
  }

  return launchRefreshToken(api, error.config)
}

const launchRefreshToken = async (api, originalConfig) => {
  isRefreshing = true
  const responseToken = await fetch(`${getBaseDev()}api/v3/refresh_access_token`, {
    method: 'POST',
  })
  if (responseToken?.status !== 200) {
    logout()
    return
  }

  const refreshToken = await responseToken.text()
  localStorage.setItem('reduxPersist:login', JSON.stringify({ token: refreshToken }))
  isRefreshing = false

  refreshAndRetryQueue.forEach(({ api, config, resolve, reject }) => {
    relaunchRequest(api, config)
      .then((response) => resolve(response))
      .catch((err) => reject(err))
  })

  if (originalConfig.headers) {
    originalConfig.headers._retry = Number(originalConfig.headers?._retry ?? 0) + 1
  }

  return relaunchRequest(api, originalConfig)
}

const relaunchRequest = (api, config) => {
  if (config.headers) {
    config.headers._retry = Number(config.headers?._retry ?? 0) + 1
  }
  return api(config)
}

const logout = () => {
  useStore.getState().logout(false)
}
