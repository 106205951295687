import styled from 'styled-components'
import { device, theme } from '../../theme'

type ContentStyleProps = {
  hideMenu: boolean
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 100%;
`

const Logo = styled.img`
  max-width: 200px;
  max-height: 60px;
  margin-left: 20px;
  cursor: pointer;
  @media ${device.mobileL} {
    max-height: 50px;
  }
`

const Header = styled.div`
  display: none;
  align-items: center;
  background-color: ${theme.colors.white};
  @media ${device.tabletL} {
    flex: 0 0 90px;
    display: flex;
    border-bottom: 1px solid ${theme.colors.grey};
  }
  @media ${device.mobileL} {
    flex: 0 0 70px;
  }
`

const SubContainer = styled.div`
  display: grid;
  grid-template-columns: 250px auto;
  grid-template-rows: 100%;
  height: 100%;
  background-color: ${theme.colors.grey20};
  overflow: hidden;

  @media ${device.tabletL} {
    grid-template-columns: 125px auto;
  }

  @media ${device.mobileL} {
    display: flex;
  }
`

const Content = styled.div<ContentStyleProps>`
  flex: 1 1 auto;
  max-width: 100%;
  min-width: 0;
  @media ${device.mobileL} {
    ${({ hideMenu }) => !hideMenu && { height: 'calc(100% - 80px)' }}
  }
`

export const LayoutStyles = {
  Container,
  Logo,
  Header,
  SubContainer,
  Content,
}
