import { Icon, InputGroup, InputRightAddon, Spinner, Tooltip } from '@chakra-ui/react'
import { InfoCircle } from 'assets/svg/InfoCircle'
import React, { MouseEvent, PropsWithChildren, ReactNode, forwardRef, useEffect, useRef, useState } from 'react'
import useDimensions from 'react-cool-dimensions'
import { useSpring } from 'react-spring'
import ResizeObserver from 'resize-observer-polyfill'
import { tests } from 'utils/tests'
import { TextInputStyles as Styles } from './TextInput.styles'

export type TextInputProps = {
  onClickIcon?: (e: MouseEvent<HTMLInputElement>) => void
  iconLeft?: ReactNode
  iconRight?: ReactNode
  rightAddon?: ReactNode
  placeholderMode?: 'default' | 'animated'
  error?: string
  errorTooltip?: string
  title?: string
  isLoading?: boolean
  dataTest?: string
} & React.InputHTMLAttributes<HTMLInputElement>

export const TextInput = forwardRef<HTMLInputElement, PropsWithChildren<TextInputProps>>(
  (
    {
      onClickIcon,
      placeholderMode = 'default',
      iconLeft,
      rightAddon,
      iconRight,
      error,
      errorTooltip,
      value,
      title,
      placeholder,
      isLoading,
      dataTest,
      ...props
    },
    ref
  ) => {
    const [isFocused, setIsFocused] = useState(false)
    const inputRef = useRef<HTMLInputElement | null>(null)
    const { observe, width } = useDimensions<HTMLDivElement | null>({ polyfill: ResizeObserver })

    useEffect(() => {
      const currentInputRef = inputRef.current

      const onInputFocus = () => {
        setIsFocused(true)
      }
      const onInputBlur = () => {
        setIsFocused(false)
      }

      currentInputRef?.addEventListener('focus', onInputFocus)
      currentInputRef?.addEventListener('blur', onInputBlur)

      return () => {
        currentInputRef?.removeEventListener('focus', onInputFocus)
        currentInputRef?.removeEventListener('blur', onInputBlur)
      }
    }, [inputRef])

    const getLabelContainerTransform = () => {
      if (isFocused || value) {
        if (iconLeft) {
          return 'translate(-36px, -24px)'
        }
        return 'translate(-6px, -24px)'
      }
      return 'translate(0px, 0px)'
    }

    const labelContainerStyle = useSpring({
      transform: getLabelContainerTransform(),
      cursor: isFocused ? 'default' : 'text',
      config: {
        duration: 50,
      },
    })

    const labelStyle = useSpring({
      fontSize: isFocused || value ? '1.2rem' : '1.4rem',
      lineHeight: isFocused || value ? '1.4rem' : '2rem',
      config: {
        duration: 50,
      },
    })

    const testType = props.type || 'text'

    return (
      <Styles.TextInput>
        {title && <Styles.Title>{title}</Styles.Title>}
        <InputGroup>
          <Styles.Container
            isFocused={isFocused}
            error={!!error}
            displayLabel={placeholderMode === 'animated' && !!placeholder && !value}
            data-test={dataTest}
          >
            {iconLeft && <Styles.IconLeft>{iconLeft}</Styles.IconLeft>}
            <Styles.InputContainer ref={observe} onClick={() => inputRef.current?.focus()}>
              <Styles.Input
                ref={(node) => {
                  inputRef.current = node
                  if (typeof ref === 'function') {
                    ref(node)
                  } else if (ref) {
                    ref.current = node
                  }
                }}
                placeholder={placeholderMode === 'default' ? placeholder : ''}
                value={value}
                {...(props.name ? tests.input(testType, props.name) : undefined)}
                {...props}
              />
              <Styles.LabelContainer style={labelContainerStyle}>
                <Styles.Label style={{ ...labelStyle, maxWidth: width }}>{placeholder}</Styles.Label>
              </Styles.LabelContainer>
            </Styles.InputContainer>
            {iconRight && (
              <Styles.IconRightContainer type="button">
                <Styles.IconRight onClick={onClickIcon}>{iconRight}</Styles.IconRight>
              </Styles.IconRightContainer>
            )}
            {isLoading && <Spinner size={'sm'} color="primary.500" />}
            {rightAddon && (
              <InputRightAddon h="42px" border={'none'} background={'transparent'}>
                {rightAddon}
              </InputRightAddon>
            )}
          </Styles.Container>
        </InputGroup>
        {error && (
          <Styles.Error {...(props.name ? tests.error(testType, props.name) : undefined)}>
            {error}
            {errorTooltip && (
              <Tooltip label={errorTooltip} variant="error">
                <Icon ml={1}>
                  <InfoCircle />
                </Icon>
              </Tooltip>
            )}
          </Styles.Error>
        )}
      </Styles.TextInput>
    )
  }
)
