import { Modal, ModalCloseButton, ModalContent, ModalOverlay, Portal } from '@chakra-ui/react'
import { ModalWrapper } from 'components/ModalWrapper/ModalWrapper'
import { UnitTypologySurvey } from 'components/UnitTypologySurvey/UnitTypologySurvey'
import { ConversationEvaluation } from 'modules/home/components/ConversationEvaluation/ConversationEvaluation'
import { equals } from 'ramda'
import { MutableRefObject, useEffect, useRef } from 'react'
import { useModalStore, useStore } from 'store'
import { selectShowModal } from 'store/selectors'
import { useCleanConversationEvaluation } from './hooks/useCleanConversationEvaluation'
import { useWelcomeModalWrapper } from './hooks/useWelcomeModalWrapper'

export const modalsCollection = [
  {
    modalId: 'unitTypologySurvey',
    component: () => <UnitTypologySurvey />,
  },
  {
    modalId: 'conversationEvaluation',
    component: () => <ConversationEvaluation />,
  },
]

// useInitWelcomeModal:
// ┌──────────────────────────────────────────────────────────────────┐
// │                                                                  │
// │  calls all the hook necessary to set the display rules.          │
// │  It will returns an object newValues and newsValuesRef.           │
// │  It also return a parameter to indicate that hooks are fetching │
// │                                                                  │
// └──────────────────────────────────────────────────────────────────┘

//  useWelcomeModalWrapper:
// ┌────────────────────────────────────────────────────────────────────────────────┐
// │                                                                                │
// │    Hook based on newValues and newValuesRef of useInitWelcomeModal.            │
// │ If they are dissonant we update useModalStore's param shouldShowModal.         │
// │ It's will also returns in case the param shouldShowModal is an empty object    │
// │                                                                                │
// └────────────────────────────────────────────────────────────────────────────────┘

//   useModalStore
//  ┌────────────────────────────────────────────────────┐
//  │ small zustand store that contains                  │
//  │ shouldShowModal , setShouldShowModal, removeState  │
//  └────────────────────────────────────────────────────┘

//   WelcomeModalWrapper:
//  ┌──────────────────────────────────────────────────────────────────────────────────────────────────────────────────────┐
//  │                                                                                                                      │
//  │ initialized by useWelcomeModalWrapper hook. We search shouldShowModal param in store.                                │
//  │ We find the first truthy occurence and based on the key that is a modalId we search in                               │
//  │ the ModalsCollection in component linked to the modalId Key. If all hooks are done fetching                          │
//  │ We show the truthy modalId Component.                                                                                │
//  │                                                                                                                      │
//  └──────────────────────────────────────────────────────────────────────────────────────────────────────────────────────┘

export const WelcomeModalWrapper = () => {
  const { isLoading: isLoadingWelcomeModalConditions } = useWelcomeModalWrapper()
  const show = useStore(selectShowModal)
  const { shouldShowModal } = useModalStore((state) => ({
    shouldShowModal: state.shouldShowModal,
  }))
  const { cleanConversationEvaluation, isConversationEvaluationModal } = useCleanConversationEvaluation()

  const shouldShowModalKeys = Object.keys(shouldShowModal) as Array<keyof typeof shouldShowModal>
  const currentTruthyModalId = shouldShowModalKeys.find((key) => shouldShowModal[key] === true)
  const currentTruthyModalIdRef: MutableRefObject<
    'unitTypologySurvey' | 'meantimeContactSurvey' | 'conversationEvaluation' | undefined
  > = useRef(undefined)

  useEffect(() => {
    if (!equals(currentTruthyModalId, currentTruthyModalIdRef.current)) {
      currentTruthyModalIdRef.current = currentTruthyModalId
    }
    if (currentTruthyModalId && !isLoadingWelcomeModalConditions) {
      show({ modalId: currentTruthyModalId })
    }
  }, [isLoadingWelcomeModalConditions, currentTruthyModalId, show])

  return (
    <>
      {!!currentTruthyModalId && !!modalsCollection && (
        <ModalWrapper modalId={currentTruthyModalId}>
          {({ hideModal }) => (
            <Portal>
              <Modal
                closeOnEsc={false}
                isOpen
                onClose={() => {
                  isConversationEvaluationModal(currentTruthyModalId) && cleanConversationEvaluation()
                  hideModal()
                }}
                isCentered
                closeOnOverlayClick={false}
              >
                <ModalOverlay />
                <ModalContent maxW="800px">
                  {currentTruthyModalId === 'conversationEvaluation' && <ModalCloseButton size={'lg'} />}
                  {modalsCollection.find((modal) => modal.modalId == currentTruthyModalId)?.component()}
                </ModalContent>
              </Modal>
            </Portal>
          )}
        </ModalWrapper>
      )}
    </>
  )
}
