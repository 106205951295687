export const Evaluation1 = () => {
  return (
    <svg width={76} height={76} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M37.526 0C16.856 0 .039 16.816.039 37.486c0 20.671 16.816 37.487 37.487 37.487 20.67 0 37.486-16.816 37.486-37.487C75.012 16.816 58.196 0 37.526 0zM23.468 21.867a4.691 4.691 0 014.686 4.686 4.691 4.691 0 01-4.686 4.686 4.691 4.691 0 01-4.685-4.686 4.692 4.692 0 014.685-4.686zm32.467 27.396C51.883 55.58 45 59.353 37.525 59.353c-7.475 0-14.357-3.773-18.408-10.09a1.562 1.562 0 112.63-1.686c3.477 5.416 9.375 8.653 15.779 8.653s12.302-3.237 15.778-8.653a1.561 1.561 0 012.159-.472c.725.465.937 1.434.472 2.158zM51.583 31.24a4.691 4.691 0 01-4.686-4.686 4.691 4.691 0 014.686-4.686 4.691 4.691 0 014.686 4.686 4.691 4.691 0 01-4.686 4.686z"
        fill="#50E2AE"
        fillRule="nonzero"
        opacity={0.8}
      />
    </svg>
  )
}
