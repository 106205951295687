import { Box, Center, Flex } from '@chakra-ui/react'
import { useSaleInvitation, useUpdateSaleInvitation } from '@happywait/hw-lib-front.core.api'
import { Loader } from 'components/Loader/Loader'
import useNavigateParams from 'hooks/useNavigateParams'
import { useToast } from 'design-system/Toast/useToast'
import { useStringParam } from 'hooks/useStringParam'
import { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { useTranslate } from 'services/i18n'
import { useLogout } from '../../hooks/useLogout'
import { InvitationContextProvider, useMaxStep, useRouteId } from './InvitationForm.service'
import { InvitationHeader } from './components/InvitationHeader'

export const InvitationForm = () => {
  const toast = useToast()
  const t = useTranslate()
  const navigate = useNavigateParams()
  const { logoutCustomer } = useLogout()

  const [formData, setFormData] = useState({ information: {}, situation: {} })
  const [informationMode, setInformationMode] = useState('person')
  const [state, setState] = useState<number>(1)

  const invitationData = useStringParam('invitationToken')
  const routeId: string = useRouteId()
  const maxStep: number = useMaxStep(informationMode)

  const {
    data: salesInvitationApi,
    isLoading,
    isSuccess,
  } = useSaleInvitation({
    params: {
      token: invitationData,
    },
    queryParams: {
      onError: () => logoutCustomer(),
    },
  })

  const { mutate, isLoading: isLoadingUpdate } = useUpdateSaleInvitation({
    onSuccess: () => {
      toast({
        title: t(`invForm.toast.success`),
        variant: 'success',
      })
      logoutCustomer()
    },
    onError: () =>
      toast({
        title: t(`invForm.toast.error`),
        variant: 'error',
      }),
  })

  const status = salesInvitationApi?.status || 'OPENED'

  const next = () => {
    if (salesInvitationApi?.id) {
      if (isLastStep(state, maxStep)) {
        mutate({
          customer: { ...formData.situation, ...formData.information, id: salesInvitationApi?.customer.id },
          id: salesInvitationApi.id,
        })
      } else {
        setState(state + 1)
      }
    }
  }

  const isLastStep = (state: number, maxStep: number) => state === maxStep

  const previous = () => {
    forceState(state - 1)
  }

  const forceState = (newState: number) => {
    setState(newState)
  }

  useEffect(() => {
    if (state < parseInt(routeId)) {
      navigate('/invitation/' + invitationData + '/' + state)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routeId])

  useEffect(() => {
    navigate('/invitation/' + invitationData + '/' + state)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state])

  if (isLoading || !isSuccess)
    return (
      <Center w={'100%'} h={'100%'}>
        <Loader />
      </Center>
    )

  return (
    <>
      <Flex id="invitation" width={'100vw'} height={'100%'} style={{ minHeight: '100vh' }} direction={'column'}>
        <Box as="header" backgroundColor="white" top={0} width={'100%'} justifySelf={'flex-start'}>
          <InvitationHeader state={state} maxStep={maxStep} previous={previous} status={status} />
        </Box>
        <InvitationContextProvider
          value={{ formData, setFormData, state, setState, informationMode, setInformationMode }}
        >
          <Outlet
            context={{
              customer: salesInvitationApi?.customer,
              projectName: salesInvitationApi?.projectName,
              currencyCode: salesInvitationApi?.currencyCode,
              button: t(isLastStep(state, maxStep) ? 'validate' : 'next'),
              next: () => next(),
              isLoading: isLoadingUpdate,
            }}
          />
        </InvitationContextProvider>
      </Flex>
    </>
  )
}
