import { SelectOptions } from 'design-system/Select/Select'
import { useController } from 'react-hook-form'
import { Flex, Text } from '@chakra-ui/react'
import { FC, useState } from 'react'
import { useTranslate } from '../../../services/i18n'
import { AutoComplete } from '../../../design-system/AutoComplete/AutoComplete'
import { useFormErrorTranslate } from '../../../hooks/useFormErrorTranslate'

export type FormAutocompleteSelectInputControlledProps = {
  name: string
  options: SelectOptions[]
  noOptionLabel?: string
  disabled?: boolean
  isLoading?: boolean
  title?: string
  querySelectFilterSearch?: string
  setQuerySelectFilterSearch?: (search: string) => void
}

export const FormAutocompleteSelectInputControlled: FC<FormAutocompleteSelectInputControlledProps> = ({
  name,
  title,
  options,
  isLoading,
  disabled,
  noOptionLabel,
  querySelectFilterSearch,
  setQuerySelectFilterSearch,
  ...props
}: FormAutocompleteSelectInputControlledProps) => {
  const tError = useFormErrorTranslate()

  const [searchDefault, setSearchDefault] = useState('')
  const search = querySelectFilterSearch || searchDefault
  const setSearch = setQuerySelectFilterSearch || setSearchDefault

  const t = useTranslate()

  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({ name })

  const searchedOptions = querySelectFilterSearch
    ? options
    : options.filter(({ label }) => label?.toLocaleLowerCase().includes(search.toLocaleLowerCase()))

  return (
    <Flex flexDir="column" {...props}>
      {title && <Text color="gray.400">{title}</Text>}
      <AutoComplete
        filterSearch={search}
        noOptionLabel={noOptionLabel || t('noResults')}
        onChange={(value) => onChange(value)}
        options={searchedOptions}
        value={value}
        setFilterSearch={setSearch}
        disabled={disabled}
        isLoading={isLoading}
        error={tError(error)}
      />
    </Flex>
  )
}
