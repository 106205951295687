import styled from 'styled-components'

const Container = styled.div<{ underline: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;

  > :first-child {
    ${({ underline }) => underline && 'text-decoration:underline'};
  }
  :hover {
    > :first-child {
      text-decoration: underline;
    }
  }
`

export const LinkStyles = {
  Container,
}
