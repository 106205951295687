import styled from 'styled-components'

type DividerStyleProps = {
  marginTop?: number
  marginBottom?: number
  marginLeft?: number
  marginRight?: number
  vertical?: boolean
}

export const Divider = styled.div<DividerStyleProps>`
  width: ${({ vertical }) => (vertical ? '1px' : '100%')};
  height: ${({ vertical }) => (vertical ? '100%' : '1px')};
  margin-top: ${({ marginTop }) => (marginTop ? `${marginTop}px` : undefined)};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? `${marginBottom}px` : undefined)};
  margin-right: ${({ marginRight }) => (marginRight ? `${marginRight}px` : undefined)};
  margin-left: ${({ marginLeft }) => (marginLeft ? `${marginLeft}px` : undefined)};
  background-color: ${({ theme }) => theme.colors.grey};
  border-radius: 1px;
`
