import styled, { css } from 'styled-components'

type FontFamily = 'regular' | 'semibold' | 'bold'

type FontSize = 10 | 12 | 14 | 16 | 18 | 20 | 22 | 25

type FontSizeProps = {
  'font-size': string
  'line-height': string
}

export type FontProps = {
  color?: string
  size?: FontSize
  opacity?: number
  family?: FontFamily
  lowercase?: boolean
}

const fontSizeMap: Record<FontSize, FontSizeProps> = {
  10: {
    'font-size': '1rem',
    'line-height': '1.4rem',
  },
  12: {
    'font-size': '1.2rem',
    'line-height': '1.6rem',
  },
  14: {
    'font-size': '1.4rem',
    'line-height': '2rem',
  },
  16: {
    'font-size': '1.6rem',
    'line-height': '2.2rem',
  },
  18: {
    'font-size': '1.8rem',
    'line-height': '2.2rem',
  },
  20: {
    'font-size': '2rem',
    'line-height': '2.4rem',
  },
  22: {
    'font-size': '2.2rem',
    'line-height': '2.8rem',
  },
  25: {
    'font-size': '2.5rem',
    'line-height': '3.4rem',
  },
}

const fontFamilyMap: Record<FontFamily, string> = {
  regular: 'Avenir-Medium',
  semibold: 'Avenir-Medium',
  bold: 'Avenir-Medium',
}

export const Font = styled.div<FontProps>`
  color: ${({ theme, color }) => color || theme.colors.black};
  ${({ size }) => fontSizeMap[size || 14]};
  opacity: ${({ opacity }) => opacity};

  ::first-letter {
    text-transform: ${({ lowercase }) => (!lowercase ? 'capitalize' : undefined)};
  }
`

const black = css`
  color: ${({ theme }) => theme.colors.black};
`

const regular = css``

const semibold = css`
  font-weight: 600;
`

const bold = css`
  font-weight: 700;
`

export const semibold12 = css`
  ${semibold};
  ${black};
  font-size: 1.2rem;
  line-height: 1.6rem;
`

export const semibold14 = css`
  ${semibold};
  ${black};
  font-size: 1.4rem;
  line-height: 1.8rem;
`

export const semibold16 = css`
  ${semibold};
  ${black};
  font-size: 1.6rem;
  line-height: 1.8rem;
`

export const semibold22 = css`
  ${semibold};
  ${black};
  font-size: 2.2rem;
  line-height: 2.8rem;
`

export const bold10 = css`
  ${bold};
  ${black};
  font-size: 1rem;
  line-height: 1.3rem;
`

export const bold12 = css`
  ${bold};
  ${black};
  font-size: 1.2rem;
  line-height: 1.6rem;
`

export const bold18 = css`
  ${bold};
  ${black};
  font-size: 1.8rem;
  line-height: 2.2rem;
`

export const bold16 = css`
  ${bold};
  ${black};
  font-size: 1.6rem;
  line-height: 1.8rem;
`

export const bold20 = css`
  ${bold};
  ${black};
  font-size: 2rem;
  line-height: 2.4rem;
`

export const bold22 = css`
  ${bold};
  ${black};
  font-size: 2.2rem;
  line-height: 2.8rem;
`

export const bold14 = css`
  ${bold};
  ${black};
  font-size: 1.4rem;
  line-height: 1.8rem;
`

export const regular16 = css`
  ${regular};
  ${black};
  font-size: 1.6rem;
  line-height: 2.2rem;
`

export const regular14 = css`
  ${regular};
  ${black};
  font-size: 1.4rem;
  line-height: 2rem;
`

export const regular12 = css`
  ${regular};
  ${black};
  font-size: 1.2rem;
  line-height: 1.8rem;
`

export const regular10 = css`
  ${regular};
  ${black};
  font-size: 1rem;
  line-height: 1.3rem;
`
export const Regular14 = styled.div`
  ${regular14}
`

export const Semibold14 = styled.div`
  ${semibold14}
`

export const Semibold16 = styled.div`
  ${semibold16}
`
