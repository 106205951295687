export type Device = 'mobileS' | 'mobileL' | 'tabletS' | 'tabletL' | 'desktopS' | 'desktopL'

export const size: Record<Device, number> = {
  mobileS: 360,
  mobileL: 500,
  tabletS: 750,
  tabletL: 1024,
  desktopS: 1365,
  desktopL: 1599,
}

export const device: Record<Device, string> = {
  mobileS: `(max-width: ${size.mobileS}px)`,
  mobileL: `(max-width: ${size.mobileL}px)`,
  tabletS: `(max-width: ${size.tabletS}px)`,
  tabletL: `(max-width: ${size.tabletL}px)`,
  desktopS: `(max-width: ${size.desktopS}px)`,
  desktopL: `(max-width: ${size.desktopL}px)`,
}

export const breakpoints = {
  sm: '361px',
  md: '501px',
  '2md': '751px',
  lg: '1025px',
  xl: '1366px',
  xxl: '1600px',
}
