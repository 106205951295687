import * as Sentry from '@sentry/browser'

import { AxiosInstance, AxiosResponse } from 'axios'
import { _date } from 'services/date'

const init = (key: string) => {
  Sentry.init({
    dsn: key,
    integrations: [Sentry.browserTracingIntegration()],
    tracesSampleRate: 1.0,
    debug: process.env.NODE_ENV === 'development',
    environment: process.env.NODE_ENV,
  })
}

const logData = (name: string, data: Record<string, any>) => {
  Sentry.setContext(name, data)
}

type LogRecord = {
  status?: number
  timeStamp?: string
  requestUrl?: string
  response?: string
}
const maxDataLength = 2048
const initNetworkTracking = ({
  apiConnectors,
  traceLength = 10,
}: {
  apiConnectors: AxiosInstance[]
  traceLength?: number
}) => {
  const records: LogRecord[] = []
  const middleware = (response: AxiosResponse<any, any>) => {
    let data = ''
    try {
      // data = JSON.stringify(response.data).substring(0, maxDataLength)
    } catch {
      try {
        data = response?.data?.toString()?.substring(0, maxDataLength)
      } catch {
        data = 'Error finding data'
      }
    }
    records.unshift({
      status: response.status,
      timeStamp: _date.from().format('HH:mm:ss'),
      requestUrl: response.config.url,
      response: data,
    })
    if (records.length > traceLength) {
      records.pop()
    }
    records.forEach((record, i) => logData(`Network Response -${i + 1}`, record))
    return response
  }
  apiConnectors.forEach((apiConnector) => apiConnector.interceptors.response.use(middleware))
}

export const _errorReporting = {
  init,
  logData,
  initNetworkTracking,
}
