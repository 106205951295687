import { Text } from '@chakra-ui/react'
import { Locale, useMe } from '@happywait/hw-lib-front.core.api'
import { useCompleteOnboarding } from '@happywait/hw-lib-front.core.api/legacy'
import { EmailTextInput } from 'components/EmailTextInput/EmailTextInput'
import { PhoneNumberInputControlled } from 'design-system/PhoneNumberInput/PhoneNumberInputControlled'

import { useToast } from 'design-system/Toast/useToast'
import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useStore } from 'store'
import { selectLocale } from 'store/selectors'
import { tests } from 'utils/tests'
import { Mail } from '../../../../../assets/svg/Mail'
import { Separator } from '../../../../../components/Separator/Separator'
import { Button } from '../../../../../design-system/Button/Button'
import { useDevice } from '../../../../../hooks/useDevice'
import { useTranslate } from '../../../../../services/i18n'
import { _validator } from '../../../../../services/validator'
import { OnboardingInfosFormValues, onboardingServices, useChangeLangIfNeededAfterLogin } from '../Onboarding.services'

const { infosSchema } = onboardingServices

type OnboardingStep2Props = {
  onSuccess: () => void
}

export const OnboardingStep2 = ({ onSuccess }: OnboardingStep2Props) => {
  const locale = useStore(selectLocale)
  const t = useTranslate()
  const isMobile = useDevice('mobileL')
  const toast = useToast()

  const [oncChecking, setOnChecking] = useState(false)

  const { data: me, refetch } = useMe()
  const methods = useForm<OnboardingInfosFormValues>({
    reValidateMode: 'onChange',
    defaultValues: {
      email: me?.email || '',
      cellphone: me?.cellphone || '',
    },
    resolver: _validator.resolver(infosSchema),
    mode: 'onChange',
  })
  const {
    handleSubmit,
    formState: { errors },
  } = methods
  const updateLang = useChangeLangIfNeededAfterLogin()
  const { mutate, isLoading } = useCompleteOnboarding({
    onSuccess: async () => {
      try {
        await refetch()
        onSuccess()
        updateLang && updateLang()
      } catch {
        toast({ title: t('api.adminError'), variant: 'error' })
      }
    },
  })

  const onSubmit = ({ cellphone, email }: OnboardingInfosFormValues) => {
    mutate({
      cellphone,
      email,
      lang: locale as Locale,
    })
  }

  return (
    <FormProvider {...methods}>
      <Text>
        {isMobile
          ? `${t('login:onboardingScreen.step2.title')}. ${t('login:onboardingScreen.step2.info')}`
          : t('login:onboardingScreen.step2.info')}
      </Text>
      <Separator height={36} />
      <EmailTextInput
        name="email"
        onValidating={setOnChecking}
        iconLeft={<Mail />}
        placeholder={t('login:onboardingScreen.step2.email')}
        data-test={'onboarding-email-DyMMgJ'}
        userId={me?.id}
      />
      <Separator height={15} />
      <PhoneNumberInputControlled
        tErrorContext="login:onboardingScreen.step2"
        name="cellphone"
        onChangeBefore={(nextValue) => (nextValue === undefined ? '' : nextValue)}
        placeholder={t('login:onboardingScreen.step2.cellphone')}
        dataTest={'onboarding-phone-NrGG'}
        isCellphone={true}
        onValidating={setOnChecking}
      />
      <Separator height={40} />
      <Button
        disabled={!!me?.isObserver || Object.entries(errors).length > 0}
        isLoading={isLoading || oncChecking}
        size="large"
        type="submit"
        title={t('continue')}
        onClick={handleSubmit(onSubmit)}
        {...tests.button.submit('onboarding_step2')}
        dataTest={'onboarding-validateButton-wafG'}
      />
    </FormProvider>
  )
}
