import { Locale } from '@happywait/hw-lib-front.core.api'
import { defaultLocale, localeToShortLocale } from 'const'
import { SelectOptions } from 'design-system/Select/Select'
import { _i18n } from 'services/i18n'
import { useStore } from 'store'
import { InitialData } from 'store/types'

const getCurrentLocale = (): Locale => {
  const { locale } = useStore.getState()

  return locale || defaultLocale
}

const getNavigatorLocale = (): string | null => {
  const navigatorLocale = (navigator.languages && navigator.languages[0]) || navigator.language

  return typeof navigatorLocale === 'string' ? navigatorLocale.replace('-', '_') : null
}

const guessAllowedLocaleWithShortLocale = (shortLocale: string, initialData: InitialData): Locale | null => {
  const locale = Object.keys(localeToShortLocale)
    .filter((key) => localeToShortLocale[key] === shortLocale)
    .find((l) => initialData.langsAllowed.includes(l as Locale))

  return locale ? (locale as Locale) : null
}

const getDefaultLocale = (initialData: InitialData): Locale => {
  let locale = initialData.defaultLang
  const navigatorLocale = getNavigatorLocale()

  if (null !== navigatorLocale) {
    if (initialData.langsAllowed.includes(navigatorLocale as Locale)) {
      locale = navigatorLocale as Locale
    } else {
      // Sometimes navigator locale is in two chars format like "en" or "fr"
      const guessedLocale = guessAllowedLocaleWithShortLocale(navigatorLocale, initialData)

      if (null !== guessedLocale) {
        locale = guessedLocale
      }
    }
  }

  return locale
}

const getLocalSelectorOptions = (): SelectOptions[] => {
  const { initialData } = useStore.getState()

  if (null === initialData) {
    return []
  }

  return initialData.langsAllowed.map((langAllowed: Locale): SelectOptions => {
    return {
      id: langAllowed,
      label: _i18n.t(langAllowed, { ns: 'common' }),
    }
  })
}

const isShowableLocalSelector = (): boolean => {
  const { initialData } = useStore.getState()

  return null !== initialData && initialData.langsAllowed?.length > 1
}

export const _locale = {
  getCurrentLocale,
  getDefaultLocale,
  getLocalSelectorOptions,
  isShowableLocalSelector,
}
