import styled from 'styled-components'

const ForgotPassword = styled.div`
  align-self: flex-end;
  margin-top: 16px;
  margin-bottom: 40px;
`

export const LoginStyles = {
  ForgotPassword,
}
