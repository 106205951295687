export const Home = (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="icon/24/home-copy-2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M11.548539,0.148098502 L0.670539043,8.3480985 C0.245192722,8.73557618 0.023804695,9.2053651 -0.0038559889,9.70559097 L-0.005,22.269 C-0.00208999536,23.2231649 0.768835093,23.99409 1.72070992,23.997 L8.245,23.997 C8.65921356,23.997 8.995,23.6612136 8.995,23.247 L8.995,18 C8.995,16.3431458 10.3381458,15.0000001 11.995,15.0000001 C13.6518542,15.0000001 14.995,16.3431458 14.995,18 L14.995,23.25 C14.995,23.6642136 15.3307864,24 15.745,24 L22.266,24 C23.2200286,23.9970968 23.9911214,23.2267916 23.995,22.2750515 L23.995,9.747 C23.9654961,9.20516845 23.7445138,8.73624062 23.3773827,8.39651905 L12.4517257,0.148298169 C12.1844566,-0.053358682 11.8158973,-0.0534401592 11.548539,0.148098502 Z M1.63062659,9.49817963 L11.999,1.685 L22.4162743,9.54570183 C22.4405611,9.57330694 22.4898845,9.67797183 22.4962016,9.78943692 L22.4949965,22.272 L22.486543,22.3320682 C22.4593863,22.4279719 22.3699527,22.4996798 22.263715,22.5000035 L16.4949965,22.5000035 L16.4949965,18 C16.4949965,15.5147187 14.4802814,13.5000001 11.9949965,13.5000001 L11.7831643,13.5048981 C9.39623653,13.615511 7.4949965,15.5857267 7.4949965,18 L7.4949965,22.497 L1.723,22.497 C1.59826346,22.4966156 1.49538437,22.3937365 1.4949965,22.2667099 L1.4949965,9.747 C1.49998526,9.677565 1.54904218,9.57346543 1.63062659,9.49817963 Z"
        id="Path"
        fill="#2E3844"
        fillRule="nonzero"
      ></path>
    </g>
  </svg>
)
