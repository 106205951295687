import { Icon, IconProps } from '@chakra-ui/react'

export const UserIcon = (props: IconProps) => (
  <Icon
    width={props.w ? props.w : '1.5em'}
    height={props.h ? props.h : '1.5em'}
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    color={props.color || '#2E3844'}
  >
    <g id="icon/24/user-copy" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M11.9166667,12.9375 C16.5938281,12.9375 20.3854167,16.7290885 20.3854167,21.40625 C20.3854167,21.8204636 20.0496302,22.15625 19.6354167,22.15625 C19.2212031,22.15625 18.8854167,21.8204636 18.8854167,21.40625 C18.8854167,17.5575156 15.765401,14.4375 11.9166667,14.4375 C8.06793232,14.4375 4.94791667,17.5575156 4.94791667,21.40625 C4.94791667,21.8204636 4.61213023,22.15625 4.19791667,22.15625 C3.7837031,22.15625 3.44791667,21.8204636 3.44791667,21.40625 C3.44791667,16.7290885 7.23950519,12.9375 11.9166667,12.9375 Z M11.9166667,2.84375 C14.6263137,2.84375 16.8229167,5.04035295 16.8229167,7.75 C16.8229167,10.4596471 14.6263137,12.65625 11.9166667,12.65625 C9.20701961,12.65625 7.01041667,10.4596471 7.01041667,7.75 C7.01041667,5.04035295 9.20701961,2.84375 11.9166667,2.84375 Z M11.9166667,4.34375 C10.0354467,4.34375 8.51041667,5.86878007 8.51041667,7.75 C8.51041667,9.63121993 10.0354467,11.15625 11.9166667,11.15625 C13.7978866,11.15625 15.3229167,9.63121993 15.3229167,7.75 C15.3229167,5.86878007 13.7978866,4.34375 11.9166667,4.34375 Z"
        id="Combined-Shape"
        fill={'currentColor'}
        fillRule="nonzero"
      ></path>
    </g>
  </Icon>
)
