import formatDistance from './formatDistance'
import formatLong from './formatLong'
import formatRelative from './formatRelative'
import localize from './localize'
import match from './match'

export const localeFr = {
  code: 'fr-FR',
  formatDistance: formatDistance,
  formatLong: formatLong,
  formatRelative: formatRelative,
  localize: localize,
  match: match,
  options: {
    weekStartsOn: 1 /* Monday */,
    firstWeekContainsDate: 4,
  },
}
