import { useToast as useChakraToast } from '@chakra-ui/react'
import { v4 as uuid } from 'uuid'
import { Toast, ToastProps } from './Toast'

export const useToast = () => {
  const toast = useChakraToast()

  return ({ render, ...props }: ToastProps) => {
    const id = uuid()
    toast({
      id,
      duration: 4000,
      position: 'top-right',
      render: () => <Toast render={render} {...props} id={id} />,
    })
  }
}
