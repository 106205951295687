import Loader from 'react-loader-spinner'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'
import { theme } from '../../theme'

export type SpinnerType =
  | 'Audio'
  | 'BallTriangle'
  | 'Bars'
  | 'Circles'
  | 'Grid'
  | 'Hearts'
  | 'Oval'
  | 'Puff'
  | 'Rings'
  | 'TailSpin'
  | 'ThreeDots'
  | 'Watch'
  | 'RevolvingDot'
  | 'Triangle'
  | 'Plane'
  | 'MutatingDots'
  | 'CradleLoader'

export type SpinnerProps = {
  type?: SpinnerType
  color?: string
  height?: number
  width?: number
}

export const Spinner = ({
  type = 'ThreeDots',
  color = theme.colors.primary,
  height = 80,
  width = 80,
}: SpinnerProps) => {
  return <Loader type={type} color={color} height={height} width={width} />
}
