import { checkboxAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(checkboxAnatomy.keys)

const baseStyle = definePartsStyle({
  control: defineStyle({
    padding: 4,
    borderRadius: 4,
    _checked: {
      background: 'primary.500',
      borderColor: 'transparent',
      _hover: {
        background: 'primary.300',
      },
    },
  }),
  label: defineStyle({ fontSize: '1.5em', width: '100%' }),
})

const green = definePartsStyle({
  control: defineStyle({
    padding: 4,
    borderRadius: 4,
    _checked: {
      background: 'green.500',
      borderColor: 'transparent',
      _hover: {
        background: 'green.300',
      },
    },
  }),
  label: defineStyle({ fontSize: '1.5em', width: '100%' }),
})

export const checkboxThemeVariant = defineMultiStyleConfig({
  variants: { green },
})

export const checkboxTheme = defineMultiStyleConfig({ baseStyle, variants: { green } })
