import { createGlobalStyle } from 'styled-components'
import AvenirMedium from '../assets/fonts/avenir-medium-webfont.woff2'
import { getHexWithAlpha } from './hexaTransparency'
import { device } from './mediaQueries'
import { theme } from './theme'

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Avenir-Medium';
    src: local('Avenir-Medium')
    url(${AvenirMedium} format('woff2'))
  }
  
  html {
    font-size: 62.5%;
    scroll-behavior: smooth;
    @media ${device.mobileL} {
      font-size: 70%;
    }
  }

  body {
    margin: 0;
    overflow: hidden;
    font-family: 'Avenir-Medium', 'Helvetica Neue', 'Arial', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  sup {
    line-height: 0
  }
  
  iframe {
    width: 100%;
    max-width: 100%;
  }
  
  p {
    margin-block-end: 0px;
    margin-block-start: 0px;
  }

  a {
    text-decoration: none;
  }

  * {
    box-sizing: border-box;
  }

  input, input:before, input:after {
    -webkit-user-select: initial;
    -khtml-user-select: initial;
    -moz-user-select: initial;
    -ms-user-select: initial;
    user-select: initial;
  }

  input, textarea {
    outline: none;
    border: none;
    background-color: transparent;
  }

  input:-webkit-autofill { 
    -webkit-background-clip: text;
    background-clip: text;
  }


  button {
    cursor: pointer;
    border: none;
    background-color: transparent;
    :focus {
      outline: none;
    }
    :disabled {
      cursor: default;
    }
  }

  table {
    border-collapse: collapse;
    max-width: 100%;
  }

  ::-webkit-scrollbar {
    width: 12px;
    @media ${device.mobileL} {
      width: 8px;
    }
  }

  ::-webkit-scrollbar-track {
    background: ${getHexWithAlpha(theme.colors.grey, 20)};
    border-radius: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${theme.colors.grey};
    border-radius: 6px;
  }   

  .fadeIn {
    opacity: 0;
    animation: fadeIn .3s forwards;
  }

  .defaultLink {
    color: revert;
    text-decoration: revert;
  }

  @keyframes fadeIn {
    0% { opacity: 0 }
    100% { opacity:1; }
  } 
`
