import { Flex, FlexProps, Text } from '@chakra-ui/react'
import { useFormErrorTranslate } from 'hooks/useFormErrorTranslate'
import { PropsWithChildren } from 'react'
import { useController } from 'react-hook-form'

export type ControlledInputWrapperProps = {
  name: string
  title?: string
  wrapperStyle?: FlexProps
  displayError?: boolean
}

export const ControlledInputWrapper = ({
  children,
  title,
  name,
  wrapperStyle,
  displayError = true,
}: PropsWithChildren<ControlledInputWrapperProps>) => {
  const tError = useFormErrorTranslate()

  const {
    fieldState: { error },
  } = useController({
    name,
  })

  return (
    <Flex flexDir="column" flexGrow={1} {...wrapperStyle}>
      {title && <Text color="gray.400">{title}</Text>}
      {children}
      {error && displayError && <Text color="red.400">{tError(error)}</Text>}
    </Flex>
  )
}
