const urlRegex = /(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+/g

export const getWordsFirstChars = (words: string) =>
  words
    .split(' ')
    .map((word) => word.slice(0, 1))
    .join('')
    .split(' ')
    .map((letter) => letter.slice(0, 2))
    .join('')

export const getHTMLLinks = (str: string) => {
  if (typeof str === 'number') {
    return str
  } else if (typeof str !== 'string') {
    return ''
  }

  const linkStr = str.replace(urlRegex, (substr) => {
    const href = substr.startsWith('http') ? substr : `http://${substr}`
    return `<a class="defaultLink" href=${href} target="_blank" rel=”noopener noreferrer”>${substr}</a>`
  })

  return linkStr
}

export const addExponent = (number: number | string) => `<sup>${number}</sup>`

export const createStringWithExp = (string: string, exp: number | string) => `${string}${addExponent(exp)}`

export const squareMetre = createStringWithExp('m', 2)
