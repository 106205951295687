export const Evaluation3 = () => {
  return (
    <svg width={76} height={76} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M38.448.997C17.778.997.962 17.812.962 38.483c0 20.67 16.816 37.486 37.486 37.486 20.67 0 37.487-16.815 37.487-37.486 0-20.67-16.816-37.486-37.487-37.486zM24.391 22.864a4.691 4.691 0 014.686 4.686 4.691 4.691 0 01-4.686 4.685 4.691 4.691 0 01-4.686-4.685 4.692 4.692 0 014.686-4.686zm31.994 37.24a1.555 1.555 0 01-.843.246 1.554 1.554 0 01-1.315-.718c-3.477-5.417-9.375-8.653-15.779-8.653-6.407 0-12.302 3.236-15.775 8.653a1.567 1.567 0 01-2.159.471 1.564 1.564 0 01-.471-2.158c4.048-6.317 10.93-10.09 18.405-10.09 7.476 0 14.358 3.773 18.41 10.09a1.564 1.564 0 01-.473 2.158zm-3.88-27.869a4.691 4.691 0 01-4.685-4.685 4.691 4.691 0 014.686-4.686 4.691 4.691 0 014.685 4.686 4.691 4.691 0 01-4.685 4.685z"
        fill="#F75234"
        fillRule="nonzero"
        opacity={0.8}
      />
    </svg>
  )
}
