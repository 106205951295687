import { isEqual } from '@react-spring/shared'
import { omit, uniq } from 'ramda'
import { ModalsStored, Store } from 'store/types'
import { SetState } from 'zustand'

export const modalSlice = (set: SetState<Store>) => ({
  modals: {},
  modalIdsHistory: [],
  showModal: <ModalId extends keyof ModalsStored, Props extends ModalsStored[ModalId]>({
    modalId,
    props,
  }: {
    props?: Props
    modalId: ModalId
  }) => {
    return set((prev) => ({
      modals: { ...prev.modals, [modalId]: props || null },
    }))
  },
  hideModal: (modalId: keyof ModalsStored) =>
    set((prev) => ({
      modals: omit([modalId], prev.modals),
      modalIdsHistory: isBannedModalId(modalId) ? [...prev.modalIdsHistory] : uniq([...prev.modalIdsHistory, modalId]),
    })),
  resetModals: () => set({ modals: {} }),
  setModalHistory: (modalIdsHistory: (keyof ModalsStored)[]) => set(() => ({ modalIdsHistory: modalIdsHistory })),
})

const isBannedModalId = (modalId: keyof ModalsStored) => {
  return isEqual(modalId, 'unitTypologySurvey') || isEqual(modalId, 'meantimeContactSurvey')
}
