import { useMe } from '@happywait/hw-lib-front.core.api'
import { PostCompleteOnboardingParams, useUpdateUser } from '@happywait/hw-lib-front.core.api/legacy'
import { useQueryClient } from '@tanstack/react-query'
import Joi from 'joi'
import { useStore } from 'store'
import { selectLocale } from 'store/selectors'
import { queriesInvalidation } from 'utils/queriesInvalidation'
import { _validator } from '../../../../services/validator'

// Fetch me
// if me.lang != store lang
// Patch user with the store lang
// Invalidate me
export const useChangeLangIfNeededAfterLogin = () => {
  const { data: me } = useMe()
  const locale = useStore(selectLocale)
  const queryClient = useQueryClient()
  const { mutate } = useUpdateUser({
    onSuccess: () => {
      queriesInvalidation(queryClient, ['me'])
    },
  })
  if (locale && me?.lang !== locale && !!me?.id) {
    return () => mutate({ id: me.id, user: { lang: locale } })
  }
  return null
}

export type OnboardingPasswordFormValues = {
  email: string
  password: string
  passwordConfirmation: string
}

export type OnboardingInfosFormValues = PostCompleteOnboardingParams

const getPasswordSchema = (strongPassword: boolean) =>
  Joi.object<OnboardingPasswordFormValues>({
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required(),
    password: strongPassword ? _validator.strongPassword.required() : Joi.string().required(),
    passwordConfirmation: Joi.string().equal(Joi.ref('password')).required(),
  })

const infosSchema = Joi.object<OnboardingInfosFormValues>({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .messages({
      'any.required': 'emailRequired',
      'string.empty': 'emailRequired',
      'string.email': 'emailFormat',
    }),
  cellphone: Joi.string().required().custom(_validator.joiPhoneNumberValidation).messages({
    'any.required': 'cellphoneRequired',
    'string.empty': 'cellphoneRequired',
  }),
})

export const onboardingServices = {
  getPasswordSchema,
  infosSchema,
}
