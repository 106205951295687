import { Me } from '@happywait/hw-lib-front.core.api'
import { useCallback } from 'react'
import { createSearchParams, useLocation } from 'react-router-dom'
import { isNil } from 'remeda'
import { routeNames } from '../modules'
import { useStore } from '../store'
import { selectConversationRankId } from '../store/selectors'
import { useLogout } from './useLogout'
import useNavigateParams from './useNavigateParams'
import useQueryParams from './useQueryParams'

export const useLoginRedirection = () => {
  const navigate = useNavigateParams()
  const location = useLocation()

  const conversationRankId = useStore(selectConversationRankId)
  const { queryObjectParams } = useQueryParams()

  const getRouteWithQueryParam = useCallback(
    (routeName: string) => {
      if (!location.pathname.includes('/onboarding') && !location.pathname.includes('/home')) {
        return {
          pathname: routeName,
          search: createSearchParams({ ...queryObjectParams, redirection: location.pathname }).toString(),
        }
      }
      return {
        pathname: routeName,
        search: createSearchParams({ ...queryObjectParams }).toString(),
      }
    },
    [location, queryObjectParams]
  )

  const redirectTo = (routeName: string) => {
    const url = getRouteWithQueryParam(routeName)
    navigate(url)
  }

  return (
    { isObserver, temporary }: Pick<Me, 'isObserver' | 'temporary'>,
    isValidatedCGU?: boolean,
    onboardingCompleted?: boolean
  ) => {
    if (!isValidatedCGU && !isObserver) {
      redirectTo(routeNames.cgu)
    } else if ((temporary && !isObserver) || (!isNil(onboardingCompleted) && !onboardingCompleted)) {
      redirectTo(routeNames.onboarding)
    } else if (!!conversationRankId && !isObserver) {
      redirectTo('/home')
    }
  }
}

export const useFirstTokenLoginRedirection = () => {
  const navigate = useNavigateParams()
  const { logoutCustomer } = useLogout()

  return ({ isObserver, onlyInvitation }: Pick<Me, 'isObserver' | 'onlyInvitation'>) => {
    if (onlyInvitation) {
      logoutCustomer()
      return
    }
    if (isObserver) {
      navigate('/home')
      return
    }
  }
}
