import { Box, Flex } from '@chakra-ui/react'
import { Button } from 'design-system/Button/Button'
import { Divider } from 'design-system/Divider/Divider'

type InvitationFooterProps = {
  isDisabled?: boolean
  isLoading?: boolean
  title: string
  onSubmit: () => void
}

export const InvitationFooter = ({ onSubmit, isDisabled, title, isLoading }: InvitationFooterProps) => {
  return (
    <>
      <Box display={{ sm: 'none', md: 'none', lg: 'block' }}>
        <Divider />
      </Box>
      <Flex id="flex" width={'100%'} justify={{ sm: 'center', md: 'center', lg: 'end' }} padding={'3rem 10%'}>
        <Button
          variant="primary"
          title={title}
          type="submit"
          disabled={isDisabled}
          onClick={onSubmit}
          isLoading={isLoading}
        />
      </Flex>
    </>
  )
}
