export interface BuildFormatLongFnArgs {
  formats: { [format in any]: string }
  defaultWidth: any
}

export default function buildFormatLongFn(args: BuildFormatLongFnArgs): any {
  return (options = {} as any) => {
    // TODO: Remove String()
    const width = options.width ? (String(options.width) as any) : args.defaultWidth
    const format = args.formats[width] || args.formats[args.defaultWidth]
    return format
  }
}
