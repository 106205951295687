import { ApiErrorV3 } from '@happywait/hw-lib-front.core.api'
import { useLoginCheck } from '@happywait/hw-lib-front.core.api/legacy'
import { useQueryClient } from '@tanstack/react-query'
import { useApiErrorTranslate } from 'hooks/useApiErrorTranslate'
import useNavigateParams from 'hooks/useNavigateParams'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { tests } from 'utils/tests'
import { EyeHidden } from '../../../../assets/svg/EyeHidden'
import { EyeOpen } from '../../../../assets/svg/EyeOpen'
import { Lock } from '../../../../assets/svg/Lock'
import { UserIcon } from '../../../../assets/svg/UserIcon'
import { Separator } from '../../../../components/Separator/Separator'
import { Button } from '../../../../design-system/Button/Button'
import { Font } from '../../../../design-system/Font/Font'
import { Link } from '../../../../design-system/Link/Link'
import { TextInput } from '../../../../design-system/TextInput/TextInput'
import { useTrackScreen } from '../../../../hooks/useTrackScreen'
import { useTranslate } from '../../../../services/i18n'
import { _validator } from '../../../../services/validator'
import { useStore } from '../../../../store'
import { selectReset, selectSetToken } from '../../../../store/selectors'
import { FormError } from '../../components/FormError/FormError'
import { LoginModuleForm } from '../../components/LoginModuleForm/LoginModuleForm'
import { LoginFormValues, loginServices as services } from './Login.services'
import { LoginStyles as Styles } from './Login.styles'

const { schema } = services

type LoginProps = {
  redirect?: string
}

export const Login = ({ redirect }: LoginProps) => {
  const navigate = useNavigateParams()
  const setToken = useStore(selectSetToken)
  const reset = useStore(selectReset)

  const t = useTranslate()
  const queryClient = useQueryClient()
  const tError = useApiErrorTranslate('login')
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)
  const [error, setError] = useState<string>()
  const { control, handleSubmit, clearErrors } = useForm<LoginFormValues>({
    reValidateMode: 'onSubmit',
    defaultValues: {
      username: '',
      password: '',
    },
    resolver: _validator.resolver(schema),
  })
  useTrackScreen('Login')

  const { mutate, isLoading: isLoadingLoginCheck } = useLoginCheck({
    onSuccess: ({ data }) => {
      reset()
      const { token } = data
      setToken(token)
      queryClient.resetQueries(['check-cgu'])
      if (redirect) {
        navigate(redirect)
      }
    },
    onError: (response: ApiErrorV3) => {
      setError(tError(response)?.messages?.global)
    },
  })

  const goToForgotPasswordScreen = () => navigate('/login/forgot-password')

  const onSubmit = ({ username, password }: LoginFormValues) => {
    mutate({ _username: username, _password: password })
  }

  return (
    <LoginModuleForm>
      <Font family="bold" size={22}>
        {t('login:loginScreen.title')}
      </Font>
      <Separator height={40} />
      {error && (
        <>
          <FormError title={error} type="api" name="login" />
          <Separator height={20} />
        </>
      )}
      <Controller
        name="username"
        control={control}
        render={({ field: { onChange, value, name }, fieldState: { error } }) => (
          <TextInput
            iconLeft={<UserIcon />}
            error={error?.type ? t(`login:loginScreen.errors.email.${error?.type}`, { defaultValue: '' }) : ''}
            value={value}
            onChange={onChange}
            autoComplete="email"
            onFocus={() => clearErrors(name)}
            placeholder={t('login:yourId')}
            placeholderMode="animated"
            name={name}
          />
        )}
      />
      <Separator height={15} />
      <Controller
        name="password"
        control={control}
        render={({ field: { value, onChange, name }, fieldState: { error } }) => (
          <TextInput
            iconLeft={Lock}
            iconRight={value ? (isPasswordVisible ? EyeHidden : EyeOpen) : undefined}
            onClickIcon={() => setIsPasswordVisible((prev) => !prev)}
            type={isPasswordVisible ? 'text' : 'password'}
            value={value}
            autoComplete="current-password"
            error={error?.type ? t(`login:loginScreen.errors.password.${error?.type}`, { defaultValue: '' }) : ''}
            onChange={onChange}
            onFocus={() => clearErrors(name)}
            placeholder={t('login:yourPassword')}
            placeholderMode="animated"
            name={name}
          />
        )}
      />
      <Styles.ForgotPassword>
        <Link
          onClick={goToForgotPasswordScreen}
          title={t('login:passwordForgotten')}
          opacity={0.6}
          size={12}
          {...tests.button.goto('forgotpassword')}
        />
      </Styles.ForgotPassword>
      <Separator height={40} />
      <Button
        type="submit"
        size="large"
        title={t('login:loginScreen.submit')}
        onClick={handleSubmit(onSubmit)}
        isLoading={isLoadingLoginCheck}
        {...tests.button.submit('login')}
      />
    </LoginModuleForm>
  )
}
