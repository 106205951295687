import { ChakraProvider } from '@chakra-ui/react'
import Div100vh from 'react-div-100vh'
import { ThemeProvider } from 'styled-components'
import { chakraTheme, GlobalStyle, theme } from '../../theme'
import { styleProviderServices as services } from './StyleProvider.services'

export const StyleProvider = ({ children }) => (
  <ChakraProvider theme={chakraTheme} portalZIndex={40}>
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Div100vh style={services.containerStyle}>{children}</Div100vh>
    </ThemeProvider>
  </ChakraProvider>
)
