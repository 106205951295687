import { useSpring } from 'react-spring'
import { useTheme } from 'styled-components'
import { theme } from '../../theme'
import { Font } from '../Font/Font'
import { ProgressBarStyles as Styles } from './ProgressBar.styles'

export type ProgressBarProps = {
  height?: number
  barcolor?: string
  backgroundColor?: string
  percent: number
  percentIndicator?: boolean
  decimals?: number
}

const getProgressBarLineWidth = (percent: number) => {
  if (percent <= 0) {
    return 0
  }
  if (percent > 100) {
    return 100
  }
  return percent
}

export const ProgressBar = ({
  height = 2,
  barcolor = theme.colors.success,
  backgroundColor = theme.colors.grey20,
  percent,
  percentIndicator,
  decimals = 2,
}: ProgressBarProps) => {
  const theme = useTheme()

  const lineStyleProps = useSpring({
    width: `${getProgressBarLineWidth(percent)}%`,
  })

  return (
    <Styles.Container height={height / 10} backgroundColor={backgroundColor}>
      <Styles.Line style={lineStyleProps} barcolor={barcolor}>
        {percentIndicator && (
          <Font family="semibold" color={theme.colors.white}>
            {percent.toFixed(decimals)} %
          </Font>
        )}
      </Styles.Line>
    </Styles.Container>
  )
}
