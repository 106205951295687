import { extendTheme } from '@chakra-ui/react'
import { CustomLinkTheme as CustomLink } from 'components/InvitationForm/components/Link/CustomLink.styles'
import { black, colors, error, primary } from './colors'
import { checkboxTheme } from './extendedComponentTheme/checkbox'
import { getHexWithAlpha } from './hexaTransparency'
import { breakpoints } from './mediaQueries'
import { InputTheme } from './extendedComponentTheme/Input'

export const theme = {
  colors: colors,
}

const components = {
  Text: {
    baseStyle: {
      fontFamily: 'regular',
      fontSize: '14px',
      color: 'gray.700',
    },
  },
  Button: {
    baseStyle: {
      height: '50px',
      fontSize: '14px',
    },
    variants: {
      solid: {
        backgroundColor: primary,
        _hover: { bg: colors.primary60 },
        _active: { bg: colors.primary20 },
        _focus: { boxShadow: 'none' },
        _disabled: { bg: 'gray.100', color: 'gray.500' },
        color: 'white',
      },
      terciary: {
        color: colors.primary,
        backgroundColor: colors.primary20,
      },
    },
    defaultProps: {
      variant: 'solid',
    },
  },
  Tooltip: {
    baseStyle: {
      fontSize: '14px',
      bg: 'white',
      color: 'gray.600',
      fontFamily: 'regular',
    },
    variants: {
      error: {
        fontSize: '14px',
        bg: error,
        color: 'white',
        fontFamily: 'regular',
      },
    },
  },
  Input: InputTheme,
  CustomLink,
  Checkbox: checkboxTheme,
}

export const chakraTheme = extendTheme({
  breakpoints,
  colors: {
    primary: {
      100: getHexWithAlpha(primary, 10),
      200: getHexWithAlpha(primary, 20),
      300: getHexWithAlpha(primary, 60),
      500: primary,
      600: getHexWithAlpha(primary, 80),
      800: getHexWithAlpha(primary, 95),
    },
    gray: {
      100: '#F3F5F7',
      200: '#E2E6EA',
      300: '#D3D8DE',
      400: getHexWithAlpha(black, 60),
      500: getHexWithAlpha(black, 40),
      600: black,
    },
    orange: {
      50: '#F7523433',
      100: '#F3961E',
      200: '#F75234',
    },
    blue: {
      500: '#102E56',
    },
  },
  fonts: {
    heading: `'Avenir-Medium', sans-serif`,
    body: `'Avenir-Medium', sans-serif`,
    regular: `'Avenir-Medium', sans-serif`,
  },
  components: components,
})

export type DefaultTheme = typeof theme
