import { useModules } from '@happywait/hw-lib-front.core.api'
import { Layout } from 'components/Layout/Layout'
import { Loader } from 'components/Loader/Loader'
import { WelcomeModalWrapper } from 'components/WelcomeModalWrapper/WelcomeModalWrapper'
import { useCurrentAcquisition } from 'hooks/useCurrentAcquisition'
import { getAccessibleModulesCodes } from 'modules/tools'
import { ReactElement, Suspense, useCallback, useEffect } from 'react'
import { Navigate, Outlet, useSearchParams } from 'react-router-dom'
import { useStore } from 'store'
import { selectSetModules } from 'store/selectors'

// Check if the user has access to the module via the useModules hook
export const ModuleAccess = ({ children }: { children?: ReactElement }) => {
  const { unit } = useCurrentAcquisition()
  const setModules = useStore(selectSetModules)
  const [searchParams] = useSearchParams()
  const redirection = searchParams.get('redirection')

  const { data: modulesAccessRights = [] } = useModules({
    params: { batch_id: unit?.id },
    queryParams: { enabled: !!unit },
  })

  const resetModules = useCallback(() => {
    const nextModules = getAccessibleModulesCodes(modulesAccessRights)
    setModules(nextModules)
  }, [setModules, modulesAccessRights])

  useEffect(() => {
    resetModules()
  }, [resetModules])

  if (redirection) {
    return <Navigate to={redirection} />
  }

  if (!children) {
    return (
      <Layout>
        <WelcomeModalWrapper />
        <Suspense fallback={<Loader />}>
          <Outlet />
        </Suspense>
      </Layout>
    )
  }
  return children
}
