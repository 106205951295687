import { Box } from '@chakra-ui/react'
import { MeExtended, useMe, useMeExtended } from '@happywait/hw-lib-front.core.api'
import { useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useStore } from 'store'
import { selectToken } from 'store/selectors'
import { queriesInvalidation } from 'utils/queriesInvalidation'
import { StepIndex } from '../../../../../design-system/StepIndex/StepIndex'
import { useDevice } from '../../../../../hooks/useDevice'
import { useTranslate } from '../../../../../services/i18n'
import { OnboardingStep1 } from '../OnboardingSteps/OnboardingStep1'
import { OnboardingStep2 } from '../OnboardingSteps/OnboardingStep2'
import { OnboardingWrapper } from '../OnboardingWrapper/OnboardingWrapper'

const getTotalSteps = ({ onboardingCompleted }: MeExtended<'consumer'>) => {
  if (onboardingCompleted) {
    return 1
  } else {
    return 2
  }
}

export const OnboardingFull = () => {
  const { data: me } = useMe()
  const token = useStore(selectToken)
  const { data: meExtended } = useMeExtended({
    queryParams: {
      enabled: !!token,
    },
  })

  const navigate = useNavigate()
  const queryClient = useQueryClient()
  // if current user is temporary, he must provide a password (step 1)
  // if he is not, he just needs to provide email and cellphone (step 2)
  const [step, setStep] = useState(me?.temporary ? 1 : 2)
  const totalSteps = meExtended ? getTotalSteps(meExtended) : 2

  const t = useTranslate()

  const isMobile = useDevice('mobileL')
  const [searchParams] = useSearchParams()
  const redirection = searchParams.get('redirection')

  const onStepComplete = () => {
    if (step + 1 > totalSteps) {
      queriesInvalidation(queryClient, ['me-extended', 'me'])
      navigate(redirection ?? '/home')
    } else {
      setStep((prev) => prev + 1)
    }
  }

  const renderStepIndex = () => (
    <Box ml="auto">
      <StepIndex index={step} total={totalSteps} dataTest={'onboarding-stepIndex-Pe17oE'} />
    </Box>
  )

  const renderContent = () =>
    step === 1 ? <OnboardingStep1 onSuccess={onStepComplete} /> : <OnboardingStep2 onSuccess={onStepComplete} />

  const renderTitle = () => {
    if (isMobile) {
      return `${t('welcome')} !`
    }
    if (step === 1) {
      return t('login:onboardingScreen.step1.title')
    }
    return t('login:onboardingScreen.step2.title')
  }

  return <OnboardingWrapper renderTitle={renderTitle} renderStepIndex={renderStepIndex} renderContent={renderContent} />
}
