import { logoutCustomer } from '@happywait/hw-lib-front.core.api'
import { queryClient } from 'App'
import { routeNavigate } from 'modules'
import { cleanAllCookies } from 'tools/cleanAllCookies'
import create, { GetState, SetState, StoreApi } from 'zustand'
import { persist } from 'zustand/middleware'
import { acquisitionSlice } from './slices/acquisition'
import { conversationSlice } from './slices/conversation'
import { documentSlice } from './slices/document'
import { generalSlice } from './slices/general'
import { homeSlice } from './slices/home'
import { loginSlice } from './slices/login'
import { messageSlice } from './slices/message'
import { modalSlice } from './slices/modal'
import { ShowModal, ShowModalSlice } from './slices/showModal'
import { Store } from './types'
// When a major change is made to the state that renders it INCOMPATIBLE with a previous version (thus causing errors)
// increase this version to remove the older version from the user's cache.
const version = 2

const getInitialStore = (set: SetState<Store>): Store => ({
  ...generalSlice(set),
  ...acquisitionSlice(set),
  ...documentSlice(set),
  ...messageSlice(set),
  ...loginSlice(set),
  ...conversationSlice(set),
  ...homeSlice(set),
  ...modalSlice(set),
  logout: async (redirectToLogin = true) => {
    await logoutCustomer().finally(() => {
      set({
        defaultCurrencyCode: 'EUR',
        projectId: undefined,
        unitId: undefined,
        markedDocumentIds: [],
        messages: [],
        conversations: [],
        token: '',
        modules: [],
        modals: {},
        modalIdsHistory: [],
        readDocumentIds: [],
        conversationRankId: undefined,
      })
      if (!window.location.hash.includes('login') && redirectToLogin) {
        window.location.href = `${window.location.origin}/#${routeNavigate.login}`
      }
    })
    queryClient.clear()
    cleanAllCookies()
  },
  reset: () =>
    set({
      initialData: null,
      defaultCurrencyCode: 'EUR',
      projectId: undefined,
      unitId: undefined,
      markedDocumentIds: [],
      messages: [],
      conversations: [],
      readDocumentIds: [],
      token: '',
      modules: [],
      modals: {},
      modalIdsHistory: [],
      conversationRankId: undefined,
      locale: null,
    }),
})

export const useStore = create<Store>((...args) => {
  const storeVersion = Number(localStorage.getItem('version'))
  if (storeVersion !== version) {
    localStorage.removeItem('store')
  }
  localStorage.setItem('version', version.toString())
  return persist<Store, SetState<Store>, GetState<Store>, StoreApi<Store>>((set, _) => getInitialStore(set), {
    name: 'store',
    partialize: (state) => ({
      markedDocumentIds: state.markedDocumentIds,
      token: state.token,
      loginPicture: state.loginPicture,
      unitId: state.unitId,
      projectId: state.projectId,
      readDocumentIds: state.readDocumentIds,
      firstConnection: state.firstConnection,
      conversationRankId: state.conversationRankId,
      modalIdsHistory: state.modalIdsHistory,
      locale: state.locale,
    }),
  })(...args)
})

export const useModalStore = create<ShowModal>((...args) => {
  const storeVersion = Number(localStorage.getItem('version'))
  if (storeVersion !== version) {
    localStorage.removeItem('store')
  }
  localStorage.setItem('version', version.toString())
  return persist<ShowModal, SetState<ShowModal>, GetState<ShowModal>, StoreApi<ShowModal>>(
    (set, _) => ShowModalSlice(set),
    {
      name: 'storeModal',
    }
  )(...args)
})
