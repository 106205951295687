import { colors } from '../colors'

export const InputTheme = {
  baseStyle: {
    field: {
      textOverflow: 'ellipsis !important',
      overflow: 'hidden !important',
      whiteSpace: 'nowrap !important',
      paddingLeft: '12px',
      paddingRight: '12px',
      backgroundColor: 'gray.100',
    },
  },
  variants: {
    filled: {
      field: {
        border: '1px solid',
        height: '42px',
        fontSize: '1.4rem',
        _focus: {
          backgroundColor: 'white',
          boxShadow: '0px 0px 0px 3px var(--chakra-colors-primary-200)',
          borderColor: 'primary.300',
        },
        _active: {
          backgroundColor: 'white',
          boxShadow: '0px 0px 0px 3px var(--chakra-colors-primary-200)',
          borderColor: 'primary.300',
        },
      },
    },
    error: {
      field: {
        border: '1px solid',
        height: '42px',
        fontSize: '1.4rem',
        borderColor: colors.error,
        boxShadow: `0px 0px 0px 3px ${colors.error20}`,
        _focus: {
          backgroundColor: 'white',
          boxShadow: `0px 0px 0px 3px ${colors.error20}`,
          borderColor: colors.error,
        },
        _active: {
          backgroundColor: 'white',
          boxShadow: `0px 0px 0px 3px ${colors.error20}`,
          borderColor: colors.error,
        },
      },
    },
  },
  defaultProps: {
    variant: 'filled',
  },
}
