import { AxiosResponse } from 'axios'
import { Toast } from 'design-system/Toast/Toast'
import { toast } from 'react-toastify'
import { useTranslate } from 'services/i18n'

export const useApiNetworkError = () => {
  const t = useTranslate()

  const toastApiError = (response: AxiosResponse<any, any> | undefined) => {
    if (response?.status === 500) return
    toast(<Toast title={t('api.globalError')} variant="error" />)
  }

  return {
    toastApiError,
  }
}
