import { Tooltip } from '@chakra-ui/react'
import { useCheckEmailUnicity } from '@happywait/hw-lib-front.core.api/legacy'
import { TextInput, TextInputProps } from 'design-system/TextInput/TextInput'
import { useDebounce } from 'hooks/useDebounce'
import { useErrorTranslate } from 'hooks/useErrorTranslate'
import { useEffect } from 'react'
import { FieldError, useController, useFormContext } from 'react-hook-form'
import { useTranslate } from 'services/i18n'
import { _validator } from 'services/validator'
import { useIsOnValidating } from './EmailTextInput.service'

export type EmailTextInputProps = {
  name: string
  userId?: number
  onValidating?: (_: boolean) => void
} & TextInputProps

export const EmailTextInput = ({ name = 'email', userId, onValidating, ...props }: EmailTextInputProps) => {
  const t = useTranslate()
  const tError = useErrorTranslate()
  const { mutate, isLoading, data } = useCheckEmailUnicity()
  const {
    field: { onChange, value },
    fieldState: { isDirty, error },
  } = useController({ name })
  const { setError, clearErrors, trigger } = useFormContext()

  // only update the debounced value if the field is not dirty and the email is valid
  const debouncedEmail = useDebounce(
    isDirty && !_validator.joiEmailValidation.validate(value).error ? value : null,
    1000
  )
  const isOnValidating = useIsOnValidating(isDirty, isLoading, debouncedEmail, value)

  // if debounced value is updated, check it's validity
  useEffect(() => {
    debouncedEmail && mutate({ email: debouncedEmail, role: 'ROLE_CONSUMER', userId: userId })
  }, [debouncedEmail, mutate, userId])

  // id doubounced value corresponds to current input value, check validity
  const isDuplicate = !isLoading && debouncedEmail === value && !!data?.data && !data.data.unique

  // Set or cleqr duplicate errors when value changes
  useEffect(() => {
    if (isDuplicate) {
      setError(name, { type: 'email.duplicate' })
    } else {
      clearErrors(name)
      // trigger revalidation
      trigger(name)
    }
  }, [isDuplicate, name, setError, clearErrors, trigger])

  // tell parent if validation running
  useEffect(() => onValidating?.(isOnValidating), [isOnValidating, onValidating])

  const getErrorTooltip = (): FieldError | undefined => {
    switch (error?.type) {
      case 'email.duplicate': {
        return { type: 'tooltip.email.duplicate' }
      }
      default: {
        return undefined
      }
    }
  }

  return (
    <Tooltip label={t('errors.form.tooltip.email.onValidating')} hidden={!isOnValidating}>
      <TextInput
        onChange={onChange}
        value={value}
        isLoading={isLoading}
        error={tError(error)}
        errorTooltip={tError(getErrorTooltip())}
        name={name}
        {...props}
      />
    </Tooltip>
  )
}
