import styled from 'styled-components'
import { device } from '../../theme'
import { getHexWithAlpha } from '../../theme/hexaTransparency'
import { createShadow } from '../../theme/style.services'

type ChoiceStyleProps = {
  selected?: boolean
  disabled?: boolean
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: 40px 100px;
  width: 800px;
  position: relative;
  border-radius: 4px;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.white};
  > :first-child {
    display: flex;
    justify-content: space-between;
  }
  @media ${device.tabletL} {
    justify-content: flex-start;
    padding: 20px;
    height: 100%;
    width: 100%;
    > :first-child {
      margin-right: 50px;
    }
  }
`

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  @media ${device.tabletL} {
    margin-top: 20px;
  }
`

const Choice = styled.div<ChoiceStyleProps>`
  min-height: 180px;
  width: 180px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-evenly;
  align-items: center;
  cursor: ${(disabled) => (!disabled ? 'pointer' : 'cursor')}
  border: ${({ theme, selected }) =>
    selected ? `1px solid ${theme.colors.primary}` : `1px solid ${theme.colors.grey20}`};
  ${({ selected, theme }) =>
    selected
      ? createShadow({ x: 0, y: 0, blur: 0, spread: 3, color: theme.colors.primary20 })
      : createShadow({ x: 0, y: 6, blur: 8, spread: 0, color: getHexWithAlpha('#000000', 2) })}
  @media ${device.tabletL} {
    width: 100%;
    min-height: 90px;
    flex-direction: row;
    justify-content: flex-start;
    > :first-child {
      margin-right: 20px;
    }
  }
`

const ChoicesContainer = styled.div`
  display: flex;
  justify-content: center;
  > * + * {
    margin-left: 24px;
  }
  @media ${device.tabletL} {
    flex-direction: column;
    > * + * {
      margin-left: 0;
      margin-top: 12px;
    }
  }
`

const Icon = styled.div`
  @media ${device.tabletL} {
    transform: scale(0.75);
  }
`

const TabletTitle = styled.div`
  margin-top: 40px;
  text-align: center;
`

export const UnitTypologySurveyStyles = {
  Container,
  Icon,
  Choice,
  TabletTitle,
  ChoicesContainer,
  Actions,
}
