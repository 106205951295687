import { tests } from 'utils/tests'
import { FormErrorStyles as Styles } from './FormError.styles'

export type FormErrorProps = {
  title: string
  type?: 'form' | 'api'
  name?: string
}

export const FormError = ({ title, type = 'form', name }: FormErrorProps) => {
  if (!title) {
    return null
  }

  return <Styles.Container {...(name ? tests.error(type, name) : undefined)}>{title}</Styles.Container>
}
