import {
  CSPSituation,
  CustomerApi,
  FamilySituation,
  HousingStatus,
  PatchUpdateCustomerParams,
} from '@happywait/hw-lib-front.core.api'
import { InvitationForm } from 'components/InvitationForm/InvitationForm.service'
import Joi from 'joi'
import { keys, pick, toPairs } from 'remeda'
import { _date } from 'services/date'
import { useTranslate } from 'services/i18n'

const tPrefix = 'forms.SituationInformation'

export type SituationInformationFormValues = Pick<
  PatchUpdateCustomerParams,
  | 'occupationalCategoryOther'
  | 'taxableRevenue'
  | 'taxReferenceRevenue'
  | 'monthlyNetTaxableRevenue'
  | 'currentHousingStatus'
  | 'rentAmount'
  | 'contractWeddingDate'
  | 'weddingDate'
  | 'weddingCity'
  | 'weddingContract'
  | 'weddingNotary'
  | 'weddingNotaryCity'
  | 'pacsDate'
  | 'pacsLocation'
> &
  Partial<{
    occupationalCategory: CSPSituation | ''
    familySituation: FamilySituation | ''
  }>

export const useCurrentHousingStatusOptions = () => {
  const t = useTranslate()
  return toPairs(HousingStatus).map(([key, value]) => ({
    id: value,
    label: t(`housingStatus.${key.toLowerCase()}`),
  }))
}

export const useFamilySituationOptions = () => {
  const t = useTranslate()
  return toPairs(FamilySituation).map(([key, value]) => ({
    id: value,
    label: t(`familySituation.${key.toLowerCase()}`),
  }))
}

export const useFinancialSituationOptions = () => {
  const t = useTranslate()
  return toPairs(CSPSituation).map(([key, value]) => {
    return {
      id: value,
      label: t(`financialSituation.${key.toLowerCase()}`),
    }
  })
}

const schema = Joi.object<SituationInformationFormValues>({
  occupationalCategory: Joi.string().allow(null),
  occupationalCategoryOther: Joi.string().allow('', null),
  taxableRevenue: Joi.number().allow(null),
  taxReferenceRevenue: Joi.number().allow(null),
  monthlyNetTaxableRevenue: Joi.number().allow(null),
  currentHousingStatus: Joi.string().allow(null),
  rentAmount: Joi.number().allow(null),
  contractWeddingDate: Joi.string().allow(null, ''),
  weddingDate: Joi.string().allow(null, ''),
  weddingCity: Joi.string().allow('', null),
  weddingContract: Joi.string().allow('', null),
  weddingNotary: Joi.string().allow('', null),
  weddingNotaryCity: Joi.string().allow('', null),
  familySituation: Joi.string().allow(null),
  pacsDate: Joi.string().allow(null, ''),
  pacsLocation: Joi.string().allow('', null),
})

const getDefaultValues = ({
  customer,
  formData,
}: {
  customer?: CustomerApi
  formData?: InvitationForm
}): SituationInformationFormValues => {
  const defaultCustomerValues = {
    familySituation: customer?.familySituation || undefined,
    contractWeddingDate: customer?.contractWeddingDate || '',
    weddingDate: customer?.weddingDate ? _date.format(_date.formatters.input)(customer?.weddingDate) : '',
    weddingCity: customer?.weddingCity || '',
    occupationalCategoryOther: customer?.occupationalCategoryOther || '',
    taxableRevenue: customer?.taxableRevenue || undefined,
    taxReferenceRevenue: customer?.taxReferenceRevenue || undefined,
    monthlyNetTaxableRevenue: customer?.monthlyNetTaxableRevenue || undefined,
    currentHousingStatus: customer?.currentHousingStatus || undefined,
    rentAmount: customer?.rentAmount || undefined,
    weddingContract: customer?.weddingContract || '',
    weddingNotary: customer?.weddingNotary || '',
    weddingNotaryCity: customer?.weddingNotaryCity || '',
    pacsDate: customer?.pacsDate ? _date.format(_date.formatters.input)(customer?.pacsDate) : '',
    pacsLocation: customer?.pacsLocation || '',
    occupationalCategory: customer?.occupationalCategory || undefined,
  }

  return pick(
    { ...defaultCustomerValues, ...formData?.situation },
    keys(defaultCustomerValues) as (keyof SituationInformationFormValues)[]
  )
}

export const situationInformationFormServices = {
  schema,
  tPrefix,
  getDefaultValues,
}
