import { Box, Icon, Link, useMultiStyleConfig } from '@chakra-ui/react'
import { Back } from 'assets/svg/Back'
// @ts-ignore
import { useTranslate } from 'services/i18n'

export const CustomLink = (props: {
  titleTranslationKey: string
  backIcon?: boolean
  variant?: string
  onClick?: () => void
}) => {
  const { variant, backIcon = true, titleTranslationKey, onClick } = props
  const t = useTranslate()
  const styles = useMultiStyleConfig('CustomLink', { variant })

  return (
    <Link sx={styles.linkText} _hover={styles.linkTextHover} onClick={onClick}>
      <Box role="group" __css={styles.linkContainer} _hover={styles.linkContainerHover}>
        {backIcon && (
          <Icon sx={styles.icon} _groupHover={styles.iconHover}>
            {Back}
          </Icon>
        )}
        {t(titleTranslationKey)}
      </Box>
    </Link>
  )
}
