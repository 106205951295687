import { Locale } from '@happywait/hw-lib-front.core.api'
import { queryClient } from 'App'
import { SetState } from 'zustand'
import { Store } from '../types'

export const loginSlice = (set: SetState<Store>) => ({
  token: '',
  setToken: (token: string) => {
    queryClient.clear()
    set({ token })
  },
  modules: [],
  setModules: (modules: string[]) => set({ modules }),
  loginPicture: '',
  setLoginPicture: (loginPicture: string) => set({ loginPicture }),
  primoConnection: null,
  setPrimoConnection: (primoConnection: boolean) => set({ primoConnection }),
  locale: null,
  setLocale: (locale: Locale) => set({ locale }),
})
