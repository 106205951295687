export const defaultLocale = 'en_GB'

export const localeToCountryCode = {
  fr_FR: 'FR',
  en_GB: 'GB',
  en_DEFAULT: 'GB',
  es_ES: 'ES',
  pt_PT: 'PT',
  nl_NL: 'NL',
} as const

export const localeToShortLocale = {
  fr_FR: 'fr',
  en_GB: 'en',
  en_DEFAULT: 'en',
  es_ES: 'es',
  pt_PT: 'pt',
  nl_NL: 'nl',
} as const

export const localeToTimezone = {
  fr_FR: 'Europe/Paris',
  en_GB: 'Europe/London',
  en_DEFAULT: 'Europe/London',
  es_ES: 'Europe/Madrid',
  pt_PT: 'Europe/Lisbon',
  nl_NL: 'Europe/Asmterdam',
} as const

export const shortLocaleList = Object.values(localeToShortLocale)
