const {
  baseAsset,
  baseDev,
  color,
  conversationRankId,
  eventApiURL,
  forgotPassword,
  promoter,
  successUrl,
  token,
  translation,
  envName,
  heap,
  baseDocumentThumb,
  basePictureThumb
} = window

export const htmlInjectedData = {
  baseAsset,
  baseDev,
  color,
  conversationRankId,
  eventApiURL,
  forgotPassword,
  logo: promoter?.logo?.url,
  successUrl,
  token,
  translation,
  envName,
  heap,
  baseDocumentThumb,
  basePictureThumb
}
