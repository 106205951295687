import { Locale, useCustomerInitialData } from '@happywait/hw-lib-front.core.api'
import { Loader } from 'components/Loader/Loader'
import { defaultLocale } from 'const'
import { useLocale } from 'hooks/useLocale'
import useNavigateParams from 'hooks/useNavigateParams'
import { useEffect } from 'react'
import { _i18n } from 'services/i18n'
import { useStore } from 'store'
import { selectInitialData, selectLocale, selectSetInitialData } from 'store/selectors'
import { InitialData } from 'store/types'

export const TranslationHandler = ({ children }) => {
  const setInitialData = useStore(selectSetInitialData)
  const initialData = useStore(selectInitialData)
  const locale = useStore(selectLocale)
  const navigate = useNavigateParams()

  useLocale()
  useCustomerInitialData({
    queryParams: {
      enabled: !initialData,
      onSuccess: (_customerInitialData) => {
        if (_customerInitialData && !initialData) {
          const _initialData: InitialData = {
            defaultLang: _customerInitialData?.defaultLang,
            langsAllowed: _customerInitialData?.langsAllowed,
            logo: _customerInitialData?.logo,
            name: _customerInitialData?.name,
          }
          setInitialData(_initialData)
        }
      },
      onError: () => {
        const ErrorInitialData: InitialData = {
          defaultLang: defaultLocale as Locale,
          langsAllowed: [defaultLocale] as Locale[],
          logo: undefined,
          name: undefined,
        }
        setInitialData(ErrorInitialData)
        navigate('/404')
      },
    },
  })

  useEffect(() => {
    if (locale) _i18n.init(locale)
  }, [locale])

  return !!initialData ? children : <Loader />
}
