import { useMemo } from 'react'
import { createSearchParams, useLocation, useSearchParams } from 'react-router-dom'
import { omit } from 'remeda'

const useQueryParams = () => {
  const location = useLocation()
  const [, setSearchParams] = useSearchParams()

  const queryObjectParams = useMemo(() => {
    const queryString = location.search.split('?')[1]
    const params = new URLSearchParams(queryString)
    const queryParams = {}

    params.forEach((valeur, key) => {
      queryParams[key] = valeur
    })
    return queryParams
  }, [location.search])

  const queryStringParams = createSearchParams(queryObjectParams).toString()

  const updateParams = (queryParams: Record<string, string>) =>
    setSearchParams({ ...queryObjectParams, ...queryParams })

  const deleteQueryParams = (paramsOmit: string[]) => {
    setSearchParams(omit(queryObjectParams, paramsOmit as never[]))
  }

  return {
    deleteQueryParams,
    updateParams,
    queryObjectParams,
    queryStringParams,
  }
}

export default useQueryParams
