import { animated } from 'react-spring'
import styled from 'styled-components'
import { getHexWithAlpha } from '../../theme/hexaTransparency'

type ContainerStyleProps = {
  backgroundColor: string
  color: string
}

const Image = styled(animated.div)`
  display: flex;
`

const Container = styled.div<ContainerStyleProps>`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: ${({ backgroundColor }) => getHexWithAlpha(backgroundColor, 20)};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  :hover {
    background-color: ${({ backgroundColor }) => getHexWithAlpha(backgroundColor, 20)};
  }
  :active {
    background-color: ${({ backgroundColor }) => backgroundColor};
  }

  ${Image} {
    * {
      fill: ${({ color }) => color};
    }
  }
`

export const SwitchChevronStyles = {
  Container,
  Image,
}
