import { ComponentMultiStyleConfig } from '@chakra-ui/react'

export const CustomLinkTheme: ComponentMultiStyleConfig = {
  parts: ['icon', 'linkText', 'linkContainer', 'linkContainerHover', 'iconHover', 'linkTextHover'],
  baseStyle: {
    linkContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '36px',
      minWidth: '36px',
    },
    linkText: {
      fontSize: '1.4rem',
      lineHeight: '1.8rem',
      color: 'black',
    },
    icon: {
      height: '23px',
      width: '23px',
      marginRight: '5px',
      opacity: 1,
    },
  },
  variants: {
    primary: {
      linkContainer: {
        backgroundColor: '#4B9190',
        borderRadius: '5px',
        margin: '2% 0',
        padding: '10px 40px',
        width: 'fit-content',
      },
      linkContainerHover: {
        backgroundColor: '#1E6665',
      },
      linkText: {
        color: 'white',
      },
      linkTextHover: {
        color: 'inital',
        textDecoration: 'none',
        transition: 'initial',
      },
      icon: {
        color: '#000000',
      },
      iconHover: {
        color: '#000000',
        opacity: 0.5,
        transition: 'opacity 0.25s ease-out',
      },
    },
    secondary: {
      linkText: {
        fontSize: '1.4rem',
        lineHeight: '1.8rem',
        fontWeight: '600',
        color: 'black',
      },
      linkTextHover: {
        color: '#4B9190',
        textDecoration: 'none',
        transition: 'color 0.25s ease-out',
      },
      iconHover: {
        color: '#4B9190',
        opacity: 0.5,
        transition: 'opacity 0.25s ease-out',
      },
    },
  },
  defaultProps: {
    variant: 'secondary',
  },
}
