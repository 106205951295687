export interface BuildMatchPatternFnArgs<Result> {
  matchPattern: RegExp
  parsePattern: RegExp
  valueCallback?: any
}

export default function buildMatchPatternFn<Result>(args: BuildMatchPatternFnArgs<Result>): any {
  return (string: any, options = {} as any) => {
    const matchResult = string.match(args.matchPattern)
    if (!matchResult) return null
    const matchedString = matchResult[0]

    const parseResult = string.match(args.parsePattern)
    if (!parseResult) return null
    let value = (args.valueCallback ? args.valueCallback(parseResult[0]) : parseResult[0]) as Result
    value = options.valueCallback ? options.valueCallback(value) : value

    const rest = string.slice(matchedString.length)

    return { value, rest }
  }
}
