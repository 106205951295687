import { Tag, TagProps, Text } from '@chakra-ui/react'
import { CoCustomer } from 'assets/svg/CoCustomer'
import { useTranslate } from 'services/i18n'
import { theme } from 'theme'

type CoCustomerBadge = {
  displayIcon?: boolean
  subTitle?: boolean
} & TagProps

export const CoCustomerBadge = ({ subTitle, displayIcon, ...props }: CoCustomerBadge) => {
  const t = useTranslate()

  const containerStyle = subTitle
    ? {
        p: 0,
      }
    : {
        rounded: 'full',
        px: '15px',
        py: '6px',
        bgColor: 'white',
      }

  return (
    <Tag bgColor="transparent" {...containerStyle} {...props}>
      {displayIcon && <CoCustomer mr={subTitle ? '20px' : '10px'} />}
      <Text as="i" fontWeight={'medium'} color={theme.colors.primary} fontSize="1.6rem" textAlign="center">
        {t('coCustomerArea')}
      </Text>
    </Tag>
  )
}
