import { ReactElement } from 'react'
import { useSpring } from 'react-spring'
import { NavigationItemStyles as Styles } from './NavigationItem.styles'

export type NavigationItemProps = {
  active?: boolean
  title: string
  icon: ReactElement
  notifications?: number
  onClick?: () => void
  id?: string
}

export const NavigationItem = ({ active = false, title, icon, notifications, onClick, id }: NavigationItemProps) => {
  const barStyle = useSpring({
    opacity: active ? 1 : 0,
    config: {
      duration: 80,
    },
  })

  return (
    <Styles.Container active={active} onClick={onClick} id={id}>
      <Styles.Bar style={barStyle} />
      <Styles.IconTitleContainer id={`${id}_container_title`}>
        <Styles.Icon>{icon}</Styles.Icon>
        <Styles.Title id={`${id}_title`}>{title}</Styles.Title>
      </Styles.IconTitleContainer>
      {notifications && <Styles.NotificationsContainer>{notifications}</Styles.NotificationsContainer>}
    </Styles.Container>
  )
}
