import { useLogout } from 'hooks/useLogout'
import useNavigateParams from 'hooks/useNavigateParams'
import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { ErrorScreen } from './ErrorScreen'

type FallbackErrorScreenProps = {
  resetError?: () => void
  error?: Error
}

const logoutButtonKeys = ['noSaleError']

export const FallbackErrorScreen = ({ resetError, error }: FallbackErrorScreenProps) => {
  const navigate = useNavigateParams()
  const { logoutCustomer } = useLogout()
  const errorMessage = error?.message || ''
  const location = useLocation()
  const errorLocation = useRef(location.pathname)

  useEffect(() => {
    if (location.pathname !== errorLocation.current) {
      resetError?.()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  const goHome = () => {
    navigate('/home')
    resetError?.()
  }

  const logout = () => {
    logoutCustomer()
    resetError?.()
  }

  return (
    <ErrorScreen
      buttonTitle={logoutButtonKeys.includes(errorMessage) ? 'logout' : 'goBackHome'}
      buttonAction={logoutButtonKeys.includes(errorMessage) ? logout : goHome}
    ></ErrorScreen>
  )
}
