import { EntityId, useCustomerProjects, useSaleById } from '@happywait/hw-lib-front.core.api'
import { useTranches, useUnits } from '@happywait/hw-lib-front.core.api/legacy'
import { useStore } from '../store'
import { selectProjectId, selectUnitId } from '../store/selectors'
import { useAccessibleSales } from './useAccessibleSales'

export const useCurrentAcquisition = () => {
  const unitId = useStore(selectUnitId)
  const projectId = useStore(selectProjectId)

  const { accessibleSales, isLoading: isLoadingAccessibleSales } = useAccessibleSales()
  const currentSaleIndex = accessibleSales?.findIndex((sale) => sale.mainBatch === unitId)
  const { data: projects, isLoading: isLoadingProject } = useCustomerProjects({
    params: { ids: [accessibleSales?.[currentSaleIndex as number]?.project as number] },
    queryParams: { enabled: !!accessibleSales?.[currentSaleIndex as number]?.project },
  })

  const { data: tranches, isLoading: isLoadingTranches } = useTranches({
    params: { limit: -1, project: projectId, batch: unitId },
  })
  const { data: currentSale, isLoading: isLoadingSale } = useSaleById({
    params: { id: accessibleSales?.[currentSaleIndex as number]?.id },
    queryParams: {
      enabled: !!accessibleSales && !!projectId && !!unitId,
    },
  })

  const { data: currentUnit, isLoading: isLoadingUnits } = useUnits({
    params: { ids: [accessibleSales?.[currentSaleIndex as number]?.mainBatch as EntityId] },
    queryParams: { enabled: !!accessibleSales?.[currentSaleIndex as number]?.mainBatch },
  })
  const currentProject = projects?.data?.find((project) => project.id === projectId)
  const currentTranche = tranches?.find((tranche) => tranche.id === currentUnit?.[0]?.trancheId)

  return {
    project: currentProject,
    sale: currentSale,
    accessibleSales,
    tranche: currentTranche,
    unit: currentUnit?.[0],
    isLoading: isLoadingSale || isLoadingProject || isLoadingTranches || isLoadingAccessibleSales || isLoadingUnits,
  }
}
