import styled from 'styled-components'
import { device } from '../../theme'
import { regular12, semibold12, semibold14 } from '../Font/Font'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  @media ${device.tabletL} {
    padding-left: 0px;
    padding-right: 0px;
    justify-content: center;
  }
`

const User = styled.div`
  display: flex;
  align-items: center;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  cursor: pointer;
  margin-bottom: 10px;
  @media ${device.tabletL} {
    flex-direction: column;
  }
`

const TextContainer = styled.div`
  @media ${device.tabletL} {
    text-align: center;
  }
`

const Title = styled.div`
  ${semibold14};
`

const Name = styled.div`
  ${regular12};
  opacity: 0.6;
`

const Chevron = styled.div`
  margin-left: auto;
`

const Button = styled.button`
  display: flex;
  align-items: center;

  ${semibold12}
  @media ${device.tabletL} {
    justify-content: center;
  }
`

export const NavigationUserStyles = {
  Container,
  Title,
  Chevron,
  Name,
  TextContainer,
  User,
  Button,
}
