import { InvestmentType } from '@happywait/hw-lib-front.core.api/legacy'
import { RentalInvestment } from 'assets/svg/RentalInvestment'
import { SecondHome } from 'assets/svg/SecondHome'
import Joi from 'joi'
import { PrincipalResidence } from '../../assets/svg/PrincipalResidence'

type AnswerMapProps = {
  name: string
  icon: React.FunctionComponent
}

export type UnitTypologySurveyFormValues = {
  investmentType: InvestmentType
}

const schema = Joi.object<UnitTypologySurveyFormValues>({
  investmentType: Joi.string().required(),
})

const ANSWERS_MAP: AnswerMapProps[] = [
  { name: 'PRIMARY_RESIDENCE', icon: PrincipalResidence },
  { name: 'SECONDARY_RESIDENCE', icon: SecondHome },
  { name: 'RENTAL_INVESTMENT', icon: RentalInvestment },
]

export const unitTypologySurveyServices = {
  schema,
  ANSWERS_MAP,
}
