import { Heading } from '@chakra-ui/layout'
import { Grid, VStack } from '@chakra-ui/react'
import { CSPSituation, FamilySituation, HousingStatus } from '@happywait/hw-lib-front.core.api'
import { InvitationOutletContextProps, useInvitationContext } from 'components/InvitationForm/InvitationForm.service'
import { InvitationFormWrapper } from 'components/InvitationForm/InvitationFormWrapper'
import { useCurrencySymbol } from 'hooks/useCurrencySymbol'
import { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useOutletContext } from 'react-router-dom'
import { useTranslate } from 'services/i18n'
import { _validator } from '../../../../services/validator'
import { FormSelectInputControlled } from '../../../FormInputs/FormSelectInput/FormSelectInputControlled'
import { FormTextInputControlled } from '../../../FormInputs/FormTextInput/FormTextInputControlled'
import {
  SituationInformationFormValues,
  situationInformationFormServices as services,
  useCurrentHousingStatusOptions,
  useFamilySituationOptions,
  useFinancialSituationOptions,
} from './SituationInformationForm.services'

const { schema, getDefaultValues, tPrefix } = services

export const SituationInformationForm = (): JSX.Element => {
  const { customer, next, button, isLoading: contextLoading }: InvitationOutletContextProps = useOutletContext()
  const { formData, setFormData } = useInvitationContext()

  const currencySymbol = useCurrencySymbol()
  const t = useTranslate()

  const methods = useForm<SituationInformationFormValues>({
    context: undefined,
    criteriaMode: undefined,
    delayError: 0,
    shouldFocusError: false,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    defaultValues: getDefaultValues({ customer, formData }),
    mode: 'onChange',
    resolver: _validator.resolver(schema),
    reValidateMode: 'onSubmit',
  })

  const saveData = (data: SituationInformationFormValues) => {
    formData.situation = { ...formData.situation, ...data }
    setFormData(formData)
    next()
  }

  const {
    watch,
    trigger,
    formState: { isValid, isValidating },
    handleSubmit,
  } = methods

  const currentHousingStatus = watch(`currentHousingStatus`)
  const currentHousingStatusOptions = useCurrentHousingStatusOptions()
  const familySituation = watch(`familySituation`)
  const financialSituation = watch(`occupationalCategory`)
  const familySituationOptions = useFamilySituationOptions()
  const financialSituationOptions = useFinancialSituationOptions()
  watch((data) => setFormData({ ...formData, ...data }))

  useEffect(() => {
    trigger()
  }, [trigger, currentHousingStatus, familySituation, financialSituation])

  return (
    <InvitationFormWrapper
      title={t('invForm.situation.title')}
      onSubmit={handleSubmit(saveData)}
      button={button}
      isLoading={contextLoading || isValidating}
      isDisabled={!isValid}
    >
      <FormProvider {...methods}>
        <VStack alignItems="stretch" spacing={8}>
          <Heading fontSize="16px">{t(`${tPrefix}.sections.personal`)}</Heading>
          <Grid gap={8} gridTemplateColumns={{ base: '1fr', lg: '1fr 1fr' }}>
            <FormSelectInputControlled
              title={t(`${tPrefix}.familySituation`)}
              name="familySituation"
              options={familySituationOptions}
              wrapperStyle={{ maxW: 'max-content' }}
              removeSelection={true}
            />
          </Grid>
          {familySituation === FamilySituation.MARRIED && (
            <Grid gap={8} gridTemplateColumns={{ base: '1fr', lg: '1fr 1fr' }}>
              <FormTextInputControlled
                title={t(`${tPrefix}.contractWeddingDate`)}
                type="date"
                name="contractWeddingDate"
              />
              <FormTextInputControlled title={t(`${tPrefix}.weddingDate`)} type="date" name="weddingDate" />
              <FormTextInputControlled title={t(`${tPrefix}.weddingCity`)} name="weddingCity" />
              <FormTextInputControlled title={t(`${tPrefix}.weddingContract`)} name="weddingContract" />
              <FormTextInputControlled title={t(`${tPrefix}.weddingNotary`)} name="weddingNotary" />
              <FormTextInputControlled title={t(`${tPrefix}.weddingNotaryCity`)} name="weddingNotaryCity" />
            </Grid>
          )}
          {familySituation === FamilySituation.CIVIL_PARTNERSHIP && (
            <Grid gap={8} gridTemplateColumns={{ base: '1fr', lg: '1fr 1fr' }}>
              <FormTextInputControlled title={t(`${tPrefix}.pacsLocation`)} name="pacsLocation" />
              <FormTextInputControlled title={t(`${tPrefix}.pacsDate`)} name="pacsDate" type="date" />
            </Grid>
          )}
          <Heading fontSize="16px" pt={3}>
            {t(`${tPrefix}.sections.financial`)}
          </Heading>
          <Grid gap={8} gridTemplateColumns={{ base: '1fr', lg: '1fr 1fr' }}>
            <FormSelectInputControlled
              title={t(`${tPrefix}.occupationalCategory`)}
              name="occupationalCategory"
              options={financialSituationOptions}
            />
            {financialSituation === CSPSituation.OTHER && (
              <FormTextInputControlled
                title={t(`${tPrefix}.occupationalCategoryOther`)}
                name="occupationalCategoryOther"
              />
            )}
            <FormTextInputControlled
              title={t(`${tPrefix}.taxableRevenue`)}
              name="taxableRevenue"
              type="number"
              emptyValue={null}
              rightAddon={currencySymbol}
            />
            <FormTextInputControlled
              title={t(`${tPrefix}.taxReferenceRevenue`)}
              name="taxReferenceRevenue"
              type="number"
              emptyValue={null}
              rightAddon={currencySymbol}
            />
            <FormTextInputControlled
              title={t(`${tPrefix}.monthlyNetTaxableRevenue`)}
              name="monthlyNetTaxableRevenue"
              type="number"
              emptyValue={null}
              rightAddon={currencySymbol}
            />
            <FormSelectInputControlled
              title={t(`${tPrefix}.currentHousingStatus`)}
              name="currentHousingStatus"
              options={currentHousingStatusOptions}
            />
            {currentHousingStatus === HousingStatus.TENANT && (
              <FormTextInputControlled
                title={t(`${tPrefix}.rentAmount`)}
                name="rentAmount"
                type="number"
                emptyValue={null}
                rightAddon={currencySymbol}
              />
            )}
          </Grid>
        </VStack>
      </FormProvider>
    </InvitationFormWrapper>
  )
}
