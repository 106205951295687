import { DEFAULT_COLOR } from 'const'
import { replaceColor } from 'lottie-colorify'
import Lottie, { LottieProps } from 'lottie-react-web'
import { memo } from 'react'
import { useTheme } from 'styled-components'
import { LottiePictureStyles as Styles } from './LottiePicture.styles'

export type LottiePictureProps = {
  animation: any
  maxWidth?: number
  margin?: string
} & Partial<LottieProps>

const handleReplaceColor = (DEFAULT_COLOR: string, primaryColor: string | number[], animation: any) => {
  try {
    return replaceColor(DEFAULT_COLOR, primaryColor, animation)
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Check the color')
    return animation
  }
}

export const LottiePicture = memo(({ animation, maxWidth = 470, margin = '', ...props }: LottiePictureProps) => {
  const theme = useTheme()
  const primaryColor = theme.colors.primary

  const animationData =
    primaryColor && primaryColor !== DEFAULT_COLOR
      ? handleReplaceColor(DEFAULT_COLOR, primaryColor, animation)
      : animation

  return (
    <Styles.Container maxWidth={maxWidth} margin={margin}>
      <Lottie
        {...props}
        options={{
          animationData: animationData,
          loop: false,
          autoplay: false,
        }}
      />
    </Styles.Container>
  )
})
