import { useMePromoterConfiguration } from '@happywait/hw-lib-front.core.api'
import { numberFormatter } from 'services/numberFormatter'

const formatCurrencyCodeToSymbol = (currencyCode: string) =>
  numberFormatter(undefined, { style: 'currency', currency: currencyCode })
    .formatToParts(0)
    .find((part) => part.type === 'currency')?.value

export const useCurrencySymbol = () => {
  const { data } = useMePromoterConfiguration()

  const currencyCode = data?.currencyCode || 'EUR'

  return useFormatCurrencySymbol(currencyCode)
}

export const useFormatCurrencySymbol = (currencyCode) => {
  return formatCurrencyCodeToSymbol(currencyCode)
}
