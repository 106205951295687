import { useTheme } from 'styled-components'
export const RentalInvestment = () => {
  const theme = useTheme()

  return (
    <svg width={84} height={80} xmlns="http://www.w3.org/2000/svg">
      <g fillRule="nonzero" fill="none">
        <path
          d="M82.988 38.307C85.268 13.871 56.863 1.61 34.179.13 3.603-1.874-1.495 19.81.325 42.539 2.146 65.27 14.766 81.688 37.4 79.861c23.965-1.927 43.472-18.856 45.588-41.554z"
          fill={theme.colors.primary}
          opacity={0.1}
        />
        <g fill="#2E3844">
          <path d="M60.335 48.763c1.615 0 2.932 1.301 3.002 2.933l.003.137v4.673a.63.63 0 01-.623.637.628.628 0 01-.62-.563l-.004-.074v-4.673c0-.992-.787-1.796-1.758-1.796-.935 0-1.699.745-1.754 1.686l-.004.11v4.673a.63.63 0 01-.623.637.628.628 0 01-.62-.563l-.003-.074v-4.673c0-1.695 1.345-3.07 3.004-3.07zM51.994 30.126c.32 0 .584.246.62.563l.004.074v25.743a.63.63 0 01-.624.637.628.628 0 01-.619-.563l-.004-.074V30.763a.63.63 0 01.623-.637zM68.652 30.126c.32 0 .583.246.619.563l.004.074v25.743a.63.63 0 01-.623.637.628.628 0 01-.62-.563l-.004-.074V30.763a.63.63 0 01.624-.637z" />
          <path d="M66.006 23.135h-11.35a2.998 2.998 0 00-2.68 1.606l-2.922 5.741c-.216.424.085.93.553.93h21.457c.468 0 .769-.506.553-.93l-2.917-5.73a3.006 3.006 0 00-2.694-1.617z" />
          <path d="M57.954 20.78c.32 0 .583.245.62.562l.003.074v2.337a.63.63 0 01-.623.636.628.628 0 01-.62-.562l-.003-.074v-2.337a.63.63 0 01.623-.637zM71.064 55.87a.63.63 0 01.623.636c0 .327-.24.596-.55.633l-.073.004H32.831a.63.63 0 01-.623-.637c0-.326.24-.596.55-.632l.073-.005h38.233zM41.729 41.363a23.36 23.36 0 00-2.218 7.614l-.03.29-.005.014a.649.649 0 00-.024.21c.082 1.634 1.404 2.917 3.008 2.917 1.603 0 2.925-1.283 3.007-2.918l-.002-.094a23.371 23.371 0 00-2.443-8.408.617.617 0 00-1.106 0l-.187.375zm.74 1.38l.146.342a22.101 22.101 0 011.565 6.018l.038.365.003-.043c-.048.958-.822 1.71-1.761 1.71l-.108-.003c-.855-.053-1.55-.728-1.646-1.6l.002-.013c.212-2.22.757-4.39 1.613-6.434l.147-.342z" />
          <path d="M42.469 51.164c.32 0 .583.246.62.562l.003.075v4.705a.63.63 0 01-.623.637.628.628 0 01-.62-.563l-.004-.074v-4.705a.63.63 0 01.624-.637zM63.914 44.147a.63.63 0 01.623.637c0 .326-.24.596-.55.632l-.073.005h-7.15a.63.63 0 01-.624-.637c0-.327.24-.596.55-.633l.073-.004h7.15zM63.914 39.474a.63.63 0 01.623.636c0 .327-.24.596-.55.633l-.073.004h-7.15a.63.63 0 01-.624-.637c0-.326.24-.595.55-.632l.073-.004h7.15zM63.914 34.8a.63.63 0 01.623.637c0 .326-.24.595-.55.632l-.073.004h-7.15a.63.63 0 01-.624-.636c0-.327.24-.596.55-.633l.073-.004h7.15z" />
        </g>
        <path
          d="M43.725 12.55a.63.63 0 01.87.255.67.67 0 01-.185.853l-.063.042-4.955 2.84a.63.63 0 01-.87-.254.67.67 0 01.185-.853l.063-.042 4.955-2.84zM33.969 5.667c.098-.34.476-.541.844-.45.342.084.555.391.504.706l-.016.073-1.737 6c-.099.339-.477.54-.845.45-.341-.085-.554-.392-.504-.707l.016-.073 1.738-5.999zM23.363 6.246c.313-.065.614.14.707.464l.017.077.837 4.877c.064.368-.158.723-.495.792-.312.064-.614-.14-.706-.465l-.018-.077-.837-4.877c-.063-.368.158-.722.495-.791z"
          fill="#2E3844"
        />
        <path
          d="M33.456 19.2a11.815 11.815 0 00-13.82 1.575 11.925 11.925 0 00-.871 16.68c.166.19.214.455.126.692l-7.43 19.696a.695.695 0 00-.032.108l2.721 6.061a.678.678 0 00.912.337l5.963-2.681.677-1.722-1.728-3.088a1.103 1.103 0 01.595-1.588l2.867-.998-1.48-2.668a1.103 1.103 0 01-.077-.934c.122-.299.367-.53.671-.636l2.861-.99-1.487-2.644a1.11 1.11 0 01.633-1.588l3.278-1.176.697-1.836a.678.678 0 01.55-.438c5.077-.63 9.178-4.457 10.172-9.493A11.91 11.91 0 0033.456 19.2zm-3.36 8.896a3.337 3.337 0 01-3.418-1.461 3.365 3.365 0 01.038-3.729 3.336 3.336 0 016.044 1.276 3.358 3.358 0 01-.524 2.511 3.335 3.335 0 01-2.14 1.403z"
          fill="#FFF"
        />
        <ellipse fill={theme.colors.primary} cx={27.5} cy={29.5} rx={12.5} ry={12} />
        <path
          d="M22.4 59.127l-1.658-2.9 2.9-1.007a1.1 1.1 0 00.68-.629 1.09 1.09 0 00-.07-.925l-1.512-2.623 2.894-.981c.311-.1.56-.33.68-.63.115-.305.09-.645-.071-.93l-1.473-2.624 3.202-1.075c.309-.102.557-.332.679-.63l.602-1.56c4.118-.644 7.695-3.14 9.67-6.748a12.928 12.928 0 00.404-11.646A8.772 8.772 0 0039 23.59c-2.389-4.414-7.122-7.113-12.209-6.96-5.087.152-9.644 3.128-11.755 7.677a12.942 12.942 0 001.812 13.752L9.47 57.108a1.51 1.51 0 00-.064.994s.039.056.032.075l2.786 6.07c.233.493.65.879 1.166 1.076a2.09 2.09 0 001.594-.056l6.027-2.655c.33-.143.589-.407.723-.736l.737-1.824c.12-.303.094-.643-.07-.925zm-1.28.554l-.686 1.704-6.02 2.642a.697.697 0 01-.533.025.68.68 0 01-.389-.358l-2.76-5.957a.677.677 0 01.032-.107l7.518-19.502a.67.67 0 00-.128-.685c-.8-.894-1.46-1.898-1.96-2.982-2.128-4.636-.986-10.09 2.832-13.525 3.818-3.435 9.45-4.075 13.964-1.586 4.515 2.488 6.894 7.543 5.899 12.533-.995 4.991-5.14 8.79-10.277 9.422a.683.683 0 00-.557.434l-.723 1.818-3.37 1.145a1.107 1.107 0 00-.698.637c-.125.305-.103.648.058.936l1.505 2.617-2.894.981a1.125 1.125 0 00-.68.63c-.115.303-.087.642.078.924l1.505 2.617-2.901.988a1.1 1.1 0 00-.68.646c-.116.305-.087.644.078.926l1.786 3.077z"
          fill="#2E3844"
        />
        <path
          d="M27.623 28.821a4.154 4.154 0 10-2.417-5.356 4.149 4.149 0 002.417 5.356zm4.036-2.913a2.743 2.743 0 11-1.593-3.515 2.75 2.75 0 011.593 3.535v-.02zM15.741 56.805a.604.604 0 00.598.25.646.646 0 00.49-.448l4.94-14.18a.717.717 0 00-.093-.69.615.615 0 00-.588-.25.652.652 0 00-.488.448l-4.976 14.18a.73.73 0 00.117.69zM14.89 59.104a.51.51 0 00.397-.003.489.489 0 00.268-.287.537.537 0 00-.313-.672.536.536 0 00-.398 0 .528.528 0 00-.268.285.537.537 0 00.313.677z"
          fill="#2E3844"
        />
      </g>
    </svg>
  )
}
