import { Locale } from '@happywait/hw-lib-front.core.api'
import { Module } from '@happywait/hw-lib-front.core.api/legacy'
import { _locale } from 'services/locale'
import { ModuleName } from './types'

export const getAccessibleModulesCodes = (accessRights: Module[]) =>
  accessRights.reduce((acc: string[], { writeRight, readRight, module }) => {
    if (writeRight || readRight) {
      acc.push(module)
    }
    return acc
  }, [])

export const moduleCodesForArchivedUnit: string[] = ['HOME', 'PROFIL']

const moduleTitleMap: Partial<Record<ModuleName, string>> = {
  account: 'routing.module.profil.title',
  'choices-options': 'routing.module.tool.consumer.title',
  documents: 'routing.module.media.document.title',
  'funding-appeal': 'routing.module.funding.appeal.title',
  home: 'routing.module.home.title',
  messaging: 'routing.module.message.title',
  pictures: 'routing.module.media.photo.title',
  services: 'routing.module.service.title',
  snags: 'routing.module.snags.title',
  sav: 'routing.module.sav.title',
}

const getRoutingTranslations = (locale: Locale) => window.translation?.[locale]

export const getModuleTitle = (moduleName: ModuleName) => {
  const translations = getRoutingTranslations(_locale.getCurrentLocale())
  const key = moduleTitleMap[moduleName]

  if (translations && key) {
    return translations[key]
  }
}
