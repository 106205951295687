import { Text, VStack } from '@chakra-ui/react'
import { ActionError } from 'assets/svg/ActionError'
import { Button } from 'design-system/Button/Button'
import { useTranslate } from 'services/i18n'

type ErrorScreenProps = {
  title?: string
  message?: string
  buttonAction?: () => void
  buttonTitle?: string
}

export const ErrorScreen = ({ title, message, buttonAction, buttonTitle }: ErrorScreenProps) => {
  const t = useTranslate()

  return (
    <VStack flex={1} justify="center" spacing="20px" px="20px">
      <ActionError />
      <div>
        <Text textAlign="center" fontWeight="semibold" fontSize="22px">
          {t(title ? title : 'error.globalError')}
        </Text>
        {message && (
          <Text textAlign="center" fontSize={16}>
            {t(message)}
          </Text>
        )}
      </div>
      {buttonAction && buttonTitle && <Button title={t(buttonTitle)} onClick={buttonAction} />}
    </VStack>
  )
}
