import { useMe } from '@happywait/hw-lib-front.core.api'
import { useDevice } from 'hooks/useDevice'
import { ReactElement, ReactNode } from 'react'
import { useStore } from 'store'
import { selectToken } from 'store/selectors'
import { Observer } from './Observer'

export type ObserverWrapperType = {
  children: ReactNode | ReactElement | Element
}

export const ObserverWrapper = ({ children }: ObserverWrapperType) => {
  const token = useStore(selectToken)
  const { data: me } = useMe({ queryParams: { enabled: !!token } })
  const isObserver = me?.isObserver
  const isMobile = useDevice('mobileL')
  return (
    <>
      {isMobile && <Observer isObserver={isObserver} />}
      {children}
      {!isMobile && <Observer isObserver={isObserver} />}
    </>
  )
}
