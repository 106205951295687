import { StepIndexStyles as Styles } from './StepIndex.styles'

export type StepIndexProps = {
  total: number
  index: number
  dataTest?: string
}

export const StepIndex = ({ total, index, dataTest }: StepIndexProps) => {
  return (
    <Styles.Container>
      <Styles.Index data-test={dataTest}>{index}</Styles.Index>
      <Styles.Total>/ {total}</Styles.Total>
    </Styles.Container>
  )
}
