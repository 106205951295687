import { Locale } from '@happywait/hw-lib-front.core.api'
import { localeToTimezone } from 'const'
import { _locale } from 'services/locale'

const fullDate = (date: Date, followTimezone: boolean, locale?: Locale): string =>
  new Intl.DateTimeFormat(
    getIntlLocale(locale),
    getDefaultOptions(
      {
        dateStyle: 'full',
      },
      followTimezone,
      getLocale(locale)
    )
  ).format(date)

const fullDateWithShortTime = (date: Date, followTimezone: boolean, locale?: Locale): string =>
  new Intl.DateTimeFormat(
    getIntlLocale(locale),
    getDefaultOptions(
      {
        dateStyle: 'full',
        timeStyle: 'short',
      },
      followTimezone,
      getLocale(locale)
    )
  ).format(date)

const longDate = (date: Date, followTimezone: boolean, locale?: Locale): string =>
  new Intl.DateTimeFormat(
    getIntlLocale(locale),
    getDefaultOptions(
      {
        dateStyle: 'long',
      },
      followTimezone,
      getLocale(locale)
    )
  ).format(date)

const shortDate = (date: Date, followTimezone: boolean, locale?: Locale): string =>
  new Intl.DateTimeFormat(
    getIntlLocale(locale),
    getDefaultOptions(
      {
        dateStyle: 'short',
      },
      followTimezone,
      getLocale(locale)
    )
  ).format(date)

const shortDateWithShortTime = (date: Date, followTimezone: boolean, locale?: Locale): string =>
  new Intl.DateTimeFormat(
    getIntlLocale(locale),
    getDefaultOptions(
      {
        dateStyle: 'short',
        timeStyle: 'short',
      },
      followTimezone,
      getLocale(locale)
    )
  ).format(date)

const dayWithMonth = (date: Date, followTimezone: boolean, locale?: Locale): string =>
  new Intl.DateTimeFormat(
    getIntlLocale(locale),
    getDefaultOptions(
      {
        day: 'numeric',
        month: 'long',
      },
      followTimezone,
      getLocale(locale)
    )
  ).format(date)

const month = (date: Date, followTimezone: boolean, locale?: Locale): string =>
  new Intl.DateTimeFormat(
    getIntlLocale(locale),
    getDefaultOptions(
      {
        month: 'long',
      },
      followTimezone,
      getLocale(locale)
    )
  ).format(date)

const monthWithYear = (date: Date, followTimezone: boolean, locale?: Locale): string =>
  new Intl.DateTimeFormat(
    getIntlLocale(locale),
    getDefaultOptions(
      {
        month: 'long',
        year: 'numeric',
      },
      followTimezone,
      getLocale(locale)
    )
  ).format(date)

const shortTime = (date: Date, followTimezone: boolean, locale?: Locale): string =>
  new Intl.DateTimeFormat(
    getIntlLocale(locale),
    getDefaultOptions(
      {
        timeStyle: 'short',
      },
      followTimezone,
      getLocale(locale)
    )
  ).format(date)

const weekday = (date: Date, followTimezone: boolean, locale?: Locale): string =>
  new Intl.DateTimeFormat(
    getIntlLocale(locale),
    getDefaultOptions(
      {
        weekday: 'long',
      },
      followTimezone,
      getLocale(locale)
    )
  ).format(date)

const input = (date: Date): string =>
  new Intl.DateTimeFormat('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(date)

const getDefaultOptions = (
  options: Intl.DateTimeFormatOptions,
  followTimezone: boolean,
  locale: Locale
): Intl.DateTimeFormatOptions => {
  if (followTimezone) {
    options['timeZone'] = localeToTimezone[locale]
  }

  return options
}

const getLocale = (locale?: Locale): Locale => (typeof locale !== 'undefined' ? locale : _locale.getCurrentLocale())

const getIntlLocale = (locale?: Locale): string => getLocale(locale).replace('_', '-')

export const formatters = {
  fullDate, // FR: Vendredi 21 avril 2023
  fullDateWithShortTime, // FR: Vendredi 21 avril 2023 à 10:14
  longDate, // FR: 21 avril 2023
  shortDate, // FR: 21/04/2023
  shortDateWithShortTime, // FR: 21/04/2023 à 10:14
  dayWithMonth, // FR: 21 Avril
  month, // FR: Avril
  monthWithYear, // FR: Avril 2023
  shortTime, // FR: 10:14
  weekday, // FR: Vendredi
  input,
}
