import { useMe, useMedias } from '@happywait/hw-lib-front.core.api'
import { Separator } from '../../components/Separator/Separator'
import { useTranslate } from '../../services/i18n'
import { getMediaThumbPath } from '../../tools/thumb'
import { UserPicture } from '../UserPicture/UserPicture'
import { NavigationUserStyles as Styles } from './NavigationUser.styles'

export type NavigationUserProps = {
  onClick?: () => void
}

export const NavigationUser = ({ onClick }: NavigationUserProps) => {
  const t = useTranslate()
  const { data: me } = useMe()
  const { data: avatar } = useMedias({
    params: { ids: [Number(me?.avatarId)] },
    queryParams: { enabled: me && !!me?.avatarId },
  })

  if (!me) {
    return null
  }

  return (
    <Styles.User onClick={onClick}>
      <UserPicture url={getMediaThumbPath(avatar?.data?.[0], 'user').path} name={me?.completeName} size="large" />
      <Separator width={8} />
      <Styles.TextContainer>
        <Styles.Title>{t('myProfile')}</Styles.Title>
        <Styles.Name>{me?.completeName}</Styles.Name>
      </Styles.TextContainer>
    </Styles.User>
  )
}
