import { Icon, IconProps } from '@chakra-ui/react'

export const Folder = (props: IconProps) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18 16"
    width={props.w ? props.w : '1.5em'}
    height={props.h ? props.h : '1.5em'}
    color={props.color || '#2E3844'}
  >
    <path
      d="M5.612 0c.478 0 .931.207 1.245.563l.082.1L8.135 2.26l2.029-.001v-.034c0-.564.427-1.029.976-1.088l.119-.007h3.388c.604 0 1.094.49 1.094 1.095v.034h.6c.878 0 1.597.683 1.655 1.546l.004.114v10.164c0 .878-.682 1.597-1.545 1.655l-.114.004H1.66a1.659 1.659 0 01-1.655-1.545L0 14.082V1.66C0 .78.682.062 1.545.004L1.66 0h3.953zm10.13 9a.53.53 0 01-.848.424l-1.941-1.456-1.941 1.456a.53.53 0 01-.842-.349L10.165 9l-.001-5.683H7.871a.53.53 0 01-.378-.157l-.046-.054-1.355-1.807a.6.6 0 00-.4-.235l-.08-.005H1.659a.6.6 0 00-.596.525l-.004.075v12.423a.6.6 0 00.525.596l.075.004H16.34a.6.6 0 00.596-.524l.004-.076V3.918a.6.6 0 00-.525-.596l-.075-.004-.6-.001zm-1.095-6.812H11.26a.035.035 0 00-.035.036V7.94l1.411-1.059a.53.53 0 01.57-.041l.066.041 1.411 1.06V2.223a.035.035 0 00-.017-.03l-.018-.006z"
      fill={'currentColor'}
      fillRule="nonzero"
    />
  </Icon>
)
