import { CoreProvider } from '@happywait/hw-lib-front.core.api'
import { QueryClient } from '@tanstack/react-query'

import { ProtectedRoute } from 'auth/ProtectedRoute'

import { ErrorBoundary } from '@sentry/react'
import { TranslationHandler } from 'auth/TranslationHandler'
import React, { Suspense, useEffect, useState } from 'react'

import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { ModuleAccess } from 'auth/ModuleAccess'
import { InvitationForm } from 'components/InvitationForm/InvitationForm'
import { Loader } from 'components/Loader/Loader'
import { MagicLink } from 'components/MagicLink/MagicLink'
import { ModuleRightWrapper } from 'components/Modules/ModuleRightWrapper'
import { useToken } from 'hooks/useToken'
import { Onboarding } from 'modules/login/screens/Onboarding/Onboarding'
import { HashRouter, Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { _date } from 'services/date'
import { fetchImageLoginPicture, fetchLoginPicture } from 'utils/loginPicture'
import { FallbackErrorScreen } from './components/ErrorScreen/FallbackErrorScreen'
import { SituationInformationForm } from './components/InvitationForm/components/ComplementaryInformationForm/SituationInformationForm'
import { InformationForm } from './components/InvitationForm/components/InformationForm/InformationForm'
import { StyleProvider } from './components/StyleProvider/StyleProvider'
import {
  AccountModuleLazy,
  AcquisitionModuleLazy,
  ChecklistModuleLazy,
  ChoicesOptionsModuleLazy,
  DocumentsModuleLazy,
  FundingAppealModuleLazy,
  HomeModuleLazy,
  HousingLogbookModuleLazy,
  LoginModuleLazy,
  MessagingModuleLazy,
  PersonalizationModuleLazy,
  PicturesModuleLazy,
  ServicesModuleLazy,
  SnagsModuleLazy,
  modulesRootsNames,
  routeNames,
} from './modules'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 60 * 1000 * 3, // 3 minutes
      retry: false,
    },
  },
})

const RedirectToSlash = () => {
  const location = useLocation()
  const [needsRedirect, setNeedsRedirect] = useState(false)
  useEffect(() => {
    setNeedsRedirect(!/\/.*/.test(location.pathname))
  }, [location, needsRedirect])
  return needsRedirect ? <Navigate to={`${location.pathname}`} /> : null
}

export const App = () => {
  _date.init()
  useToken()

  React.useEffect(() => {
    queryClient.prefetchQuery([fetchLoginPicture], fetchImageLoginPicture)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <React.StrictMode>
      <CoreProvider queryClient={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <HashRouter>
          <RedirectToSlash />
          <StyleProvider>
            <ErrorBoundary fallback={(props) => <FallbackErrorScreen {...props} />}>
              <Suspense fallback={<Loader />}>
                <TranslationHandler>
                  <Routes>
                    <Route path={modulesRootsNames.magicLink} element={<MagicLink />} />
                    <Route path={routeNames.invitation} element={<InvitationForm />}>
                      <Route path="" element={<InformationForm />} />
                      <Route path="1" element={<InformationForm />} />
                      <Route path="2" element={<SituationInformationForm />} />
                    </Route>
                    <Route path={modulesRootsNames.login} element={<LoginModuleLazy />} />
                    <Route path="/" element={<ProtectedRoute />}>
                      <Route path={'/onboarding'} element={<Onboarding />} />
                      <Route element={<ModuleAccess />}>
                        <Route
                          path={modulesRootsNames.home}
                          element={
                            <ModuleRightWrapper moduleCodes={['HOME']}>
                              <HomeModuleLazy />
                            </ModuleRightWrapper>
                          }
                        />
                        <Route index element={<Navigate to="/home" />} />
                        <Route
                          path={modulesRootsNames.pictures}
                          element={
                            <ModuleRightWrapper moduleCodes={['PHOTO_V1', 'PHOTO_V2']}>
                              <PicturesModuleLazy />
                            </ModuleRightWrapper>
                          }
                        />
                        <Route
                          path={modulesRootsNames.account}
                          element={
                            <ModuleRightWrapper moduleCodes={['PROFIL']}>
                              <AccountModuleLazy />
                            </ModuleRightWrapper>
                          }
                        />
                        <Route
                          path={modulesRootsNames.documents}
                          element={
                            <ModuleRightWrapper moduleCodes={['MEDIA']}>
                              <DocumentsModuleLazy />
                            </ModuleRightWrapper>
                          }
                        />
                        <Route
                          path={modulesRootsNames.services}
                          element={
                            <ModuleRightWrapper moduleCodes={['SERVICE']}>
                              <ServicesModuleLazy />
                            </ModuleRightWrapper>
                          }
                        />
                        <Route
                          path={modulesRootsNames.messaging}
                          element={
                            <ModuleRightWrapper moduleCodes={['MESSAGE']}>
                              <MessagingModuleLazy />
                            </ModuleRightWrapper>
                          }
                        />
                        <Route
                          path={modulesRootsNames.fundingAppeal}
                          element={
                            <ModuleRightWrapper moduleCodes={['FUNDING_APPEAL']}>
                              <FundingAppealModuleLazy />
                            </ModuleRightWrapper>
                          }
                        />
                        <Route
                          path={modulesRootsNames.choicesOptions}
                          element={
                            <ModuleRightWrapper moduleCodes={['TOOL']}>
                              <ChoicesOptionsModuleLazy />
                            </ModuleRightWrapper>
                          }
                        />
                        <Route
                          path={modulesRootsNames.snags}
                          element={
                            <ModuleRightWrapper moduleCodes={['SNAG']}>
                              <SnagsModuleLazy />
                            </ModuleRightWrapper>
                          }
                        />
                        <Route
                          path={modulesRootsNames.personalization}
                          element={
                            <ModuleRightWrapper moduleCodes={['SHOWYOU']}>
                              <PersonalizationModuleLazy />
                            </ModuleRightWrapper>
                          }
                        />
                        <Route
                          path={modulesRootsNames.checklist}
                          element={
                            <ModuleRightWrapper moduleCodes={['CHECKLIST']}>
                              <ChecklistModuleLazy />
                            </ModuleRightWrapper>
                          }
                        />
                        <Route
                          path={modulesRootsNames.housing_logbook}
                          element={
                            <ModuleRightWrapper moduleCodes={['HOUSING_LOGBOOK']}>
                              <HousingLogbookModuleLazy />
                            </ModuleRightWrapper>
                          }
                        />
                        <Route path={modulesRootsNames.acquisition} element={<AcquisitionModuleLazy />} />
                        <Route path="*" element={<Navigate to="/home" />} />
                      </Route>
                    </Route>
                    <Route path="/404" element={<FallbackErrorScreen />} />
                  </Routes>
                </TranslationHandler>
              </Suspense>
            </ErrorBoundary>
          </StyleProvider>
        </HashRouter>
      </CoreProvider>
    </React.StrictMode>
  )
}
