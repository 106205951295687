import { Box, Flex, Heading, Text } from '@chakra-ui/react'
import { PropsWithChildren } from 'react'
import { invitationFormService as service } from './InvitationForm.service'
import { InvitationFooter } from './components/InvitationFooter'

const { paddingWithBottomStyles } = service

type InvitationFormWrapperProps = {
  title?: string
  subtitle?: string
  isLoading?: boolean
  isDisabled?: boolean
  button: string
  onSubmit: () => {}
}

export const InvitationFormWrapper = ({
  children,
  title,
  subtitle,
  onSubmit,
  button,
  isLoading,
  isDisabled,
}: PropsWithChildren<InvitationFormWrapperProps>) => {
  return (
    <>
      <Flex overflowY={'auto'} flexGrow="1" direction={'column'}>
        <Box id="main" padding={paddingWithBottomStyles} flexGrow="1">
          <Box mb={{ sm: '1rem', lg: '2rem' }}>
            {title && <Heading m={'1rem 0'}>{title}</Heading>}
            {subtitle && <Text>{subtitle}</Text>}
          </Box>
          {children}
        </Box>
        <Box as="footer" backgroundColor="white" width={'100%'} justifySelf={'flex-end'}>
          <InvitationFooter onSubmit={onSubmit} title={button} isLoading={isLoading} isDisabled={isDisabled} />
        </Box>
      </Flex>
    </>
  )
}
