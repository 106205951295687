import Joi from 'joi'

export type LoginFormValues = {
  username: string
  password: string
}

const schema = Joi.object<LoginFormValues>({
  username: Joi.string().required(),
  password: Joi.string().required(),
})

export const loginServices = {
  schema,
}
