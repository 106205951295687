import { Icon, IconProps } from '@chakra-ui/react'

export const InfoCircle = (props: IconProps) => (
  <Icon viewBox="0 0 22 22" {...props}>
    <defs>
      <clipPath id="i0">
        <path d="M10.125,0 C15.7168831,0 20.25,4.53311691 20.25,10.125 C20.25,15.7168831 15.7168831,20.25 10.125,20.25 C4.53311691,20.25 0,15.7168831 0,10.125 C0,4.53311691 4.53311691,0 10.125,0 Z M10.125,1.5 C5.36154403,1.5 1.5,5.36154403 1.5,10.125 C1.5,14.888456 5.36154403,18.75 10.125,18.75 C14.888456,18.75 18.75,14.888456 18.75,10.125 C18.75,5.36154403 14.888456,1.5 10.125,1.5 Z M9.5,8.125 C10.2147214,8.125 10.802078,8.67031408 10.8687056,9.36757832 L10.875,9.5 L10.875,12.625 C10.875,12.8704599 11.0518752,13.0746084 11.2851244,13.1169443 L11.375,13.125 L12,13.125 C12.4142136,13.125 12.75,13.4607864 12.75,13.875 C12.75,14.2546958 12.4678461,14.568491 12.1017706,14.6181534 L12,14.625 L11.375,14.625 C10.3206382,14.625 9.45683488,13.8091222 9.38048574,12.7742623 L9.375,12.625 L9.375,9.625 L8.875,9.625 C8.49530423,9.625 8.18150904,9.34284612 8.13184662,8.97677056 L8.125,8.875 C8.125,8.49530423 8.40715388,8.18150904 8.77322944,8.13184662 L8.875,8.125 L9.5,8.125 Z M9.8125,5 L9.92827108,5.00623461 C10.4606147,5.06392013 10.875,5.51481762 10.875,6.0625 C10.875,6.64930255 10.3993025,7.125 9.8125,7.125 C9.22569745,7.125 8.75,6.64930255 8.75,6.0625 C8.75,5.51481762 9.16438534,5.06392013 9.69672892,5.00623461 L9.8125,5 Z"></path>
      </clipPath>
    </defs>
    <g transform="translate(-1.0 -1.0)">
      <g transform="translate(2.0 2.0)">
        <g transform="translate(-0.125 -0.125)">
          <g clip-path="url(#i0)">
            <polygon points="0,0 20.25,0 20.25,20.25 0,20.25 0,0" stroke="none" fill="currentColor"></polygon>
          </g>
        </g>
      </g>
    </g>
  </Icon>
)
