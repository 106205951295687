import { useTheme } from 'styled-components'

export const PromoterPlaceholder = () => {
  const theme = useTheme()

  return (
    <svg
      viewBox="0 0 100 100"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <circle id="path-1" cx="50" cy="50" r="50"></circle>
      </defs>
      <g id="element/user/placeholder-promoteur-" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <mask id="mask-2" fill="white">
          <use xlinkHref="#path-1"></use>
        </mask>
        <use id="Mask" fill="#F3F5F7" xlinkHref="#path-1"></use>
        <g id="Happywait-Acquereur-doc-blank" mask="url(#mask-2)">
          <g transform="translate(-135.000000, 14.000000)" id="Path">
            <path
              d="M240,63.8852724 C234.692475,71.6758025 229.917005,79.8114285 225.705769,88.23733 C215.858864,85.9809694 218.38076,86.3552965 218.38076,86.3552965 C218.38076,86.3552965 223.00598,108.762933 223.162944,124.162334 C203.437742,128.321524 190.87012,132.480714 181.305751,130.484303 C171.741382,128.487891 157.237865,126.023571 157.237865,126.023571 L156.986722,85.6378362 L146.522423,92.5316936 C146.522423,92.5316936 131.181762,77.2570683 121,75.4998105 C136.152304,63.2613939 153.638146,51.2309368 165.494196,47.799605 C177.350246,44.3682732 178.490855,46.1879189 178.490855,46.1879189 L190.859655,56.8978332 L194.909339,45.0233457 C200.62502,44.8286711 206.316319,45.8533487 211.599894,48.0283605 C220.348048,51.8444173 240,63.8852724 240,63.8852724 Z"
              stroke="none"
              fill={theme.colors.primary}
              fillRule="nonzero"
            ></path>
            <path
              d="M122,77 C137.159323,64.5730448 154.63073,52.3572529 166.484085,48.8730598 C178.337441,45.3888668 179.634719,47.2048704 179.634719,47.2048704 L192.000709,58.079776 L196.049472,46.0223564 C201.763407,45.8308844 207.452124,46.871112 212.736235,49.0736649 C221.524249,52.8957191 240,64.0345786 240,64.0345786"
              stroke="#FFFFFF"
              strokeWidth="2"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <path
              d="M240.357042,65 C240.357042,65 251.576386,72.4715026 255.108977,78.2227979 C258.641568,83.9740933 262.57818,96.5025907 253.037076,103.186528 C243.658741,109.525642 233.620305,114.827846 223.098107,119 C223.098107,119 222.455818,113.466321 222,109.165803 C224.859223,106.357513 233.871992,91.880829 233.871992,91.880829 L225.729422,88.4611399 L240.357042,65 Z"
              stroke="none"
              fill="#FFFFFF"
              fillRule="nonzero"
            ></path>
            <path
              d="M188.96281,56.4210526 L193.632231,47.1578947 L191.886364,33.4736842 C191.886364,33.4736842 195.21281,33.7157895 195.357438,31.3684211 C195.424155,29.8204032 195.249819,28.2713874 194.840909,26.7789474 L197,25.4526316 C197,25.4526316 193.280992,20.3368421 192.805785,18.0210526 C192.330579,15.7052632 191.772727,9.35789474 191.772727,9.35789474 L174.902893,8 L172,17.9894737 L177.36157,30.1052632 L176.235537,46.0421053 L189.365702,57"
              stroke="none"
              fill="#FFFFFF"
              fillRule="nonzero"
            ></path>
            <path
              d="M121,76 C121,76 139.574758,60.8296292 154.113338,52.9118991 C168.651919,44.9941691 176.807708,45.6487014 176.807708,45.6487014 L191.002118,57.2613722 L194.819278,45.0363969 C200.830776,44.7773646 206.821894,45.9036109 212.340667,48.3301727 C220.997067,52.3312656 249,71.7138688 249,71.7138688"
              stroke="#2E3844"
              strokeWidth="2"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <path
              d="M213.854479,70 C213.854479,70 222.833149,102.126395 223,124.26841 C201.392795,129.286847 188.399273,132.457491 179.681308,130.32623 C170.963343,128.194969 157.239848,126.126701 157.239848,126.126701 L157,85.3492774"
              stroke="#2E3844"
              strokeWidth="2"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <line
              x1="163"
              y1="82"
              x2="146"
              y2="93"
              stroke="#2d3844"
              strokeWidth="2"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></line>
            <path
              d="M248.551032,71 C262.548054,79.4798599 260.997517,97.0595447 252.601414,102.964974 C239.184525,112.422067 222.118074,119 222.118074,119 C221.9915,115.847636 221.44301,112.695271 221,109.028021 C223.803804,105.929352 226.400635,102.650865 228.773779,99.2136602 C234.617298,91.028021 235.39784,87.7705779 239.184525,80.0472855"
              stroke="#2d3844"
              strokeWidth="2"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <path
              d="M152.766784,130.051104 C154.654015,132.636465 157.288654,134.57322 160.311104,135.597024 C166.55468,137.689824 169.187388,131.107968 168.989675,127.874592 C168.791962,124.641216 160.404758,126.294528 155.565987,125.415552 L137.365963,98.0626561 L146.179811,92.1400321 C136.533487,82.209696 129.863266,77.019552 120.966171,75 C108.343742,83.182848 110.258438,96.7860481 119.228376,103.441152 C128.198313,110.096256 152.766784,130.051104 152.766784,130.051104 Z"
              stroke="none"
              fill="#FFFFFF"
              fillRule="nonzero"
            ></path>
            <path
              d="M240,65 C240,65 229.763083,76.5531423 225,88"
              stroke="#2d3844"
              strokeWidth="2"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <path
              d="M153.121671,129.957844 C155.249183,132.55458 158.052138,134.520259 161.225005,135.640617 C167.522934,137.729871 170.189056,130.166769 169.989622,126.938871 C169.790188,123.710973 160.826137,126.207632 155.945242,125.330145 L137.586782,98.0235864 L146.477357,92.1109957 C136.747058,82.1974823 130.018772,77.0161308 121.044224,75 C108.311913,83.1689857 110.243277,96.7491411 119.291301,103.392971 C128.339324,110.036801 153.121671,129.957844 153.121671,129.957844 Z"
              stroke="#2d3844"
              strokeWidth="2"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <path
              d="M192,34 C194.05634,42.3405734 193.999847,46 193.999847,46"
              stroke="#2d3844"
              strokeWidth="2"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <path
              d="M176.299795,45 C176.299795,45 178.162818,37.0291356 177.231307,31.784059 C174.562369,30.0597917 167.612663,20.818958 169.245425,11.0309018 C170.878188,1.24284549 175.619477,2.96711279 182.171459,3.38011094 C188.72344,3.7931091 196.029003,6.27109803 197.703631,7.28294351 C196.93828,7.81526639 196.207673,8.39461048 195.516149,9.01753576 C194.578687,9.80867554 193.35141,10.1823448 192.125027,10.0500311 C191.947222,12.8658231 192.793368,15.651847 194.511372,17.907321 C196.196466,20.1271861 199.336393,22.5948501 199.932979,24.0196937 C200.665629,25.754286 195.149824,26.1672841 195.149824,26.1672841 C195.149824,26.1672841 195.59988,31.8253589 193.548461,32.7339548 C192.020363,33.4050768 189.361892,33.0746783 185.85564,32.5584306"
              stroke="#2E3844"
              strokeWidth="2"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <path
              d="M191.785643,10.8430931 C190.128478,10.2165589 188.356916,9.96730716 186.59525,10.112825 C185.536984,10.4064282 184.516152,10.8255804 183.553679,11.3616893 C182.607145,11.6289323 181.647285,11.844439 180.678202,12.0072886 C180.740486,12.4623832 183.647107,22.1675403 180.875437,20.7493385 C178.892706,19.6909789 178.518998,15.4575408 176.29751,16.314812 C174.076022,17.1720832 177.252542,25.183865 177.916912,27.5545904 C179.380603,32.6982178 178.301002,34 178.301002,34 C178.301002,34 168.37697,25.2897009 169.030959,14.3674304 C169.280098,10.1339922 169.082863,4.51410305 172.882231,1.87878778 C177.034546,-0.989366592 182.795882,0.143078122 187.851325,0.6616743 C192.107447,1.10618531 198.636962,3.44515991 199.675041,6.54615338 C200.899973,10.2292446 198.367061,11.5839448 196.965655,11.7321152 C195.198797,11.7807403 193.43951,11.4788019 191.785643,10.8430931 Z"
              stroke="none"
              fill="#2E3844"
              fillRule="nonzero"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  )
}
