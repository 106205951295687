import { useQueryClient } from '@tanstack/react-query'
import { useStore } from 'store'
import { selectHideModal } from 'store/selectors'
import { ModalsStored } from 'store/types'
import { useShowStandaloneToast } from './useShowStandaloneWMToast'

export const useCleanWelcomeModal = () => {
  const hideModal = useStore(selectHideModal)
  const queryClient = useQueryClient()

  const { showStandaloneToast } = useShowStandaloneToast()

  const cleanWelcomeModal = ({
    invalidateQueries,
    closeModalId,
  }: {
    invalidateQueries: string[]
    closeModalId: keyof ModalsStored
  }) => {
    queryClient.invalidateQueries(invalidateQueries)
    hideModal(closeModalId)
    showStandaloneToast('success')
  }

  return { cleanWelcomeModal }
}
