import { FieldError } from 'react-hook-form'
import { useTranslate } from 'services/i18n'

export type ErrorTooltip = Partial<Record<string, string>>

export const useErrorTooltip = (error?: FieldError, context?: string) => {
  const t = useTranslate(context)

  return (errorTooltip: ErrorTooltip): string => {
    if (!error) return ''
    const errorType = Object.keys(errorTooltip).find((key) => error.type === key)

    if (!errorType) return ''
    return t(`errors.form.tooltip.${errorTooltip[errorType]}`) || ''
  }
}
