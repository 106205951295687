import { Box, Flex, Skeleton, Stack, Text, VStack } from '@chakra-ui/react'
import {
  Evaluation,
  useConversationById,
  usePostConversationEvaluate,
  useUsersMinimal,
} from '@happywait/hw-lib-front.core.api'
import { useCleanConversationEvaluation } from 'components/WelcomeModalWrapper/hooks/useCleanConversationEvaluation'
import { useCleanWelcomeModal } from 'components/WelcomeModalWrapper/hooks/useCleanWelcomeModal'
import { ReactElement, useEffect, useState } from 'react'

import { useStore } from 'store'
import { selectConversationRankId } from 'store/selectors'
import { Evaluation1 } from '../../../../assets/svg/Evaluation1'
import { Evaluation2 } from '../../../../assets/svg/Evaluation2'
import { Evaluation3 } from '../../../../assets/svg/Evaluation3'
import { Button } from '../../../../design-system/Button/Button'
import { StepIndex } from '../../../../design-system/StepIndex/StepIndex'
import { TextArea } from '../../../../design-system/TextArea/TextArea'
import { useTranslate } from '../../../../services/i18n'

const DEFAULT_EVALUATION: Evaluation = 1

export const ConversationEvaluation = () => {
  const t = useTranslate()
  const { cleanConversationEvaluation } = useCleanConversationEvaluation()
  const { cleanWelcomeModal } = useCleanWelcomeModal()
  const conversationRankId = useStore(selectConversationRankId)

  const [everytingIsLoaded, setEverythingIsLoaded] = useState(false)

  const {
    data: conversation,
    isSuccess,
    isError,
  } = useConversationById({
    params: { id: conversationRankId },
    queryParams: { enabled: !!conversationRankId },
  })

  const userIds = conversation?.collaboratorId ? [conversation?.collaboratorId] : []
  const { data: users, isSuccess: isSuccesUsers } = useUsersMinimal({
    params: { ids: userIds },
    queryParams: { enabled: userIds.length > 0 },
  })

  useEffect(() => {
    if (isSuccess && isSuccesUsers) setEverythingIsLoaded(true)
  }, [isSuccess, isSuccesUsers])
  const promoterName = users?.data[0]?.name

  const [evaluation, setEvaluation] = useState<Evaluation>()
  const [evaluationComment, setEvaluationComment] = useState('')
  const { mutate, isLoading: isLoadingConversationEvaluation } = usePostConversationEvaluate({
    onSuccess: () => {
      cleanWelcomeModal({
        closeModalId: 'conversationEvaluation',
        invalidateQueries: ['customer-configuration'],
      })
    },
  })

  if (!conversation || isError) {
    cleanConversationEvaluation()
    return null
  }

  const { subject, id } = conversation

  const onSubmit = () => {
    if (evaluation) mutate({ id, evaluation, evaluationComment })
    cleanConversationEvaluation()
  }

  const renderChoice = ({ evaluation, icon }: { evaluation: Evaluation; icon: ReactElement }) => (
    <Box cursor="pointer" onClick={() => setEvaluation(evaluation)}>
      {icon}
    </Box>
  )

  return (
    <Stack spacing="30px" p="40px" justifyContent="space-between">
      <Flex justifyContent="space-between">
        <Text fontWeight="bold" fontSize="16px">
          {t('conversationEvaluation.title')}
        </Text>
        <StepIndex total={2} index={evaluation ? 2 : 1} />
      </Flex>
      <Skeleton isLoaded={everytingIsLoaded}>
        <Text>
          {evaluation
            ? t('conversationEvaluation.commentTitle')
            : t('conversationEvaluation.subtitle', { subject, promoterName })}
        </Text>
      </Skeleton>

      {!evaluation ? (
        <Flex alignItems="center" justifyContent="space-evenly">
          {renderChoice({ evaluation: 1, icon: <Evaluation1 /> })}
          {renderChoice({ evaluation: 2, icon: <Evaluation2 /> })}
          {renderChoice({ evaluation: 3, icon: <Evaluation3 /> })}
        </Flex>
      ) : (
        <VStack spacing="30px" alignItems="flex-end">
          <TextArea
            placeholder={t('conversationEvaluation.commentPlaceholder')}
            value={evaluationComment}
            rows={5}
            onChange={(e) => setEvaluationComment(e.target.value)}
          />
          <Button
            disabled={isLoadingConversationEvaluation}
            isLoading={isLoadingConversationEvaluation}
            onClick={onSubmit}
            title={t('send')}
          />
        </VStack>
      )}
    </Stack>
  )
}
