import { useMe, useMeExtended } from '@happywait/hw-lib-front.core.api'
import { Loader } from 'components/Loader/Loader'
import { useFirstTokenLoginRedirection, useLoginRedirection } from 'hooks/useLoginRedirection'
import { Login } from 'modules/login/screens/Login/Login'
import { ReactElement, Suspense, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { useStore } from 'store'

import { selectToken } from 'store/selectors'

// Check if the user is logged in and has access to the module via the useModules hook
// If not, redirect to the login page
// If yes, render the module
// If the user does not have access to the module, the module will not be rendered (what should we do in this case?)
export const ProtectedRoute = ({ children }: { children?: ReactElement }) => {
  const token = useStore(selectToken)
  const { data: meData } = useMe({ queryParams: { enabled: !!token } })
  const { data: meExtendedData } = useMeExtended({
    queryParams: { enabled: !!token },
  })

  const loginRedirect = useLoginRedirection()
  useEffect(() => {
    if (meData && meExtendedData) {
      loginRedirect(
        { isObserver: meData.isObserver, temporary: meData.temporary },
        meExtendedData.cguValidated,
        meExtendedData.onboardingCompleted
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meData?.id, meExtendedData?.cguValidated, meExtendedData?.onboardingCompleted])

  const loginFirstTokenRedirect = useFirstTokenLoginRedirection()
  useEffect(() => {
    if (meData) {
      loginFirstTokenRedirect({ isObserver: meData.isObserver, onlyInvitation: meData.onlyInvitation })
    }
    // Run only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meData?.id])

  if (!token) {
    return <Login />
  }
  if (!children) {
    return (
      <Suspense fallback={<Loader />}>
        <Outlet />
      </Suspense>
    )
  }
  return children
}
