import { Icon, IconProps } from '@chakra-ui/react'

export const SnagSvg = (props: IconProps) => (
  <Icon
    viewBox="0 0 23 23"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icon/24/reserves-" transform="translate(-1.000000, -1.000000)" fill="currentColor" fillRule="nonzero">
        <g id="pencil-circle" transform="translate(1.192308, 1.192308)">
          <path
            d="M11.3076923,0 C17.5527583,0 22.6153846,5.06262629 22.6153846,11.3076923 C22.6153846,17.5527583 17.5527583,22.6153846 11.3076923,22.6153846 C5.06262629,22.6153846 0,17.5527583 0,11.3076923 C0,5.06262629 5.06262629,0 11.3076923,0 Z M11.3076923,1.61538462 C5.95477858,1.61538462 1.61538462,5.95477858 1.61538462,11.3076923 C1.61538462,16.660606 5.95477858,21 11.3076923,21 C16.660606,21 21,16.660606 21,11.3076923 C21,5.95477858 16.660606,1.61538462 11.3076923,1.61538462 Z M12.7199009,5.78243427 C13.8554304,4.64690477 15.6964875,4.64690477 16.832017,5.78243427 C17.9675465,6.91796377 17.9675465,8.75902085 16.832017,9.89455035 L16.832017,9.89455035 L9.77508368,16.9514837 C9.65154615,17.0750212 9.49128257,17.1551719 9.31833469,17.179912 L9.31833469,17.179912 L5.85380136,17.675512 C5.32074086,17.7517661 4.86374488,17.294908 4.93983813,16.7618245 L4.93983813,16.7618245 L5.4345048,13.2963578 C5.45920452,13.12332 5.53937112,12.9629641 5.6629676,12.8393676 L5.6629676,12.8393676 Z M15.6897676,6.92468368 C15.185085,6.42000111 14.3668329,6.42000111 13.8621503,6.92468368 L13.8621503,6.92468368 L6.99569231,13.79 L6.68984615,15.9233846 L8.82430769,15.6175385 L15.6897676,8.75230093 C16.1608047,8.28126387 16.1922071,7.53707289 15.783975,7.0296153 L15.783975,7.0296153 Z"
            id="Combined-Shape"
          ></path>
        </g>
      </g>
    </g>
  </Icon>
)
