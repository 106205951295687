import { Heading } from '@chakra-ui/layout'
import { Divider, Grid, HStack, VStack } from '@chakra-ui/react'
import { NeoCivility } from '@happywait/hw-lib-front.core.api'
import { useCountries } from '@happywait/hw-lib-front.core.api/legacy'
import { FormAutocompleteSelectInputControlled } from 'components/FormInputs/FormAutocompleteSelectInput/FormAutocompleteInputControlled'
import { InvitationFormWrapper } from 'components/InvitationForm/InvitationFormWrapper'
import { useFormatCurrencySymbol } from 'hooks/useCurrencySymbol'
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useOutletContext } from 'react-router-dom'
import { useTranslate } from 'services/i18n'
import { PhoneNumberInputControlled } from '../../../../design-system/PhoneNumberInput/PhoneNumberInputControlled'
import { _locale } from '../../../../services/locale'
import { _validator } from '../../../../services/validator'
import { useStore } from '../../../../store'
import { selectLocale, selectSetLocale } from '../../../../store/selectors'
import { EmailTextInput } from '../../../EmailTextInput/EmailTextInput'
import { FormSelectInputControlled } from '../../../FormInputs/FormSelectInput/FormSelectInputControlled'
import { FormTextInputControlled } from '../../../FormInputs/FormTextInput/FormTextInputControlled'
import { useCivilityOptions } from '../../../GeneralInformationForm/GeneralInformationForm.services'
import { InvitationOutletContextProps, useInvitationContext } from '../../InvitationForm.service'
import { FormatMode, InformationFormValues, informationFormServices as services } from './InformationForm.services'

const { getSchema, getDefaultValues, formatterValues, tPrefix } = services

export const InformationForm = (): JSX.Element => {
  const { formData, setFormData, setInformationMode } = useInvitationContext()
  const { projectName, currencyCode, customer, next, isLoading, button }: InvitationOutletContextProps =
    useOutletContext()

  const currencySymbol = useFormatCurrencySymbol(currencyCode)
  const setLocale = useStore(selectSetLocale)
  const locale = useStore(selectLocale)
  const t = useTranslate()
  const [mode, setMode] = useState<FormatMode>('person')
  const [onChecking, setOnChecking] = useState(false)
  const civilityOptions = useCivilityOptions()
  const { data: countries = [], isLoading: isLoadingCountries } = useCountries({
    params: {
      page: 0,
      size: 300,
    },
  })
  const countryOptions = countries.map(({ id, countryName }) => ({
    id,
    label: countryName,
  }))

  const schema = getSchema(mode)
  const methods = useForm<InformationFormValues>({
    context: undefined,
    criteriaMode: undefined,
    delayError: 0,
    shouldFocusError: false,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    defaultValues: getDefaultValues({ customer, formData, locale }),
    mode: 'onChange',
    resolver: _validator.resolver(schema, formatterValues(mode)),
    reValidateMode: 'onChange',
  })

  const saveData = (data: InformationFormValues) => {
    formData.information = data
    setFormData(formData)
    next()
  }

  const {
    watch,
    handleSubmit,
    trigger,
    formState: { errors },
  } = methods

  watch((data) => setFormData({ ...formData, information: data }))

  const currentCivility = watch('civility')
  const currentFormLocale = watch('lang')

  useEffect(() => {
    setMode(currentCivility === NeoCivility.corporation ? 'corporation' : 'person')
  }, [currentCivility])

  useEffect(() => {
    setInformationMode(mode)
  }, [mode, setInformationMode])

  useEffect(() => {
    if (currentFormLocale) {
      setLocale(currentFormLocale)
    }
  }, [currentFormLocale, setLocale])

  useEffect(() => {
    trigger()
  }, [trigger, mode])

  return (
    <InvitationFormWrapper
      title={t('invForm.general.title')}
      subtitle={t('invForm.general.subtitle', { project: projectName })}
      onSubmit={handleSubmit(saveData)}
      button={button}
      isLoading={isLoading}
      isDisabled={Object.entries(errors).length > 0 || onChecking}
    >
      <FormProvider {...methods}>
        <VStack alignItems="stretch" spacing={8}>
          <Heading fontSize="16px">{t(`${tPrefix}.sections.identity`)}</Heading>
          <Grid gap={8} gridTemplateColumns={{ base: '1fr', lg: '1fr 1fr' }}>
            <HStack spacing={8} alignItems="flex-start">
              <FormSelectInputControlled
                wrapperStyle={{ maxW: 'max-content' }}
                title={t(`${tPrefix}.civility`)}
                name="civility"
                options={civilityOptions}
              />

              {/* No tertiary here because it causes bugs between lastname and companyName inputs (it shares value between them) */}
              {mode === 'person' && (
                <FormTextInputControlled wrapperStyle={{ flex: 1 }} name="lastname" title={t(`${tPrefix}.lastname`)} />
              )}
              {mode === 'corporation' && (
                <FormTextInputControlled
                  wrapperStyle={{ flex: 1 }}
                  name="status"
                  title={t(`${tPrefix}.corporationStatus`)}
                />
              )}
            </HStack>
            {mode === 'person' && (
              <FormTextInputControlled wrapperStyle={{ flex: 1 }} name="firstname" title={t(`${tPrefix}.firstname`)} />
            )}
            {mode === 'corporation' && (
              <>
                <FormTextInputControlled
                  wrapperStyle={{ flex: 1 }}
                  name="lastname"
                  title={t(`${tPrefix}.corporationName`)}
                />
                <FormTextInputControlled
                  name="legalRepresentativeLastname"
                  title={t(`${tPrefix}.legalRepresentativeLastname`)}
                />
                <FormTextInputControlled
                  name="legalRepresentativeFirstname"
                  title={t(`${tPrefix}.legalRepresentativeFirstname`)}
                />
                <FormTextInputControlled
                  name="capital"
                  title={t(`${tPrefix}.capital`)}
                  type="number"
                  rightAddon={currencySymbol}
                />
                <FormTextInputControlled name="sirenNumber" title={t(`${tPrefix}.sirenNumber`)} />
                <FormTextInputControlled name="rcsRegistrationPlace" title={t(`${tPrefix}.rcsRegistrationPlace`)} />
                <FormTextInputControlled name="rcsNumber" title={t(`${tPrefix}.rcsNumber`)} />
              </>
            )}
            {mode === 'person' && (
              <>
                <FormTextInputControlled name="nationality" title={t(`${tPrefix}.nationality`)} />
                <FormTextInputControlled name="function" title={t(`${tPrefix}.function`)} />
              </>
            )}
          </Grid>
          {mode === 'person' && (
            <>
              <Heading fontSize="16px">{t(`${tPrefix}.sections.birth`)}</Heading>
              <Grid gap={8} gridTemplateColumns={{ base: '1fr', lg: '1fr 1fr' }}>
                <FormTextInputControlled name="birthDate" title={t(`${tPrefix}.birthDate`)} type={'date'} />
                <FormTextInputControlled name="birthCity" title={t(`${tPrefix}.birthCity`)} />
              </Grid>
            </>
          )}
          <Heading fontSize="16px">{t(`${tPrefix}.sections.contactDetails`)}</Heading>
          <Grid gap={8} gridTemplateColumns={{ base: '1fr', lg: '1fr 1fr' }}>
            <FormTextInputControlled name="address" title={t(`${tPrefix}.address`)} />
            <FormTextInputControlled name="address2" title={t(`${tPrefix}.address2`)} />
            <FormTextInputControlled name="postCode" title={t(`${tPrefix}.postCode`)} />
            <FormTextInputControlled name="city" title={t(`${tPrefix}.city`)} />
            <FormAutocompleteSelectInputControlled
              name="country"
              title={t(`${tPrefix}.country`)}
              isLoading={isLoadingCountries}
              options={countryOptions}
            />

            <EmailTextInput
              name="email"
              title={t(`${tPrefix}.email`)}
              onValidating={setOnChecking}
              userId={customer?.id}
            />
            <PhoneNumberInputControlled
              name="phone"
              title={t(`${tPrefix}.phone`)}
              onValidating={setOnChecking}
              onChangeBefore={(nextValue) => (nextValue === undefined ? '' : nextValue)}
            />
            <PhoneNumberInputControlled
              name="cellphone"
              title={t(`${tPrefix}.cellphone`)}
              onValidating={setOnChecking}
              isCellphone={true}
              onChangeBefore={(nextValue) => (nextValue === undefined ? '' : nextValue)}
            />
          </Grid>
          <Divider pt={5} borderColor="gray.300" />
          <Grid gap={8} gridTemplateColumns={{ base: '1fr', lg: '1fr 1fr' }}>
            {_locale.isShowableLocalSelector() && (
              <>
                <FormSelectInputControlled
                  name="lang"
                  title={t(`${tPrefix}.lang`)}
                  options={_locale.getLocalSelectorOptions()}
                />
              </>
            )}
          </Grid>
        </VStack>
      </FormProvider>
    </InvitationFormWrapper>
  )
}
