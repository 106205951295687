import { useStore } from 'store'
import { selectHideModal, selectModal } from 'store/selectors'
import { ModalsStored } from 'store/types'

export const useModal = <ModalId extends keyof ModalsStored>(modalId: ModalId) => {
  const props = useStore(selectModal(modalId))
  const hide = useStore(selectHideModal)
  const hideModal = () => hide(modalId)
  const isVisible = props !== undefined

  return {
    hideModal,
    props,
    isVisible,
  }
}
