import { getBaseDev } from "./helpers"

export const fetchLoginPicture = 'image-login-picture'
export const fetchImageLoginPicture = async () => {
    return new Promise<string>((resolve, reject) => {
      const imageLoginPictureUrl = getBaseDev() + 'api/v3/promoters/login_picture'
      const img = new Image()
      img.src = imageLoginPictureUrl
      img.onload = () => resolve(imageLoginPictureUrl)
      img.onerror = reject
    })
  }
  