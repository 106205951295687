import { InputGroup, useDisclosure, Text, Input, InputRightElement } from '@chakra-ui/react'
import { EntityId } from '@happywait/hw-lib-front.core.api'
import { FormEvent, forwardRef, PropsWithChildren } from 'react'
import { SelectWrapper } from '../SelectWrapper/SelectWrapper'
import { SelectOptions } from '../Select/Select'
import { ChevronUp } from '../../assets/svg/ChevronUp'
import { ChevronDown } from '../../assets/svg/ChevronDown'
import { Loader } from '../../components/Loader/Loader'
import { theme } from '../../theme'

export type AutoCompleteProps = {
  id?: string
  disabled?: boolean
  error?: string
  isLoading?: boolean
  noOptionLabel?: string
  options: SelectOptions[]
  placeholder?: string
  filterSearch: string
  value: EntityId
  openOn?: EntityId
  setFilterSearch: (value: string) => void
  onChange: (value: EntityId) => void
}

export const AutoComplete = forwardRef<HTMLDivElement, PropsWithChildren<AutoCompleteProps>>(
  (
    {
      disabled,
      error,
      filterSearch,
      isLoading,
      noOptionLabel,
      options,
      placeholder,
      value,
      openOn,
      setFilterSearch,
      onChange,
      id,
    },
    scrollContainerRef
  ) => {
    const { isOpen, onClose, onOpen } = useDisclosure()
    const onChangeSearch = (value: FormEvent<HTMLInputElement>) => {
      setFilterSearch?.(value.currentTarget.value)
      onChange('')
      onOpen()
    }
    const currentOption = options.find(({ id }) => id?.toString() === value?.toString())
    const label = currentOption?.label || ''

    return (
      <>
        <SelectWrapper
          isLoading={isLoading}
          noOptionLabel={noOptionLabel}
          options={options}
          onChange={onChange}
          isOpen={isOpen}
          onClose={onClose}
          ref={scrollContainerRef}
          value={value}
          openOn={openOn}
          filterSearch={filterSearch}
        >
          <InputGroup>
            <Input
              id={id}
              disabled={disabled}
              onChange={onChangeSearch}
              onClick={onOpen}
              variant={error ? 'error' : 'filled'}
              onBlur={onClose}
              onFocus={onOpen}
              placeholder={placeholder}
              value={label ? label : filterSearch}
            />
            <InputRightElement children={isLoading ? <Loader height={24} /> : isOpen ? <ChevronUp /> : ChevronDown} />
          </InputGroup>
        </SelectWrapper>
        {error && (
          <Text color={theme.colors.error} mt={2} fontSize="1.2rem">
            {error}
          </Text>
        )}
      </>
    )
  }
)
