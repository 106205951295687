import { CustomerApi, NeoCivility } from '@happywait/hw-lib-front.core.api'
import { PatchUpdateUserParamsUser } from '@happywait/hw-lib-front.core.api/legacy'
import { InvitationForm } from 'components/InvitationForm/InvitationForm.service'
import { SelectOptions } from 'design-system/Select/Select'
import i18next from 'i18next'
import Joi from 'joi'
import { keys, omit, pick, toPairs } from 'remeda'
import { _date } from 'services/date'
import { useTranslate } from 'services/i18n'
import { _validator } from 'services/validator'

const tPrefix = 'forms.generalInformationForm'

export type FormatMode = 'person' | 'corporation'

export type InformationFormValues = Pick<
  PatchUpdateUserParamsUser,
  | 'civility'
  | 'lastname'
  | 'firstname'
  | 'legalRepresentativeLastname'
  | 'legalRepresentativeFirstname'
  | 'capital'
  | 'sirenNumber'
  | 'rcsRegistrationPlace'
  | 'rcsNumber'
  | 'cellphone'
  | 'phone'
  | 'birthDate'
  | 'birthCity'
  | 'email'
  | 'address'
  | 'address2'
  | 'postCode'
  | 'city'
  | 'country'
  | 'lang'
  | 'function'
> &
  Partial<{
    status: string
  }>

export const useCivilityOptions = (): SelectOptions[] => {
  const t = useTranslate()
  return toPairs(NeoCivility).map(([key, value]) => ({ id: value, label: t(`civilities.${key}`) }))
}

const shared = {
  civility: Joi.string().required(),
  lastname: Joi.string().required(),
  isLegalEntity: Joi.boolean().required(),
  email: _validator.joiEmailValidation,
  cellphone: Joi.string().allow('').custom(_validator.joiPhoneNumberValidation),
  phone: Joi.string().allow('').custom(_validator.joiPhoneNumberValidation),
  address: Joi.string().allow(null, ''),
  address2: Joi.string().allow(null, ''),
  postCode: Joi.string().allow(null, ''),
  city: Joi.string().allow(null, ''),
  country: Joi.number().allow(null, ''),
  lang: Joi.string().custom(_validator.joiTypeLocaleValidation),
}

const fields = {
  person: {
    firstname: Joi.string().allow(''),
    function: Joi.string().allow(null, ''),
    nationality: Joi.string().allow(null, ''),
    birthCity: Joi.string().allow(null, ''),
    birthDate: Joi.string().allow(null, ''),
  },
  corporation: {
    status: Joi.string().required(),
    legalRepresentativeLastname: Joi.string().allow(''),
    legalRepresentativeFirstname: Joi.string().allow(''),
    capital: Joi.number().allow(null, ''),
    sirenNumber: Joi.string()
      .pattern(/^\d+(\s\d+)*$/) // allow whitespaces between numbers (123 123, 123123)
      .allow(''),
    rcsRegistrationPlace: Joi.string().allow(''),
    rcsNumber: Joi.string().allow(''),
  },
}

const schemas: Record<FormatMode, Joi.ObjectSchema<InformationFormValues>> = {
  person: Joi.object<InformationFormValues>({
    ...shared,
    ...fields.person,
  }),

  corporation: Joi.object<InformationFormValues>({
    ...shared,
    ...fields.corporation,
  }),
}

const getSchema = (mode: FormatMode) => schemas[mode]

const omitMap: Record<FormatMode, any> = {
  corporation: keys(fields.person),
  person: keys(fields.corporation),
}

// This function only returns necessary form values
const formatterValues = (mode: FormatMode) => (values: InformationFormValues) => {
  const nextValues: InformationFormValues = {}
  for (const key in values) {
    if ((key === 'capital' || key === 'country') && values[key] === '') {
      nextValues[key] = null
      continue
    }

    nextValues[key] = values[key]
  }

  nextValues['isLegalEntity'] = mode === 'corporation'

  return omit(nextValues, omitMap[mode])
}

const getDefaultValues = ({
  customer,
  formData,
  locale,
}: {
  customer?: CustomerApi
  formData?: InvitationForm
  locale: string | null
}): {} => {
  const isCorporation = customer && customer.isLegalEntity

  const defaultCustomerValues = {
    civility: isCorporation ? NeoCivility.corporation : customer?.civility || '',
    lastname: isCorporation ? customer?.companyName : customer?.lastname,
    firstname: customer?.firstname || '',
    function: customer?.function || '',
    email: customer?.email || '',
    cellphone: customer?.cellphone || '',
    phone: customer?.phone || '',
    address: customer?.address || '',
    address2: customer?.address2 || '',
    postCode: customer?.postCode || '',
    city: customer?.city || '',
    country: customer?.country || '',
    birthCity: customer?.birthCity || '',
    birthDate: customer?.birthDate ? _date.format(_date.formatters.input)(customer?.birthDate) : '',
    status: customer?.status || i18next.t('forms.generalInformationForm.company') || '',
    legalRepresentativeLastname: customer?.legalRepresentativeLastname || '',
    legalRepresentativeFirstname: customer?.legalRepresentativeFirstname || '',
    capital: customer?.capital || '',
    sirenNumber: customer?.sirenNumber || '',
    rcsRegistrationPlace: customer?.rcsRegistrationPlace || '',
    rcsNumber: customer?.rcsNumber || '',
    nationality: customer?.nationality || '',
    lang: locale,
  }

  return pick(
    { ...defaultCustomerValues, ...formData?.information },
    keys(defaultCustomerValues) as (keyof InformationFormValues)[]
  )
}

export const informationFormServices = {
  getSchema,
  tPrefix,
  formatterValues,
  getDefaultValues,
}
