import { useSpring } from 'react-spring'
import { ChevronDown } from '../../assets/svg/ChevronDown'
import { theme } from '../../theme'
import { SwitchChevronStyles as Styles } from './SwitchChevron.styles'

export type SwitchChevronProps = {
  active?: boolean
  onClick?: () => void
  backgroundColor?: string
  color?: string
  direction?: 'left' | 'right'
  inverted?: boolean
}

export const SwitchChevron = ({
  active = false,
  onClick,
  backgroundColor = theme.colors.grey,
  color = theme.colors.black,
  direction = 'right',
  inverted = false,
}: SwitchChevronProps) => {
  const chevronStyle = useSpring({
    transform:
      (!inverted && active) || (inverted && !active)
        ? `rotate(${direction === 'right' ? '-' : ''}180deg)`
        : 'rotate(0deg)',
    config: {
      duration: 150,
    },
  })

  return (
    <Styles.Container color={color} backgroundColor={backgroundColor} onClick={onClick}>
      <Styles.Image style={chevronStyle}>{ChevronDown}</Styles.Image>
    </Styles.Container>
  )
}
