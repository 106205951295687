import { Locale } from '@happywait/hw-lib-front.core.api'
import { defaultLocale, localeToShortLocale } from 'const'
import dayjs, { ConfigType, Dayjs, OptionType } from 'dayjs'
import 'dayjs/locale/es'
import 'dayjs/locale/fr'
import 'dayjs/locale/pt'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import duration from 'dayjs/plugin/duration'
import minMax from 'dayjs/plugin/minMax'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
import relativeTime from 'dayjs/plugin/relativeTime'
import updateLocale from 'dayjs/plugin/updateLocale'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import { registerLocale } from 'react-datepicker'
import { ShortLocale } from 'types/locale'
import { localeFr } from './custom-fns'
import { formatters } from './formatters'

//@ts-ignore
registerLocale('fr', localeFr)

const init = (lng: ShortLocale = localeToShortLocale[defaultLocale]) => {
  dayjs.extend(customParseFormat)
  dayjs.extend(updateLocale)
  dayjs.extend(quarterOfYear)
  dayjs.extend(weekOfYear)
  dayjs.extend(minMax)
  dayjs.extend(relativeTime)
  dayjs.extend(duration)

  dayjs.locale(lng)
}

const changeLocale = (lng: ShortLocale) => dayjs.locale(lng)

const from = (date?: ConfigType, format?: OptionType) => dayjs(date, format)

const format =
  (
    formatter: (date: Date, followTimezone: boolean, locale?: Locale) => string,
    followTimezone: boolean = false,
    locale?: Locale
  ) =>
  (date: ConfigType): string =>
    formatter(dayjs(date).toDate(), followTimezone, locale)

/**
 * @deprecated Keep for legacy purpose but use formatV2 preferentially
 */
const formatLegacy = (template: string) => (date: ConfigType) => from(date).format(template)
const getYear = (date?: ConfigType) => (date ? from(date).year() : dayjs().year())
const getQuarter = (date: ConfigType) => from(date).quarter()
const getWeek = (date: ConfigType) => from(date).week()
const getMin = (dates: Dayjs[]) => dayjs.min(dates)
const getRemainingTime = (dateFrom: Dayjs, dateTo: Dayjs) => dateFrom.from(dateTo)
const today = () => dayjs()

const getTimeOfDay = () => {
  const hour = from().hour()
  const minute = from().minute()
  if (hour >= 5 && hour <= 11 && minute <= 59) return 'morning'
  if (hour >= 12 && hour <= 16 && minute <= 59) return 'afternoon'
  return 'evening'
}

const recreateDate = ({ date, hourStart }: { date: string; hourStart: string }): string => {
  const formattedDate = formatLegacy('YYYY-MM-DD')(date)
  const hour = `T${hourStart}:00`

  return formattedDate.concat(hour)
}

export const _date = {
  init,
  from,
  format,
  formatLegacy,
  getYear,
  getQuarter,
  getWeek,
  getMin,
  getRemainingTime,
  getTimeOfDay,
  recreateDate,
  today,
  changeLocale,
  formatters,
}
