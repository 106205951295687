import { Conversation, Me, SaleMinimalWithInvestmentType } from '@happywait/hw-lib-front.core.api'
import { PromoterConfiguration } from '@happywait/hw-lib-front.core.api/v1'
import { ModalsStored } from 'store/types'

export type NewValuesProps = {
  promoterConfiguration?: PromoterConfiguration
  currentSale?: SaleMinimalWithInvestmentType
  me?: Me
  consumerConfiguration?: Partial<{ meantimeColdCalling: boolean | null; dailyMailing: boolean }>
  currentConsumerConfiguration?: Partial<{
    hasMeantimeAccess: boolean
  }>
  isConversationRankId: boolean
  currentConversation?: Conversation
  modalIdHistory: (keyof ModalsStored)[]
}

export const isTypologieSurveyAccessible = (
  promoterConfiguration?: PromoterConfiguration,
  currentSale?: SaleMinimalWithInvestmentType,
  currentUser?: Me
): boolean => {
  return (
    !!promoterConfiguration?.askAcquisitionTypology &&
    !!currentSale &&
    !currentSale?.investmentType &&
    !!(currentSale?.mainCustomer === currentUser?.id)
  )
}

//@TODO Uncomment when Meantime will be activated again.
// export const isMeantimeContactSurveyAccessible = (
//   consumerConfiguration?: Partial<{
//     meantimeColdCalling: boolean | null
//     dailyMailing: boolean
//   }>,
//   currentConsumerConfiguration?: Partial<{
//     hasMeantimeAccess: boolean
//   }>
// ): boolean => consumerConfiguration?.meantimeColdCalling === null && !!currentConsumerConfiguration?.hasMeantimeAccess

export const isConversationEvaluationAccessible = (
  isConversationRankId: boolean,
  currentConversation: Conversation | undefined,
  modalIdHistory: (keyof ModalsStored)[]
): boolean => isConversationRankId && !currentConversation?.evaluation

//@TODO Uncomment Meantime related values when it will be activated again.
export const setNewValues = ({
  promoterConfiguration,
  currentSale,
  me,
  isConversationRankId,
  currentConversation,
  modalIdHistory,
}: NewValuesProps): {
  unitTypologySurvey: boolean
  // meantimeContactSurvey: boolean
  conversationEvaluation: boolean
} => ({
  unitTypologySurvey: isTypologieSurveyAccessible(promoterConfiguration, currentSale, me),
  // meantimeContactSurvey: isMeantimeContactSurveyAccessible(consumerConfiguration, currentConsumerConfiguration),
  conversationEvaluation: isConversationEvaluationAccessible(isConversationRankId, currentConversation, modalIdHistory),
})
