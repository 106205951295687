import { useEffect, useState } from 'react'

export const useMediaQuery = (breakpoint: string) => {
  const [matches, setMatches] = useState(window.matchMedia(breakpoint).matches)

  useEffect(() => {
    const mediaQuery = window.matchMedia(breakpoint)

    const onChange = (e: MediaQueryListEvent) => {
      setMatches(e.matches)
    }

    try {
      mediaQuery.addEventListener('change', onChange)
    } catch {
      try {
        mediaQuery.addListener(onChange)
      } catch {
        return
      }
    }

    return () => {
      try {
        mediaQuery.removeEventListener('change', onChange)
      } catch {
        try {
          mediaQuery.removeListener(onChange)
        } catch {
          return
        }
      }
    }
  }, [breakpoint])

  return matches
}
