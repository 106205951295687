import { ModalsStored, Store } from '../types'

export const selectProjectId = (state: Store) => state.projectId

export const selectUnitId = (state: Store) => state.unitId

export const selectSaleId = (state: Store) => state.saleId

export const selectToken = (state: Store) => state.token

export const selectModules = (state: Store) => state.modules

export const selectLoginPicture = (state: Store) => state.loginPicture

export const selectConversations = (state: Store) => state.conversations

export const selectMessages = (state: Store) => state.messages

export const selectMarkedDocumentIds = (state: Store) => state.markedDocumentIds

export const selectReadDocumentIds = (state: Store) => state.readDocumentIds

export const selectAddReadDocumentIds = (state: Store) => state.addReadDocumentIds

export const selectSetAcquisition = (state: Store) => state.setAcquisition

export const selectAddMarkedDocumentId = (state: Store) => state.addMarkedDocumentId

export const selectRemoveMarkedDocumentId = (state: Store) => state.removeMarkedDocumentId

export const selectSetMessages = (state: Store) => state.setMessages

export const selectAddMessage = (state: Store) => state.addMessage

export const selectSetConversations = (state: Store) => state.setConversations

export const selectAddConversation = (state: Store) => state.addConversation

export const selectSetToken = (state: Store) => state.setToken

export const selectSetModules = (state: Store) => state.setModules

export const selectSetLoginPicture = (state: Store) => state.setLoginPicture

export const selectReset = (state: Store) => state.reset

export const selectFirstConnection = (state: Store) => state.firstConnection

export const selectSetFirstConnection = (state: Store) => state.setFirstConnection

export const selectModal =
  <ModalId extends keyof ModalsStored>(modalId: ModalId) =>
  (state: Store) =>
    state.modals[modalId]

export const selectShowModal = (state: Store) => state.showModal

export const selectHideModal = (state: Store) => state.hideModal

export const selectResetModals = (state: Store) => state.resetModals

export const selectPrimoConnection = (state: Store) => state.primoConnection

export const selectSetPrimoConnection = (state: Store) => state.setPrimoConnection

export const selectModalIdsHistory = (state: Store) => state.modalIdsHistory

export const selectSetModalIdsHistory = (state: Store) => state.setModalHistory

export const selectSetLocale = (state: Store) => state.setLocale

export const selectLocale = (state: Store) => state.locale

export const selectSetInitialData = (state: Store) => state.setInitialData

export const selectInitialData = (state: Store) => state.initialData

export const selectConversationRankId = (state: Store) => state.conversationRankId

export const selectSetConversationRankId = (state: Store) => state.setConversationRankId

export const selectSetDefaultCurrencyCode = (state: Store) => state.setDefaultCurrencyCode

export const selectDefaultCurrencyCode = (state: Store) => state.defaultCurrencyCode

export const selectLogout = (state: Store) => state.logout
