import { Box } from '@chakra-ui/react'
import { Spinner, SpinnerType } from '../Spinner/Spinner'

export type LoaderModuleProps = {
  type?: SpinnerType
  color?: string
  height?: number
  width?: number
  flex?: string
}

export const Loader = (props: LoaderModuleProps) => {
  return (
    <Box display="flex" alignItems="center" justifyContent="center" flex={props.flex || '1 1 auto'} height="100%">
      <Spinner type="TailSpin" {...props} />
    </Box>
  )
}
