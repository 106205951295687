import styled from 'styled-components'

type ContainerStyleProps = {
  maxWidth: number
  margin: string
}

const Container = styled.div<ContainerStyleProps>`
  max-width: ${({ maxWidth }) => `${maxWidth}px`};
  ${({ margin }) => margin && `margin:${margin}`};
`

export const LottiePictureStyles = {
  Container,
}
