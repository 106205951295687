import { Unit } from '@happywait/hw-lib-front.core.api/legacy'

export const getAcquisitionTitle = ({
  customValues,
  batchType,
  name,
}: Pick<Unit, 'customValues' | 'batchType' | 'name'>): string => {
  const housingType = customValues.find(({ name }) => name === 'housingType')?.value
  const housingLabel = customValues.find(({ name }) => name === 'housingLabel')?.value

  if (!housingType || !housingLabel) {
    return batchType === name ? batchType : `${batchType} ${name ? name : ''}`
  }

  return `${housingType} ${name}${housingLabel && ` ● ${housingLabel}`}`
}
