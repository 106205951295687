import { isOfTypeLocale } from '@happywait/hw-lib-front.core.api'
import { joiResolver } from '@hookform/resolvers/joi'
import Joi, { ObjectSchema } from 'joi'
import { ResolverOptions, UnpackNestedValue } from 'react-hook-form'
import { isValidPhoneNumber } from 'react-phone-number-input'

const resolver =
  <T extends Record<string, any>>(
    schema: ObjectSchema<T>,
    formatterValues?: (values: UnpackNestedValue<T>) => UnpackNestedValue<T>
  ) =>
  (values: UnpackNestedValue<T>, context: object = {}, options: ResolverOptions<T>) => {
    const valuesFormatted = formatterValues ? formatterValues(values) : values
    return joiResolver<ObjectSchema<T>>(schema)(valuesFormatted, context, options)
  }

const strongPassword = Joi.string().pattern(/\d/).pattern(/[A-Z]/).pattern(/[a-z]/).pattern(/\W/).min(10)

const joiPhoneNumberValidation = (value: string, helpers: Joi.CustomHelpers<string>) => {
  if (isValidPhoneNumber(value)) {
    return value
  }

  return helpers.error('cellphoneFormat')
}

const joiEmailValidation = Joi.string().email({ tlds: { allow: false } })

const joiTypeLocaleValidation = (value: string, helpers: Joi.CustomHelpers<string>) => {
  if (isOfTypeLocale(value)) {
    return value
  }

  return helpers.error('lang')
}

export const _validator = {
  resolver,
  strongPassword,
  joiPhoneNumberValidation,
  joiEmailValidation,
  joiTypeLocaleValidation,
}
