import { Icon, IconProps } from '@chakra-ui/react'

export const WarningIcon = (props: IconProps) => (
  <Icon viewBox="0 0 24 24" {...props}>
    <g
      id="icon/24/warningicon"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="alert-triangle" transform="translate(2, 2)" fill="currentColor">
        <path
          d="M10.0001371,0 C10.8356134,0 11.5983714,0.475141311 11.9668,1.22519684 L19.799681,17.1820151 C20.0977111,17.7882866 20.0616798,18.5054517 19.7043842,19.0787988 C19.3470886,19.6521457 18.7191476,20.0004473 18.0440462,20 L1.95669057,20 C1.28112665,20.0004473 0.653185678,19.6521457 0.295890129,19.0787988 C-0.0614055165,18.5054517 -0.0974368029,17.7882866 0.200420145,17.1823677 L8.03355405,1.22503443 C8.40190286,0.475141311 9.1646609,0 10.0001371,0 Z M10.0001371,1.39534881 C9.69673223,1.39534881 9.41973508,1.56789735 9.2860482,1.84006017 L1.45282087,17.7975835 C1.36746542,17.9712189 1.37778473,18.1766141 1.48011376,18.34082 C1.58244282,18.5050259 1.76228454,18.604651 1.95622805,18.604651 L18.0445088,18.604651 C18.2379897,18.604651 18.4178315,18.5050259 18.5201605,18.34082 C18.6224895,18.1766141 18.6328089,17.9712189 18.5472802,17.797231 L10.7143058,1.84022258 C10.5805392,1.56789735 10.3035421,1.39534881 10.0001371,1.39534881 Z M10.0001371,14.883681 L10.1099432,14.8895944 C10.614858,14.9443077 11.0078923,15.3719729 11.0078923,15.8914362 C11.0078923,16.448004 10.556705,16.8991914 10.0001371,16.8991914 C9.44356932,16.8991914 8.99238195,16.448004 8.99238195,15.8914362 C8.99238195,15.3719729 9.38541628,14.9443077 9.89033111,14.8895944 L10.0001371,14.883681 Z M10.0001371,6.20141895 C10.3533425,6.20141895 10.645245,6.46388767 10.6914426,6.80442307 L10.6978115,6.89909335 L10.6978115,13.1007091 C10.6978115,13.4860241 10.3854521,13.7983835 10.0001371,13.7983835 C9.64693178,13.7983835 9.35502928,13.5359148 9.30883168,13.1953794 L9.30246274,13.1007091 L9.30246274,6.89909335 C9.30246274,6.51377842 9.61482221,6.20141895 10.0001371,6.20141895 Z"
          id="Combined-Shape"
        ></path>
      </g>
    </g>
  </Icon>
)
