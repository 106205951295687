import styled, { css, ThemedStyledProps } from 'styled-components'
import { DefaultTheme } from '../../theme'
import { bold14 } from '../Font/Font'

export type PictureSize = 'small' | 'medium' | 'large' | 'big' | number

type BaseCSSProps = {
  size: PictureSize
  border: boolean
}

const getHeightWidth = ({ size }: ThemedStyledProps<BaseCSSProps, DefaultTheme>) => {
  if (size === 'small') {
    return 24
  }
  if (size === 'medium') {
    return 32
  }
  if (size === 'large') {
    return 42
  }
  if (size === 'big') {
    return 68
  }
  return size
}

const baseCSS = css<BaseCSSProps>`
  border-radius: 50%;
  width: ${getHeightWidth}px;
  height: ${getHeightWidth}px;
  object-fit: cover;
  border: ${({ border }) => border && '1px solid white'};
`

const Container = styled.img`
  ${baseCSS};
`

const PictureLoading = styled.div`
  ${baseCSS};
  background-color: ${({ theme }) => theme.colors.grey20};
`

const Placeholder = styled.div`
  ${baseCSS};
  background-color: ${({ theme }) => theme.colors.grey20};
  ${bold14};
  display: flex;
  align-items: center;
  justify-content: center;
`

export const UserPictureStyles = {
  Container,
  PictureLoading,
  Placeholder,
}
