import { useTheme } from 'styled-components'

export const CloseSmall = () => {
  const theme = useTheme()

  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="icon-dev/24/close_sm-" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" opacity="0.598353795">
        <path
          d="M20,12 C20,16.4183 16.4182,20 12,20 C7.58179,20 4,16.4183 4,12 C4,7.58179 7.58179,4 12,4 C16.4182,4 20,7.58179 20,12 Z M14.55,15.6501 C14.8499,15.9501 15.3501,15.9501 15.6499,15.6501 C15.95,15.3501 15.95,14.8501 15.6499,14.55 L13.1001,12.00012 L15.6499,9.45007 C15.95,9.15015 15.95,8.65015 15.6499,8.3501 C15.3501,8.05017 14.8499,8.05017 14.55,8.3501 L12,10.90015 L9.44995,8.3501 C9.1499,8.05017 8.6499,8.05017 8.3501,8.3501 C8.05005,8.65015 8.05005,9.15015 8.3501,9.45007 L10.8999,12.00012 L8.3501,14.55 C8.05005,14.8501 8.05005,15.3501 8.3501,15.6501 C8.6499,15.9501 9.1499,15.9501 9.44995,15.6501 L12,13.1001 L14.55,15.6501 Z"
          id="Shape"
          fill={theme.colors.primary}
        ></path>
      </g>
    </svg>
  )
}
