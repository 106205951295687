import { useCheckCellphone, useCheckPhone } from '@happywait/hw-lib-front.core.api'
import { useDebounce } from 'hooks/useDebounce'
import { useErrorTooltip } from 'hooks/useErrorTooltip'
import { useErrorTranslate } from 'hooks/useErrorTranslate'
import { E164Number } from 'libphonenumber-js/core'
import { useEffect, useRef } from 'react'
import { useController, useFormContext } from 'react-hook-form'
import { useTranslate } from 'services/i18n'
import { PhoneNumberInput } from './PhoneNumberInput'

export type PhoneNumberInputControlledProps = {
  onChangeBefore?: (value: E164Number) => E164Number | string
  placeholder?: string
  disabled?: boolean
  isCellphone?: boolean
  title?: string
  name: string
  tErrorContext?: string
  dataTest?: string
  onValidating?: (_: boolean) => void
}

export const PhoneNumberInputControlled: React.FC<PhoneNumberInputControlledProps> = ({
  onChangeBefore,
  placeholder,
  disabled,
  title,
  name,
  tErrorContext,
  dataTest,
  onValidating,
  isCellphone = false,
}) => {
  const inputRef = useRef<HTMLInputElement>()
  const {
    field: { value, onChange },
    fieldState: { isDirty, error },
  } = useController({ name })

  const { setError, clearErrors, trigger } = useFormContext()
  const t = useTranslate()
  const tError = useErrorTranslate(tErrorContext)
  const errTooltip = useErrorTooltip(error)

  const errorTooltip = {
    'phone.invalid': isCellphone ? 'cellphone.invalid' : 'phone.invalid',
  }

  const debouncedPhone = useDebounce(isDirty && !error ? value : null, 700)

  const checkPhoneHook = isCellphone ? useCheckCellphone : useCheckPhone
  const { isLoading, data: dataCheckPhone } = checkPhoneHook({
    params: { phone: debouncedPhone },
    queryParams: { enabled: !!debouncedPhone && isDirty },
  })

  const isOnValidating = isDirty && (isLoading || debouncedPhone !== value)

  useEffect(() => {
    onValidating?.(isOnValidating)
  }, [isOnValidating, onValidating])

  useEffect(() => {
    if (dataCheckPhone?.isValid === false) {
      setError(name, { type: 'phone.invalid' })
    } else if (dataCheckPhone?.isValid === true) {
      clearErrors(name)
      trigger(name)
    }
  }, [dataCheckPhone, name, setError, clearErrors, trigger])

  useEffect(() => {
    if (inputRef.current && document.activeElement === inputRef.current) {
      const length = inputRef.current.value.length
      inputRef.current.setSelectionRange(length, length)
    }
  }, [value, isLoading])

  const handleChange = (nextValue: E164Number) => {
    const processedValue = onChangeBefore ? onChangeBefore(nextValue) : nextValue
    onChange(processedValue)
  }

  return (
    <PhoneNumberInput
      name={name}
      placeholder={placeholder}
      title={title}
      disabled={disabled}
      value={value as E164Number}
      onChange={handleChange}
      error={tError(error)}
      dataTest={dataTest}
      isLoading={isLoading}
      tooltip={isLoading ? t('errors.form.phone.onValidating') : null}
      errTooltip={errTooltip(errorTooltip)}
    />
  )
}
