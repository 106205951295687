import { lazy } from 'react'
import { ModuleName } from './types'
export * from './routes'
export * from './types'

// ModuleWrapperLazy is a wrapper for lazy loading modules
// It loads the routes of the module
// It returns the routes of the module
const ModuleWrapperLazy = (name: ModuleName) =>
  lazy(async () => {
    const {
      default: { routes },
    } = await import(`./${name}`)

    return { default: routes }
  })

// List of all modules
export const HomeModuleLazy = ModuleWrapperLazy('home')
export const PicturesModuleLazy = ModuleWrapperLazy('pictures')
export const AccountModuleLazy = ModuleWrapperLazy('account')
export const DocumentsModuleLazy = ModuleWrapperLazy('documents')
export const ServicesModuleLazy = ModuleWrapperLazy('services')
export const MessagingModuleLazy = ModuleWrapperLazy('messaging')
export const FundingAppealModuleLazy = ModuleWrapperLazy('funding-appeal')
export const ChoicesOptionsModuleLazy = ModuleWrapperLazy('choices-options')
export const SnagsModuleLazy = ModuleWrapperLazy('snags')
export const PersonalizationModuleLazy = ModuleWrapperLazy('personalization')
export const ChecklistModuleLazy = ModuleWrapperLazy('checklist')
export const AcquisitionModuleLazy = ModuleWrapperLazy('acquisition')
export const LoginModuleLazy = ModuleWrapperLazy('login')
export const HousingLogbookModuleLazy = ModuleWrapperLazy('housing-logbook')
