export const Evaluation2 = () => {
  return (
    <svg width={76} height={75} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M37.529 0C16.858 0 .042 16.816.042 37.486c0 20.671 16.816 37.487 37.487 37.487 20.67 0 37.486-16.816 37.486-37.487C75.015 16.816 58.199 0 37.529 0zM23.47 21.867a4.691 4.691 0 014.686 4.686 4.691 4.691 0 01-4.686 4.686 4.691 4.691 0 01-4.686-4.686 4.692 4.692 0 014.686-4.686zM54.71 53.106H20.347a1.563 1.563 0 010-3.124H54.71a1.563 1.563 0 010 3.124zm-3.124-21.867a4.691 4.691 0 01-4.686-4.686 4.691 4.691 0 014.686-4.686 4.691 4.691 0 014.686 4.686 4.691 4.691 0 01-4.686 4.686z"
        fill="#F3961E"
        fillRule="nonzero"
        opacity={0.8}
      />
    </svg>
  )
}
