export const Back = (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="icon/24/back-copy" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M21.21875,11.0625 L4.67351562,11.0625 L7.41429687,8.335 C7.72011719,8.030625 7.72128906,7.53597656 7.41691406,7.23015625 C7.11253906,6.92429688 6.61785156,6.92316406 6.31203125,7.2275 L2.22984375,11.29 C2.22957031,11.2902344 2.229375,11.2905078 2.22914062,11.2907422 C1.92410156,11.5951172 1.923125,12.0913672 2.2290625,12.3967578 C2.22933594,12.3969922 2.22953125,12.3972656 2.22976562,12.3975 L6.31195312,16.46 C6.61773437,16.7642969 7.11242187,16.7632422 7.41683594,16.4573438 C7.72121094,16.1515234 7.72003906,15.656875 7.41421875,15.3525 L4.67351562,12.625 L21.21875,12.625 C21.6502344,12.625 22,12.2752344 22,11.84375 C22,11.4122656 21.6502344,11.0625 21.21875,11.0625 Z"
        id="Path"
        fill="#2E3844"
        fillRule="nonzero"
      ></path>
    </g>
  </svg>
)
