import { Box } from '@chakra-ui/react'
import { EntityId } from '@happywait/hw-lib-front.core.api'
import { forwardRef, PropsWithChildren, RefObject, useEffect, useRef, useState } from 'react'
import { ListOptions } from '../ListOptions/ListOptions'
import { SelectOptions } from '../Select/Select'

export type SelectWrapperProps = {
  isLoading?: boolean
  isScrollLoading?: boolean
  noOptionLabel?: string
  options: SelectOptions[]
  isOpen: boolean
  value: EntityId
  openOn?: EntityId
  onChange: (id: EntityId) => void
  onClose: () => void
  onScroll?: (e: React.UIEvent<HTMLElement>) => void
  filterSearch?: string
}

const MAX_HEIGHT_OPTIONS = 200

export const SelectWrapper = forwardRef<HTMLDivElement, PropsWithChildren<SelectWrapperProps>>(
  (
    {
      children,
      isLoading,
      isScrollLoading,
      options,
      noOptionLabel,
      isOpen,
      value,
      openOn,
      onChange,
      onClose,
      onScroll,
      filterSearch,
    },
    scrollContainerRef
  ) => {
    const [isReversed, setIsReversed] = useState(false)
    const ref = useRef<HTMLDivElement>(null)

    useEffect(() => {
      const referer = scrollContainerRef as RefObject<HTMLDivElement | null>

      const checkAvailableSpace = () => {
        if (ref.current) {
          const buttonBottom = ref.current.getBoundingClientRect().bottom
          const availableSpace = window.innerHeight - buttonBottom

          setIsReversed(MAX_HEIGHT_OPTIONS > availableSpace)
        }
      }

      checkAvailableSpace()

      referer?.current?.addEventListener('scroll', checkAvailableSpace, {
        passive: true,
      })

      return () => referer?.current?.removeEventListener('scroll', checkAvailableSpace)
    }, [scrollContainerRef])

    const onSelectOption = (id: EntityId) => {
      onChange(id)
      onClose()
    }

    return (
      <Box ref={ref} position="relative">
        {children}
        {ref.current && (
          <ListOptions
            boxHeight={MAX_HEIGHT_OPTIONS}
            isLoading={isLoading}
            isScrollLoading={isScrollLoading}
            isOpen={isOpen}
            isReversed={isReversed}
            noOptionLabel={noOptionLabel}
            onSelectOption={onSelectOption}
            options={options}
            refHeight={ref.current?.clientHeight}
            value={value}
            openOn={openOn}
            onScroll={onScroll}
            filterSearch={filterSearch}
          />
        )}
      </Box>
    )
  }
)
