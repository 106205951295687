import { animated } from 'react-spring'
import styled from 'styled-components'

type ContainerStyleProps = {
  height: number
  backgroundColor: string
}

type LineStyleProps = {
  barcolor: string
}

const Line = styled(animated.div)<LineStyleProps>`
  background-color: ${({ barcolor }) => barcolor};
  display: flex;
  align-items: center;
  justify-content: center;
`

const Container = styled.div<ContainerStyleProps>`
  width: 100%;
  background-color: ${({ backgroundColor }) => backgroundColor};
  height: ${({ height }) => `${height}rem`};
  border-radius: ${({ height }) => `${height}rem`};
  overflow: hidden;
  ${Line} {
    height: ${({ height }) => `${height}rem`};
    border-radius: ${({ height }) => `${height}rem`};
  }
`

export const ProgressBarStyles = {
  Container,
  Line,
}
