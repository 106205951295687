export const when = <T>(condition: boolean, valueToReturn: T) => {
  if (condition) {
    return valueToReturn
  }
}

export const doWhen = <T>(condition: boolean, funcToDo: () => T): T | undefined => {
  if (condition) {
    return funcToDo()
  }
}

/**
 * Been forced to create a multiline function that could have been done
 * on a single line just to cache the result of an operation ?
 * With makeWith, no more!
 * @param toCache operation requiring "cache"
 * @param makeFunc Your now single line function
 * @returns The result of makeFunc
 */
export const makeWith = <T, R>(toCache: T, makeFunc: (_: T) => R): R => makeFunc(toCache)

export const getBaseDev = () => {
  if (process.env.NODE_ENV === 'development') {
    return window.baseDev
  }

  return window.location.origin + '/'
}
