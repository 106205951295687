export const ChevronDown = (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="icon/24/chevron_down-copy-2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M5.84464518,8.62519465 C6.11089932,8.35891567 6.52756187,8.33469018 6.82118353,8.55253095 L6.90530535,8.62514518 L11.999,13.719 L17.0946947,8.62514518 C17.3609736,8.35889104 17.7776384,8.33470442 18.0712398,8.55257257 L18.1553548,8.62519465 C18.421609,8.89147363 18.4457956,9.30813844 18.2279274,9.60173978 L18.1553053,9.68585482 L12.7957076,15.0449523 C12.5847771,15.2561834 12.2985134,15.3748772 12,15.3748772 C11.7441313,15.3748772 11.4972625,15.2876736 11.2993728,15.1299916 L11.2046947,15.0453548 L5.84469465,9.68585482 C5.55178777,9.39297527 5.55176562,8.91810153 5.84464518,8.62519465 Z"
        id="Path"
        fill="#2E3844"
        fillRule="nonzero"
      ></path>
    </g>
  </svg>
)
