type ChevronUpParams = { iconColor?: string }

export const ChevronUp = ({ iconColor = '#2E3844' }: ChevronUpParams) => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="icon/24/chevron_up-copy" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M6.21964518,9.21969465 C6.48589932,8.95341567 6.90256187,8.92919018 7.19618353,9.14703095 L7.28030535,9.21964518 L12.374,14.3135 L17.4696947,9.21964518 C17.7359736,8.95339104 18.1526384,8.92920442 18.4462398,9.14707257 L18.5303548,9.21969465 C18.796609,9.48597363 18.8207956,9.90263844 18.6029274,10.1962398 L18.5303053,10.2803548 L13.1707076,15.6394523 C12.9597771,15.8506834 12.6735134,15.9693772 12.375,15.9693772 C12.1191313,15.9693772 11.8722625,15.8821736 11.6743728,15.7244916 L11.5796947,15.6398548 L6.21969465,10.2803548 C5.92678777,9.98747527 5.92676562,9.51260153 6.21964518,9.21969465 Z"
        id="Path"
        fill={iconColor}
        fillRule="nonzero"
        transform="translate(12.375000, 12.484689) scale(1, -1) translate(-12.375000, -12.484689) "
      ></path>
    </g>
  </svg>
)
