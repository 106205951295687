import { Select, SelectProps } from 'design-system/Select/Select'
import { useController } from 'react-hook-form'
import { ControlledInputWrapper, ControlledInputWrapperProps } from '../ControlledInputWrapper/ControlledInputWrapper'

export type FormSelectInputControlledProps = ControlledInputWrapperProps &
  Omit<SelectProps, 'onSelect' | 'value'> & { isLoading?: boolean; removeSelection?: boolean }

export const FormSelectInputControlled = ({
  name,
  title,
  options,
  wrapperStyle,
  isLoading,
  removeSelection,
  ...props
}: FormSelectInputControlledProps) => {
  const {
    field: { value, onChange },
  } = useController({ name })

  return (
    <ControlledInputWrapper name={name} title={title} wrapperStyle={wrapperStyle}>
      <Select
        options={options}
        value={value}
        onSelect={onChange}
        isLoading={isLoading}
        removeSelection={removeSelection}
        {...props}
      />
    </ControlledInputWrapper>
  )
}
