import { equals } from 'ramda'
import { useEffect } from 'react'
import { useModalStore } from 'store'
import { useInitWelcomeModal } from './useInitWelcomeModal'

export const useWelcomeModalWrapper = () => {
  const { newValues, newValuesRef, isLoading } = useInitWelcomeModal()

  const { shouldShowModal, setShouldShowModal } = useModalStore((state) => ({
    shouldShowModal: state.shouldShowModal,
    setShouldShowModal: state.setShouldShowModal,
  }))

  useEffect(() => {
    if (!equals(newValuesRef.current, newValues) || equals(shouldShowModal, {})) {
      newValuesRef.current = newValues
      setShouldShowModal(newValues)
    }
    if (!equals(shouldShowModal, newValues)) {
      setShouldShowModal(newValues)
    }
  }, [newValues, newValuesRef, setShouldShowModal, shouldShowModal])

  return { isLoading }
}
