import { useSalesMinimal } from '@happywait/hw-lib-front.core.api'
import { useStore } from 'store'
import { selectToken } from 'store/selectors'

export const useAccessibleSales = () => {
  const token = useStore(selectToken)
  const { data: customerAcqs, isLoading, isError } = useSalesMinimal({ queryParams: { enabled: !!token } })

  return {
    accessibleSales: customerAcqs?.data,
    isLoading,
    isError,
  }
}
