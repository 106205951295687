import { useModal } from 'hooks/useModal'
import { ReactNode } from 'react'
import { ModalsStored } from 'store/types'

export type ModalWrapperProps<T extends keyof ModalsStored> = {
  modalId: T
  children: (_: { props: ModalsStored[T]; hideModal: () => void }) => ReactNode
}

export const ModalWrapper = <T extends keyof ModalsStored>({ modalId, children }: ModalWrapperProps<T>) => {
  const { isVisible, ...props } = useModal(modalId)

  if (!isVisible) {
    return null
  }

  return <>{children(props)}</>
}
