import { useMe } from '@happywait/hw-lib-front.core.api'
import { defaultLocale } from 'const'
import { useEffect } from 'react'
import { _i18n } from 'services/i18n'
import { useStore } from '../store'
import { selectInitialData, selectLocale, selectSetLocale, selectToken } from '../store/selectors'

export const useLocale = () => {
  const initialData = useStore(selectInitialData)
  const token = useStore(selectToken)
  const locale = useStore(selectLocale)
  const setLocale = useStore(selectSetLocale)
  const { data: meData, isSuccess: isSuccessMe } = useMe({ queryParams: { enabled: !!token } })

  useEffect(() => {
    if (isSuccessMe && meData && !!meData.lang) {
      setLocale(meData.lang)
    }
  }, [isSuccessMe, meData, setLocale])

  useEffect(() => {
    if (!locale && initialData?.defaultLang) {
      setLocale(initialData?.defaultLang)
    }
  }, [initialData?.defaultLang, locale, setLocale])

  // Default useEffect, set the locale to the default language
  useEffect(() => {
    if (!locale) {
      _i18n.init(defaultLocale)
    } else {
      _i18n.init(locale)
    }
    // Only run once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (locale && _i18n.getLanguage() !== locale) _i18n.changeLanguage(locale)
  }, [locale])
}
