import { useMediaQuery, useToast } from '@chakra-ui/react'
import WelcomeModalStandaloneToast from '../WelcomeModalStandaloneToast'

export const useShowStandaloneToast = () => {
  const toast = useToast()

  const isLargerThan1280 = useMediaQuery('(min-width: 1280px)')
  return {
    showStandaloneToast: (variant: 'error' | 'success') =>
      toast({
        position: 'top-right',
        status: 'error',
        duration: 3000,
        variant: 'left-accent',
        containerStyle:
          (!isLargerThan1280 && {
            position: 'absolute',
            bottom: '80px',
          }) ||
          {},
        render: () => {
          return <WelcomeModalStandaloneToast variant={variant} />
        },
      }),
  }
}
