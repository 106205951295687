import { useMe } from '@happywait/hw-lib-front.core.api'
import { useUpdatePassword } from '@happywait/hw-lib-front.core.api/legacy'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { tests } from 'utils/tests'
import { EyeHidden } from '../../../../../assets/svg/EyeHidden'
import { EyeOpen } from '../../../../../assets/svg/EyeOpen'
import { Lock } from '../../../../../assets/svg/Lock'
import { Mail } from '../../../../../assets/svg/Mail'
import { Separator } from '../../../../../components/Separator/Separator'
import { Button } from '../../../../../design-system/Button/Button'
import { Font } from '../../../../../design-system/Font/Font'
import { TextInput } from '../../../../../design-system/TextInput/TextInput'
import { useTranslate } from '../../../../../services/i18n'
import { _validator } from '../../../../../services/validator'
import { OnboardingPasswordFormValues, onboardingServices } from '../Onboarding.services'

export type OnboardingStep1Props = {
  onSuccess: () => void
}

export const OnboardingStep1 = ({ onSuccess }: OnboardingStep1Props) => {
  const t = useTranslate()
  const [isNewPasswordVisible, setIsNewPasswordVisible] = useState(false)
  const [isNewPasswordConfirmationVisible, setIsNewPasswordConfirmationVisible] = useState(false)

  const { control, clearErrors, handleSubmit, setError, setValue } = useForm<OnboardingPasswordFormValues>({
    reValidateMode: 'onSubmit',
    defaultValues: {
      email: '',
      password: '',
      passwordConfirmation: '',
    },
    resolver: _validator.resolver(onboardingServices.getPasswordSchema(!!window?.strongPassword)),
  })

  const { mutate, isLoading } = useUpdatePassword({
    onSuccess,
    onError: ({ response }) => {
      if (response) {
        const { error } = response.data
        if (error === 'Password not strong enough') {
          setError('password', { type: 'api.strong' })
        }
      }
    },
  })

  const { data: me } = useMe()
  useEffect(() => {
    if (me?.email) {
      setValue('email', me?.email)
    }
  }, [me, setValue])

  if (!me) {
    return null
  }

  const onSubmit = ({ password, email }: OnboardingPasswordFormValues) => {
    mutate({ password, email })
  }

  return (
    <>
      <Font>{t('login:onboardingScreen.step1.info1')}</Font>
      <Font>{t('login:onboardingScreen.step1.info2')}</Font>
      <Separator height={36} />
      {!me?.email && (
        <Controller
          name="email"
          control={control}
          render={({ field: { value, onChange, name }, fieldState: { error } }) => (
            <TextInput
              iconLeft={<Mail />}
              value={value}
              error={t(`login:onboardingScreen.step1.errors.email.${error?.type}`, { defaultValue: '' })}
              onChange={onChange}
              onFocus={() => clearErrors(name)}
              placeholder={t('login:onboardingScreen.step1.email')}
              placeholderMode="animated"
              name={name}
            />
          )}
        />
      )}
      <Separator height={15} />
      <Controller
        name="password"
        control={control}
        render={({ field: { value, onChange, name }, fieldState: { error } }) => (
          <TextInput
            iconLeft={Lock}
            iconRight={value ? (isNewPasswordVisible ? EyeHidden : EyeOpen) : undefined}
            onClickIcon={() => setIsNewPasswordVisible((prev) => !prev)}
            type={isNewPasswordVisible ? 'text' : 'password'}
            value={value}
            error={t(`login:onboardingScreen.step1.errors.password.${error?.type}`, { defaultValue: '' })}
            onChange={onChange}
            onFocus={() => clearErrors(name)}
            placeholder={t('login:onboardingScreen.step1.password')}
            placeholderMode="animated"
            name={name}
            data-test={'onboarding-mdp-cPWH'}
          />
        )}
      />
      <Separator height={15} />
      <Controller
        name="passwordConfirmation"
        control={control}
        render={({ field: { value, onChange, name }, fieldState: { error } }) => (
          <TextInput
            iconLeft={Lock}
            iconRight={value ? (isNewPasswordConfirmationVisible ? EyeHidden : EyeOpen) : undefined}
            onClickIcon={() => setIsNewPasswordConfirmationVisible((prev) => !prev)}
            type={isNewPasswordConfirmationVisible ? 'text' : 'password'}
            value={value}
            error={t(`login:onboardingScreen.step1.errors.passwordConfirmation.${error?.type}`, {
              defaultValue: '',
            })}
            onChange={onChange}
            onFocus={() => clearErrors(name)}
            placeholder={t('login:onboardingScreen.step1.passwordConfirmation')}
            placeholderMode="animated"
            name={name}
            dataTest="onboarding-mdpConfirmationContainer-SLiQY"
            data-test={'onboarding-mdpConfirmation-ZmcR'}
          />
        )}
      />
      <Separator height={40} />
      <Button
        disabled={me.isObserver}
        isLoading={isLoading}
        size="large"
        type="submit"
        title={t('continue')}
        onClick={handleSubmit(onSubmit)}
        {...tests.button.submit('onboarding_step1')}
        dataTest={'onboarding-validationButton-9GLY'}
      />
    </>
  )
}
