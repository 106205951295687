import { useMagicLink } from '@happywait/hw-lib-front.core.api'
import { useApiNetworkError } from 'hooks/useErrorToast'
import { useLogout } from 'hooks/useLogout'
import useNavigateParams from 'hooks/useNavigateParams'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useStore } from '../../store'
import { selectSetToken } from '../../store/selectors'
import { ErrorScreen } from '../ErrorScreen/ErrorScreen'

type MagicLinkParams = {
  token: string
}

export const MagicLink = () => {
  const navigate = useNavigateParams()
  const setToken = useStore(selectSetToken)
  const { logoutCustomer } = useLogout()
  const { toastApiError } = useApiNetworkError()
  let { token } = useParams<MagicLinkParams>()
  if (!token) token = ''
  const [errorState, setErrorState] = useState(false)

  const { data: magicLink } = useMagicLink({
    params: { token },
    queryParams: {
      onError: ({ response }) => {
        if (response?.status === 400 && !!response?.data?.exception) {
          setErrorState(true)
        } else {
          toastApiError(response)
        }
      },
    },
  })

  useEffect(() => {
    if (magicLink) {
      const { token: magicLinkToken, target, isAbsolute } = magicLink

      setToken(magicLinkToken)

      if (target) {
        if (isAbsolute) {
          window.location.replace(target)
        } else {
          navigate(target)
        }
      } else {
        navigate('/home')
      }
    }
  }, [setToken, magicLink, navigate])

  return (
    <>
      {errorState && (
        <ErrorScreen
          message={'error.magic_link.not_found.message'}
          title={'error.magic_link.not_found.title'}
          buttonAction={logoutCustomer}
          buttonTitle={'goToLogin'}
        />
      )}
    </>
  )
}
