import { Container, HStack } from '@chakra-ui/react'
import { useDevice } from 'hooks/useDevice'
import { ObserverContent } from './ObserverContent'

export type ObserverType = {
  isObserver?: boolean
}

export const Observer = ({ isObserver }: ObserverType) => {
  const isMobile = useDevice('mobileL')
  if (!isObserver) {
    return null
  }
  return (
    <Container minWidth="100%" bgColor="transparent" p={0} zIndex={999}>
      <HStack
        marginLeft={isMobile ? 0 : '5%'}
        width={isMobile ? undefined : '90%'}
        bgColor="#2E3844"
        color={'gray.100'}
        borderTopRadius={isMobile ? 0 : 4}
        borderBottomRadius={isMobile ? 4 : 0}
        py={4}
      >
        <ObserverContent />
      </HStack>
    </Container>
  )
}
