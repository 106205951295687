export const Pencil = (
  <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>pencil</title>
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icon/24/edit_1" transform="translate(-2.000000, -2.000000)" fill="#2E3844" fillRule="nonzero">
        <g id="pencil" transform="translate(2.000000, 2.000000)">
          <path
            d="M15.693136,0.812255008 L15.9067626,0.817375369 C16.7574811,0.86392866 17.5646447,1.22157445 18.1737715,1.82811302 C18.8290401,2.4857143 19.194205,3.37801663 19.1880758,4.30649272 C19.1816322,5.23483944 18.8044177,6.12211507 18.1465801,6.76458009 L18.1465801,6.76458009 L7.28231798,17.6287506 C7.28015997,17.6309655 7.27798554,17.6331704 7.27579468,17.6353651 C7.17655725,17.7346029 7.05774896,17.8023496 6.92757961,17.8357356 L6.92757961,17.8357356 L1.74882961,19.1639856 C1.19653241,19.3056392 0.69441054,18.8035856 0.835989121,18.2512691 L0.835989121,18.2512691 L2.16348912,13.0725191 C2.1680089,13.0548869 2.17315913,13.037463 2.1789222,13.0202783 C2.18230134,13.0110931 2.1857168,13.0014811 2.18933428,12.9919281 C2.19646908,12.9724984 2.20458737,12.9535737 2.21346188,12.9350273 C2.21984541,12.9219712 2.22666849,12.9086838 2.23391427,12.8955673 C2.26780054,12.8342351 2.31001037,12.7780795 2.35966991,12.7284199 L2.35966991,12.7284199 L13.2286565,1.85950228 C13.8775405,1.19564688 14.7648228,0.818619364 15.693136,0.812255008 L15.693136,0.812255008 Z M3.28977818,14.716255 L2.603,17.396 L5.28377818,16.707255 L3.28977818,14.716255 Z M13.4537782,3.75525501 L3.95177818,13.257255 L6.74377818,16.044255 L16.2437782,6.54525501 L13.4537782,3.75525501 Z M15.7032905,2.31217635 L15.528382,2.3211798 C15.1667602,2.3558195 14.8220387,2.48957812 14.5296218,2.70858735 L17.2915621,5.47074966 C17.5097604,5.18029301 17.6443161,4.83445798 17.6790797,4.47127533 L17.6790797,4.47127533 L17.6880766,4.29633625 C17.691562,3.76834454 17.4838829,3.26086799 17.1133035,2.88896125 C16.7390801,2.51633256 16.2314358,2.3086906 15.7032905,2.31217635 L15.7032905,2.31217635 Z"
            id="Combined-Shape"
          ></path>
        </g>
      </g>
    </g>
  </svg>
)
