import { ModalsStored } from 'store/types'
import { SetState } from 'zustand'
//@TODO add 'meantimeContactSurvey' when meantime wil be activated again
export type ShouldShowModal = Record<keyof Pick<ModalsStored, 'unitTypologySurvey' | 'conversationEvaluation'>, boolean>

export type ShowModal = {
  shouldShowModal: ShouldShowModal | {}
  setShouldShowModal: (sortedModals: ShouldShowModal) => void
  removeState: () => void
}

export const ShowModalSlice = (set: SetState<ShowModal>) => ({
  shouldShowModal: { unitTypologySurvey: false, meantimeContactSurvey: false },
  setShouldShowModal: (newShouldShowModal: ShouldShowModal) => set({ shouldShowModal: newShouldShowModal }),
  removeState: () => set({ shouldShowModal: {} }),
})
