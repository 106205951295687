import { useEffect, useState } from 'react'
import { _validator } from 'services/validator'

export const useIsOnValidating = (isDirty: boolean, isLoading: boolean, debouncedEmail: any, value: string) => {
  const [isOnValidating, setIsOnValidating] = useState(false)

  useEffect(() => {
    setIsOnValidating(
      isDirty && (isLoading || debouncedEmail != value) && !_validator.joiEmailValidation.validate(value).error
    )
  }, [isDirty, isLoading, debouncedEmail, value])
  return isOnValidating
}
